export enum Path {
  NotFound = '*',
  Root = '/',
  Checkout = '/checkout',
  Home = '/home',
  Success = '/success',
  CallbackSuccess = '/callback-success',
  YunoCallback = '/yuno-callback',
  Error = '/error',
  Account = '/accounts/:accountId',
  PaymentMethod = '/accounts/:accountId/payment-method',
  Invoice = '/accounts/:accountId/invoices/:invoiceId',
  Cancel = '/cancel',
}
