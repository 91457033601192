export const toPrecision = (num: number, precision: number = 2): string =>
  (Math.round(num * 100) / 100).toFixed(precision);

export const addThousandSeparator = (num: number | string) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getDisplayPrice = (num: number, currency: string): string =>
  `${currency} ${addThousandSeparator(toPrecision(num, 2))}`;

export const formatCardNumber = (
  cardNumber: string,
  isAmericanExpress?: boolean
): string => {
  const cleanedInput = cardNumber.replace(/\W/g, '');

  const formattedNumber = isAmericanExpress
    ? cardNumber.replace(/(\d{4})(\d{6})(\d{5})/, '$1 $2 $3')
    : cleanedInput.replace(/(\w{4})/g, '$1 ');

  return formattedNumber.replaceAll('X', '*').trim();
};
