import { pmCountryAtom } from '@openeducation/pp-rn-jotai-atoms';
import {
  PaymentFlowType,
  PaymentMethodStatusInfoProps,
  Currency,
} from '@openeducation/pp-rn-shared-types';
import { useAtomValue } from 'jotai';

import { useGetPaymentMethodScreenData } from './use-get-payment-method-screen-data';
import { useUpdatePaymentMethod } from '../../../hooks';
import {
  getBillingAddress,
  getIsSubmitEnabledParams,
  getSubmitPayUParams,
} from '../../helpers';
import { usePaymentSubmitProps } from '../checkout/use-payment-submit-props';
import { useHandleAddressValidation } from '../checkout/use-payment-submit-props/use-handle-address-validation';
import type { SubmitPayPalParams } from '../checkout/use-submit-pay-pal';
import { useAdditionalPaymentInfoProps } from '../use-additional-payment-info-props';
import { useErrorModalProps } from '../use-error-modal-props';
import { useIsSubmitEnabled } from '../use-is-submit-enabled';
import { usePageTemplateProps } from '../use-page-template-props';
import { usePaymentInfoProps } from '../use-payment-info-props';
import { usePaymentStatusModalProps } from '../use-payment-status-modal-props';
import { usePersonalInfoProps } from '../use-personal-info-props';
import type { SubmitYunoParams } from '../use-submit-yuno';

export const usePaymentMethodScreen = (
  accountId: string = '',
  baseUrl: string
) => {
  const {
    data,
    isScreenLoading,
    contactPhone,
    organization,
    countryISOCode,
    isInvalidAccount,
  } = useGetPaymentMethodScreenData({ accountId });
  const {
    accountInfo,
    statusInfo,
    commonPaymentInfo,
    allCountries,
    creditCardPaymentInfo,
    alternativePaymentInfo,
    webCheckoutFormData,
    additionalPaymentInfo,
    countryStates,
    personalDetails,
    billingAgreements,
  } = data;
  const personalInfo = statusInfo?.personalInfo;
  const invoice = statusInfo?.invoice;
  const pmCountry = useAtomValue(pmCountryAtom);
  const productDescription = `${personalInfo?.fullName}'s account (${organization})`;

  const isB2B =
    data?.accountInfo?.businessUnit?.toLowerCase().includes('_b2b') || false;

  const { pageTemplateProps } = usePageTemplateProps({
    isScreenLoading,
    contactPhone,
    organization,
    isB2B,
  });
  const paymentMethodStatusInfoProps: PaymentMethodStatusInfoProps = {
    status: accountInfo?.status,
  };

  const { personalInfoProps } = usePersonalInfoProps({
    fullName: personalInfo?.fullName,
    email: personalInfo?.email,
    personalDetails,
    countryISOCode,
  });

  const { paymentInfoProps } = usePaymentInfoProps({
    countryISOCode,
    commonPaymentInfo,
    allCountries,
    creditCardPaymentInfo,
    paypalEmail: personalInfo?.email,
  });

  const { additionalPaymentInfoProps } = useAdditionalPaymentInfoProps({
    additionalPaymentInfo,
    isExistingPaymentMethod: paymentInfoProps.fields.isExistingPaymentMethod,
    countryStates,
  });

  const billingAddress = getBillingAddress({
    additionalPaymentInfo: additionalPaymentInfoProps.formData,
    pmCountry,
  });

  const isSubmitEnabledParams = getIsSubmitEnabledParams({
    personalInfoProps,
    paymentInfoProps,
    additionalPaymentInfoProps,
  });
  const { isSubmitEnabled } = useIsSubmitEnabled(isSubmitEnabledParams);

  const submitPaymentReturn = useUpdatePaymentMethod({
    accountId,
  });

  const submitPayPalParams: SubmitPayPalParams = {
    baseUrl,
    agreementDescription: productDescription,
    pmCountry,
    sfdcToken: null,
    zuoraAccountId: accountId,
  };

  const submitPayUParams = getSubmitPayUParams({
    baseUrl,
    webCheckoutFormData,
    buyerEmail: personalInfo?.email as string,
    productDescription,
    amount: invoice?.amount as number,
    extra1: organization as string,
    currency: invoice?.currencyIso as Currency,
  });

  const { addressValidationModalProps } = useHandleAddressValidation({
    billingAddress,
  });

  const submitYunoParams: SubmitYunoParams = {
    baseUrl,
    pmCountry,
    sfdcToken: null,
    accountId,
    documentNumber:
      personalInfoProps.formData.cpf ||
      additionalPaymentInfoProps.formData.documentNumber,
  };

  const { paymentSubmitProps } = usePaymentSubmitProps({
    paymentInfo: paymentInfoProps.formData,
    documentNumber: additionalPaymentInfoProps.formData.documentNumber,
    cpfNumber: personalInfoProps.formData.cpf,
    contractParams: null,
    submitPaymentReturn,
    isSubmitEnabled,
    submitPayPalParams,
    submitPayUParams,
    submitYunoParams,
    billingAgreements,
    billingAddress: addressValidationModalProps.address,
    validateAddress: addressValidationModalProps.validateAddress,
    isAddressValid: addressValidationModalProps.isAddressValid,
    alternativePaymentInfo,
  });

  const { errorModalProps } = useErrorModalProps({
    contactPhone,
  });

  const { paymentStatusModalProps } = usePaymentStatusModalProps({
    paymentFlowType: PaymentFlowType.FIRST_PAYMENT,
    alternativePaymentGateway: alternativePaymentInfo?.paymentGateway,
  });

  return {
    pageTemplateProps,
    paymentMethodStatusInfoProps,
    errorModalProps,
    personalInfoProps,
    paymentInfoProps,
    additionalPaymentInfoProps,
    paymentSubmitProps,
    paymentStatusModalProps,
    isInvalidAccount,
    addressValidationModalProps,
  };
};
