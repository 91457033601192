import type { PaymentSubmitProps } from '@openeducation/pp-rn-shared-types';
import React from 'react';

import DefaultSubmitButton from './default-submit-button';
import PayPalSubmitButton from './pay-pal-submit-button';
import PayUSubmitButton from './payu-submit-button';
import YunoSubmitButton from './yuno-submit-button';

export const PaymentSubmit = ({
  submitPayment,
  isSubmitEnabled,
  isLoading,
  isPayPal,
  isPayU,
  isYuno,
}: PaymentSubmitProps) => {
  const buttonDisabled = !isSubmitEnabled || isLoading;

  const commonButtonProps = {
    onPress: submitPayment,
    disabled: buttonDisabled,
    isLoading,
  };

  if (isPayPal) {
    return <PayPalSubmitButton {...commonButtonProps} />;
  }

  if (isPayU) {
    return <PayUSubmitButton {...commonButtonProps} />;
  }

  if (isYuno) {
    return <YunoSubmitButton {...commonButtonProps} />;
  }

  return <DefaultSubmitButton {...commonButtonProps} />;
};

export default PaymentSubmit;
