import { Organization } from '@openeducation/pp-rn-shared-types';

export const getOrganizationPhoneKey = (organization?: Organization) => {
  switch (organization) {
    case Organization.NEXTU:
      return 'nextuContactPhone';
    case Organization.OPEN_MUNDO:
      return 'openMundoContactPhone';
    case Organization.OPEN_ENGLISH:
    case Organization.OPEN_ENGLISH_ADULT:
    case Organization.OPEN_ENGLISH_JR:
    default:
      return 'oeContactPhone';
  }
};
