import { FontAwesome5 } from '@expo/vector-icons';
import { getDisplayPrice } from '@openeducation/pp-js-utils';
import type { InvoiceTableProps } from '@openeducation/pp-rn-shared-types';
import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking } from 'react-native';

import InvoiceTableCell from './invoice-table-cell';
import { FormSection } from '../../templates';

export const InvoiceTable = ({
  invoiceId,
  dueDate,
  amount,
  pdfLink,
  currency,
}: InvoiceTableProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <FormSection title={t('title.your_account_status')}>
        <InvoiceInfo>
          <TableHeading>
            <InvoiceTableCell isHeading>{t('invoice.number')}</InvoiceTableCell>
            <InvoiceTableCell isHeading>
              {t('invoice.due_date')}
            </InvoiceTableCell>
            <InvoiceTableCell isHeading>
              {t('invoice.amount_due')}
            </InvoiceTableCell>
          </TableHeading>
          <TableRow>
            <InvoiceTableCell>
              {pdfLink ? (
                <DownloadButton onPress={() => Linking.openURL(pdfLink)}>
                  {invoiceId} <FontAwesome5 name="download" />
                </DownloadButton>
              ) : (
                invoiceId
              )}
            </InvoiceTableCell>
            <InvoiceTableCell>{dueDate}</InvoiceTableCell>
            <InvoiceTableCell>
              {currency && getDisplayPrice(Number(amount), currency)}
            </InvoiceTableCell>
          </TableRow>
        </InvoiceInfo>
      </FormSection>
    </Container>
  );
};

const Container = styled.View`
  margin-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const InvoiceInfo = styled.View`
  border-width: ${({ theme }) => theme.border.width.primary}px;
  border-color: ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.light};
`;

const TableRow = styled.View`
  flex-direction: row;
  align-items: center;
`;

const TableHeading = styled(TableRow)`
  border-bottom-width: ${({ theme }) => theme.border.width.primary}px;
  border-bottom-color: ${({ theme }) => theme.colors.border};
`;

const DownloadButton = styled.TouchableOpacity`
  flex-direction: row;
  color: ${({ theme }) => theme.colors.primary};
`;
export default memo(InvoiceTable);
