import type {
  AdditionalPaymentInfoProps,
  BillingAddress,
  Country,
} from '@openeducation/pp-rn-shared-types';

interface GetBillingAddress {
  additionalPaymentInfo: AdditionalPaymentInfoProps['formData'];
  pmCountry: Country['isoCode'] | null;
}

export const getBillingAddress = ({
  additionalPaymentInfo,
  pmCountry,
}: GetBillingAddress): BillingAddress => {
  return {
    address1: additionalPaymentInfo.address,
    address2: additionalPaymentInfo.number,
    address3: additionalPaymentInfo.complement,
    state: additionalPaymentInfo.state,
    city: additionalPaymentInfo.city,
    postcode: additionalPaymentInfo.zipCode,
    country: pmCountry || '',
  };
};
