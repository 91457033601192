import { PPPaymentInfoService } from '@openeducation/pp-rn-services';
import type {
  AdditionalPaymentInfo,
  Currency,
  Organization,
  PaymentMethod,
} from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetAdditionalPaymentInfoInput {
  countryISOCode?: string;
  currency?: Currency;
  organization?: Organization;
  paymentMethod?: PaymentMethod;
  pmCountry?: string;
  isB2B?: boolean;
}

export const useGetAdditionalPaymentInfo = ({
  countryISOCode = '',
  currency = '' as Currency,
  organization = '' as Organization,
  paymentMethod = '' as PaymentMethod,
  pmCountry = '',
  isB2B = false,
}: UseGetAdditionalPaymentInfoInput) => {
  const { environment } = useEnvironmentContext();

  const inputParams = [
    countryISOCode,
    currency,
    organization,
    paymentMethod,
    pmCountry,
  ];

  const someParamsAreEmpty = inputParams.some((item) => !item);

  const { data, isError, isLoading } = useQuery<AdditionalPaymentInfo>(
    [QueryKey.GetAdditionalPaymentInfo, ...inputParams],
    () =>
      PPPaymentInfoService.getAdditionalPaymentInfo({
        env: environment,
        countryISOCode,
        currency,
        organization,
        paymentMethod,
        pmCountry,
        isB2B,
      }),
    { enabled: !someParamsAreEmpty }
  );

  return {
    data,
    isError,
    isLoading,
  };
};
