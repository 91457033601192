import { PPPaymentInfoService } from '@openeducation/pp-rn-services';
import type { PersonalPaymentDetails } from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetPersonalDetailsInput {
  countryISOCode?: string;
}

export const useGetPersonalDetails = (
  { countryISOCode = '' }: UseGetPersonalDetailsInput,
  enabled = true
) => {
  const { environment } = useEnvironmentContext();

  const { data, isError, isLoading } = useQuery<PersonalPaymentDetails>(
    [QueryKey.GetPersonalDetails, countryISOCode],
    () => PPPaymentInfoService.getPersonalDetails(environment, countryISOCode),
    { enabled: enabled && !!countryISOCode }
  );

  return {
    data,
    isError,
    isLoading,
  };
};
