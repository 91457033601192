import { FontAwesome5 } from '@expo/vector-icons';
import {
  dateToStandardString,
  differenceInMonths,
  getTranslatedMonth,
} from '@openeducation/pp-js-utils';
import { Language } from '@openeducation/pp-rn-shared-types';
import { styled, ThemeType, useStyledTheme } from '@openeducation/pp-rn-themes';
import React, { memo, ReactNode } from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import {
  Calendar as RNCalendar,
  LocaleConfig as CalendarLocaleConfig,
} from 'react-native-calendars';

import { CalendarLocales } from './calendar.locales';
import { Loading } from '../../atoms/loading/loading';
import { PPText } from '../../atoms/pp-text/pp-text';

export type CalendarProps = {
  onPrevMonth: () => void;
  onNextMonth: () => void;
  selectedDates: Date[];
  currentMonth: Date;
  today: Date;
  language: Language;
  onDayPress?: (date: Date) => void;
  minDate?: Date;
  isLoading?: boolean;
  selectedColor?: string;
  headerRight?: ReactNode;
  style?: StyleProp<ViewStyle>;
};

CalendarLocaleConfig.locales = CalendarLocales;

const Calendar = ({
  isLoading,
  onPrevMonth,
  onNextMonth,
  selectedDates,
  selectedColor,
  today,
  currentMonth,
  language,
  headerRight,
  style,
  minDate,
  onDayPress,
}: CalendarProps) => {
  const theme = useStyledTheme();

  CalendarLocaleConfig.defaultLocale = language || Language.EN;

  const disablePrevMonth = differenceInMonths(today, currentMonth) === 1;
  const disableNextMonth = differenceInMonths(today, currentMonth) === 0;

  const maxDate = dateToStandardString(today);
  const minDateStr = minDate && dateToStandardString(minDate);
  const dateSelectionStyle = {
    selected: true,
    selectedColor: selectedColor || theme.colors.primary,
    textColor: theme.colors.light,
  };
  const markedDates = selectedDates.reduce((acc, date: Date) => {
    if (date) {
      const dateUtc = dateToStandardString(date);
      return { ...acc, [dateUtc]: dateSelectionStyle };
    }
    return acc;
  }, {});

  const todaySelection = today && {
    [dateToStandardString(today)]: {
      customStyles: {
        container: {
          borderWidth: 1,
          borderColor: theme.colors.dark3,
        },
      },
    },
  };

  const renderCalendarContent = () => {
    if (isLoading) {
      return <Loading isFullPage />;
    }
    return (
      <RNCalendar
        initialDate={dateToStandardString(currentMonth)}
        minDate={minDateStr || '2000-01-01'}
        maxDate={maxDate}
        markingType="custom"
        hideArrows
        firstDay={1}
        disableAllTouchEventsForDisabledDays
        markedDates={{ ...todaySelection, ...markedDates }}
        renderHeader={() => null}
        onDayPress={({ day, month, year }) =>
          onDayPress?.(new Date(year, month - 1, day))
        }
        theme={{
          todayTextColor: theme.colors.black,
        }}
      />
    );
  };

  return (
    <Container style={style}>
      <CalendarHeader>
        <Row>
          <TouchableOpacity onPress={onPrevMonth} disabled={disablePrevMonth}>
            <ArrowIcon name="chevron-left" disabled={disablePrevMonth} />
          </TouchableOpacity>
          <TouchableOpacity onPress={onNextMonth} disabled={disableNextMonth}>
            <ArrowIcon name="chevron-right" disabled={disableNextMonth} />
          </TouchableOpacity>
          <HeaderText>
            {getTranslatedMonth(currentMonth, language, true)}{' '}
            {currentMonth.getFullYear()}
          </HeaderText>
        </Row>
        {headerRight}
      </CalendarHeader>
      <CalendarWrapper>{renderCalendarContent()}</CalendarWrapper>
    </Container>
  );
};

const Container = styled.View`
  background-color: ${({ theme }) => theme.colors.light};
`;

const CalendarHeader = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  padding-left: 5px;
`;

const CalendarWrapper = styled.View`
  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.colors.border};
  padding-top: ${({ theme }) => theme.spacing.primarySmall}px;
`;

const Row = styled.View`
  flex-direction: row;
  align-items: center;
`;

type ArrowIconProps = {
  theme: ThemeType;
  disabled?: boolean;
};

const ArrowIcon = styled(FontAwesome5).attrs(
  ({ theme, disabled }: ArrowIconProps) => ({
    color: disabled ? theme.colors.border : theme.colors.black,
    size: 15,
  })
)<ArrowIconProps>`
  padding-horizontal: 7px;
`;

const HeaderText = styled(PPText).attrs(({ theme }) => ({
  color: theme.colors.black,
  fontSize: 16,
}))``;

export default memo(Calendar);
