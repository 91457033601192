import { Language } from '@openeducation/pp-rn-shared-types';
import i18n from 'i18next';
import AsyncStorageBackend from 'i18next-async-storage-backend2';
import ChainedBackend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';

const expirationTime = 20 * 60 * 1000; // 20 min

const initConfig = {
  fallbackLng: Language.EN,
  languages: [Language.EN],
  lng: Language.EN,
  debug: false,
  cache: {
    enabled: true,
  },
  interpolation: {
    prefix: '{{',
    suffix: '}}',
  },
  backend: {
    backends: [LocalStorageBackend, AsyncStorageBackend],
    backendOptions: [{ expirationTime }, { expirationTime }],
  },
};

i18n.use(ChainedBackend).use(initReactI18next).init(initConfig);

export function initTranslationWithXHREndpoint(
  loadPath: string,
  lang = Language.EN
) {
  return i18n
    .use(ChainedBackend)
    .use(initReactI18next)
    .init({
      ...initConfig,
      lng: lang,
      backend: {
        backends: [LocalStorageBackend, AsyncStorageBackend, HttpApi],
        backendOptions: [{ expirationTime }, { expirationTime }, { loadPath }],
      },
    });
}

export default i18n;
