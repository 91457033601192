import { getUrlParams } from '@openeducation/pp-rn-services';
import { useEffect } from 'react';

import { useCheckoutRedirection } from '../hooks';

const CancelScreen = () => {
  const { accountId, sfdcToken } = getUrlParams();
  const { openCheckoutAfterRedirection } = useCheckoutRedirection({
    accountId,
    sfdcToken,
  });

  useEffect(() => {
    openCheckoutAfterRedirection();
  }, [openCheckoutAfterRedirection]);

  return null;
};

export default CancelScreen;
