import { getDisplayPrice, formatLocale } from '@openeducation/pp-js-utils';
import type {
  AccountStatusInfo,
  AccountStatusInfoProps,
  AccountStatus,
} from '@openeducation/pp-rn-shared-types';

import { usePreferredLanguage } from '../use-preferred-language';

interface UseAccountStatusInfoProps {
  statusInfo?: AccountStatusInfo;
}

export const useAccountStatusInfoProps = ({
  statusInfo,
}: UseAccountStatusInfoProps) => {
  const { preferredLanguage } = usePreferredLanguage();
  const invoice = statusInfo?.invoice || ({} as AccountStatusInfo['invoice']);

  const dueDate = invoice?.dueDate
    ? formatLocale(
        new Date(`${invoice?.dueDate} 00:00:00`),
        'MMMM dd, yyyy',
        preferredLanguage
      )
    : '';

  const accountStatusInfoProps: AccountStatusInfoProps = {
    status: statusInfo?.accountStatus as AccountStatus,
    invoiceId: invoice.number || '',
    dueDate,
    price: getDisplayPrice(invoice.amount || 0, invoice.currencyIso),
    isSuspended: !!statusInfo?.suspended,
  };

  return { accountStatusInfoProps };
};
