import { styled, useDimension } from '@openeducation/pp-rn-themes';
import type { ThemeType } from '@openeducation/pp-rn-themes';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { PPText } from '../../atoms';
const HeaderFinanced = () => {
  const { t } = useTranslation();
  const { isMediumSize } = useDimension();
  return (
    <Container>
      <Message isMediumSize={isMediumSize}>{t('ppui.finaced.title')}</Message>
    </Container>
  );
};

const Container = styled.Text`
  background: ${({ theme }) => theme.colors.primary};
  width: 100%;
  text-align: center;
`;

type MessageType = {
  isMediumSize?: boolean;
  theme: ThemeType;
};

export const Message = styled(PPText).attrs(
  ({ theme, isMediumSize = false }: MessageType) => ({
    color: theme.colors.light,
    fontSize: isMediumSize ? 20 : 24,
    fontType: 'bold',
    lineHeight: 56,
  })
)<MessageType>``;
export default memo(HeaderFinanced);
