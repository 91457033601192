import {
  FontsType,
  primaryFontFamily,
  styled,
} from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import type { TextProps, TextStyle } from 'react-native';

export interface PPTextProps extends TextProps {
  fontType?: keyof FontsType;
  fontSize?: number;
  color?: string;
  textAlign?: TextStyle['textAlign'];
  fontFamily?: FontsType;
  lineHeight?: number;
}

export const PPText = ({
  children,
  fontType = 'regular',
  fontSize = 14,
  color,
  fontFamily = primaryFontFamily,
  style,
  lineHeight,
  textAlign,
  ...rest
}: PPTextProps) => (
  <StyledText
    fontSize={fontSize}
    textAlign={textAlign}
    color={color}
    lineHeight={lineHeight}
    style={[fontFamily[fontType], style]}
    {...rest}
  >
    {children}
  </StyledText>
);

type StyledTextType = {
  fontSize: number;
  textAlign: TextStyle['textAlign'];
  color?: string;
  right?: boolean;
  lineHeight?: number;
};

const StyledText = styled.Text<StyledTextType>`
  color: ${({ theme, color }) => (color ? color : theme.colors.text)};
  font-size: ${({ fontSize }) => fontSize}px;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  line-height: ${({ fontSize, lineHeight }) =>
    lineHeight ? lineHeight : fontSize * 1.5}px;
`;

export default memo(PPText);
