import { LocationService } from '@openeducation/pp-rn-services';
import type { City } from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetCitiesInput {
  stateId?: number;
}

export const useGetCities = ({ stateId = 0 }: UseGetCitiesInput) => {
  const { environment } = useEnvironmentContext();

  const {
    data = [],
    isError,
    isLoading,
  } = useQuery<City[]>(
    [QueryKey.GetCities, stateId],
    () => LocationService.getCities(environment, stateId),
    { enabled: !!stateId }
  );

  return {
    data,
    isError,
    isLoading,
  };
};
