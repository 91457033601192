import { selectedPaymentGatewayAtom } from '@openeducation/pp-rn-jotai-atoms';
import type {
  PickedFile,
  ManualPaymentInfo,
  ManualPaymentInfoProps,
  PaymentGateway,
  SelectInputItem,
  ManualPaymentBanks,
} from '@openeducation/pp-rn-shared-types';
import { useAtom } from 'jotai';
import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { usePreferredLanguage } from '../use-preferred-language';

interface UseManualPaymentInfoProps {
  manualPaymentInfo?: ManualPaymentInfo;
  manualPaymentBanks?: ManualPaymentBanks;
}

const today = new Date();

export const useManualPaymentInfoProps = ({
  manualPaymentInfo = {} as ManualPaymentInfo,
  manualPaymentBanks = {} as ManualPaymentBanks,
}: UseManualPaymentInfoProps) => {
  const { t } = useTranslation();
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useAtom(
    selectedPaymentGatewayAtom
  );

  const {
    gateways = [],
    paymentDate,
    proofOfPayment,
    sendBy,
    transactionNumber,
  } = manualPaymentInfo;

  const { banks = [] } = manualPaymentBanks;

  const { preferredLanguage } = usePreferredLanguage();

  const [formData, setFormData] = useState({
    paymentGateway:
      selectedPaymentGateway || manualPaymentInfo?.selectedGateway || '',
    transactionNumber: '',
    sendBy: '',
    paymentDate: '',
    bank: '',
    proofOfPayment: {
      name: '',
      content: '',
      size: 0,
    },
  });

  const handleFieldChange = useCallback(
    (value: string | PickedFile, name?: string) => {
      if (name === 'paymentGateway') {
        setSelectedPaymentGateway(value as PaymentGateway);
      }
      if (name) {
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    },
    [setSelectedPaymentGateway]
  );

  useEffect(() => {
    if (selectedPaymentGateway && String(formData['paymentGateway']) === '') {
      handleFieldChange(selectedPaymentGateway, 'paymentGateway');
    }
  }, [formData, handleFieldChange, selectedPaymentGateway]);

  const selectData: ManualPaymentInfoProps['selectData'] = {
    paymentGateway: gateways.map(
      (value: PaymentGateway): SelectInputItem => ({
        label: t(`input.payment_bank.value.${value}`, String(value)),
        value,
      })
    ),

    paymentGatewayBanks: manualPaymentBanks?.banks?.map(
      (value: string): SelectInputItem => ({ label: value, value })
    ),
  };

  const fields: ManualPaymentInfoProps['fields'] = {
    isPaymentGatewayEnabled: gateways.length > 0,
    isTransactionNumberEnabled: !!transactionNumber,
    isSendByEnabled: !!sendBy,
    isPaymentDateEnabled: !!paymentDate,
    isProofOfPaymentEnabled: !!proofOfPayment,
    isBanksEnabled: banks?.length > 0,
  };

  const validation: ManualPaymentInfoProps['validation'] = {
    isTransactionNumberTooLong: formData.transactionNumber.length > 50,
    iseSendByTooLong: formData.sendBy.length > 100,
    isProofOfPaymentTooBig: formData.proofOfPayment.size > 5242880, // 5mb
    isBanksRequired: Boolean(fields['isBanksEnabled']),
  };

  const manualPaymentInfoProps: ManualPaymentInfoProps = {
    onFieldChange: handleFieldChange,
    formData,
    selectData,
    fields,
    validation,
    today,
    language: preferredLanguage,
  };

  return { manualPaymentInfoProps };
};
