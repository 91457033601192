import { validateCpf } from '@openeducation/pp-js-utils';
import { paymentMethodOnFileAtom } from '@openeducation/pp-rn-jotai-atoms';
import {
  Country,
  PersonalInfoProps,
  PersonalPaymentDetails,
  PaymentMethodOnFile,
} from '@openeducation/pp-rn-shared-types';
import { useAtomValue } from 'jotai';
import { useState, useMemo } from 'react';

interface UsePersonalInfoProps {
  fullName?: string;
  email?: string;
  personalDetails?: PersonalPaymentDetails;
  countryISOCode?: Country['isoCode'];
  isUpsell?: boolean;
}

export const usePersonalInfoProps = ({
  fullName = '',
  email = '',
  personalDetails,
  countryISOCode,
  isUpsell = false,
}: UsePersonalInfoProps) => {
  const [formData, setFormData] = useState({
    cpf: '',
  });
  const paymentMethodOnFile = useAtomValue(paymentMethodOnFileAtom);
  const isUseNewPaymentMethod = paymentMethodOnFile === PaymentMethodOnFile.NEW;

  const handlePersonalFieldChange = (value: string, name?: string) => {
    if (name) {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const isCpfValid = useMemo(() => validateCpf(formData.cpf), [formData]);

  const isCpfEnabled = useMemo(() => {
    const isCpfEnabled = !!personalDetails?.cpf;
    if (isUpsell) {
      return isUseNewPaymentMethod && isCpfEnabled;
    }
    return isCpfEnabled;
  }, [personalDetails, isUpsell, isUseNewPaymentMethod]);

  const personalInfoProps: PersonalInfoProps = {
    formData: {
      ...formData,
      fullName,
      email,
      country: countryISOCode || '',
    },
    onFieldChange: handlePersonalFieldChange,
    fields: {
      isFullNameEnabled: !!personalDetails?.fullName,
      isEmailEnabled: !!personalDetails?.email,
      isCountryEnabled: !!personalDetails?.country,
      isCpfEnabled,
    },
    validation: {
      isCpfValid,
    },
  };

  return { personalInfoProps };
};
