import { PPApiService } from '@openeducation/pp-rn-services';
import {
  PaymentFlowType,
  PaymentStatus,
} from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetPaymentStatusInput {
  paymentId?: number | null;
  paymentFlowType?: PaymentFlowType;
}

export const useGetPaymentStatus = ({
  paymentFlowType,
  paymentId,
}: UseGetPaymentStatusInput) => {
  const { environment } = useEnvironmentContext();

  const endpoint =
    paymentFlowType === PaymentFlowType.UPSELL
      ? PPApiService.getUpsellPaymentStatus
      : PPApiService.getFirstPaymentStatus;

  const { data, isError, isLoading, refetch } = useQuery<PaymentStatus>(
    [QueryKey.GetPaymentStatus, endpoint, paymentId],
    () => endpoint(environment, paymentId as number),
    {
      enabled: !!paymentId,
    }
  );

  return {
    data,
    isError,
    isLoading,
    refetch,
  };
};
