import {
  PPProductCatalogService,
  GetTermsOfPurchaseParams,
} from '@openeducation/pp-rn-services';
import type {
  TermsOfPurchase,
  TermsOfPurchaseItem,
} from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

export const useGetTermsOfPurchase = (
  params: GetTermsOfPurchaseParams,
  enabled: boolean = true
) => {
  const { environment } = useEnvironmentContext();
  const {
    country,
    language,
    initialTerm,
    contractType,
    paymentMethod,
    billingPeriod,
  } = params;

  const requiredParams = [
    country,
    language,
    initialTerm,
    contractType,
    paymentMethod,
  ];

  const allParams = [billingPeriod, ...requiredParams];

  const someParamsAreEmpty = requiredParams.some((item) => !item);

  const { data, isError, isLoading } = useQuery<TermsOfPurchase>(
    [QueryKey.GetTermsOfPurchase, ...allParams],
    () => PPProductCatalogService.getTermsOfPurchase(environment, params),
    { enabled: enabled && !someParamsAreEmpty }
  );

  return {
    data: data?.termsOfPurchase || ({} as TermsOfPurchaseItem),
    isError,
    isLoading,
  };
};
