import type {
  Country,
  CountryState,
  City,
  Environment,
} from '@openeducation/pp-rn-shared-types';

import LocationDao from '../dao/location.dao';

export abstract class LocationService {
  static async getCountryByCode(
    env: Environment,
    countryISOCode: string
  ): Promise<Country> {
    const data = await LocationDao.getCountryByCode(env, countryISOCode);
    return data && data[0];
  }

  static async getCountries(env: Environment): Promise<Country[]> {
    const data = await LocationDao.getCountries(env);
    return data;
  }

  static async getCountryStates(
    env: Environment,
    countryId: number
  ): Promise<CountryState[]> {
    const data = await LocationDao.getCountryStates(env, countryId);
    return data;
  }

  static async getCities(env: Environment, stateId: number): Promise<City[]> {
    const data = await LocationDao.getCities(env, stateId);
    return data;
  }
}
