import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectInputProps, SelectInput } from '../../../atoms';
import { InputRow } from '../../../molecules';

const PaymentInfoInstallments = ({
  onFocus,
  onBlur,
  selectedValue,
  data,
  onValueChange,
}: SelectInputProps) => {
  const { t } = useTranslation();

  return (
    <InputRow
      label={t('input.installments.label')}
      error={selectedValue ? '' : t('input.installments.error.required')}
    >
      <SelectInput
        name="installments"
        selectedValue={selectedValue}
        data={data}
        onFocus={onFocus}
        onBlur={onBlur}
        onValueChange={onValueChange}
        placeholder={t('input.installments.label')}
      />
    </InputRow>
  );
};

export default memo(PaymentInfoInstallments);
