import type { Environment } from '@openeducation/pp-rn-shared-types';
import { useState, useEffect } from 'react';

export interface ConfigType {
  environment: Environment;
}

export function useConfig() {
  const [config, setConfig] = useState<ConfigType>();

  useEffect(() => {
    if (__DEV__) {
      setConfig({ environment: 'stg' });
    } else {
      fetch('/config.json')
        .then((data) => data?.json())
        .then((c) => setConfig(c))
        .catch(() => setConfig({ environment: 'prod' }));
    }
  }, []);

  return { config };
}
