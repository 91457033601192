import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { PPButton } from '../../atoms';

interface DefaultSubmitButtonProps {
  disabled: boolean;
  isLoading?: boolean;
  onPress: () => void;
}

const DefaultSubmitButton = ({
  disabled,
  isLoading,
  onPress,
}: DefaultSubmitButtonProps) => {
  const { t } = useTranslation();
  return (
    <SubmitButton
      disabled={disabled}
      icon="arrow-right"
      loading={isLoading}
      onPress={onPress}
    >
      {t('button.submit')}
    </SubmitButton>
  );
};

type SubmitButtonType = {
  disabled?: boolean;
};

const SubmitButton = styled(PPButton).attrs({
  contentStyle: {
    flexDirection: 'row-reverse',
  },
  labelStyle: {
    textTransform: 'uppercase',
  },
})<SubmitButtonType>`
  align-self: flex-end;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export default memo(DefaultSubmitButton);
