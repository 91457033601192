import type {
  ManualPaymentParams,
  ManualPaymentInfoProps,
} from '@openeducation/pp-rn-shared-types';

export const getManualPaymentParams = (
  formData: ManualPaymentInfoProps['formData']
): ManualPaymentParams => {
  const {
    paymentGateway,
    transactionNumber,
    sendBy,
    paymentDate,
    proofOfPayment,
    bank,
  } = formData;

  return {
    gateway: paymentGateway,
    paymentDate,
    proofOfPaymentFileContent: proofOfPayment.content,
    sendBy,
    bank,
    transactionNumber,
  };
};
