import { Language } from '@openeducation/pp-rn-shared-types';
import { es, pt, tr, enUS, vi } from 'date-fns/locale';
import { format } from 'date-fns-tz';

import { capitalizeFirstLetter } from '../../string-utils';

const ORDINAL_CHAR_CODE = 186;

const getFNSLocale = (language?: Language) => {
  switch (language) {
    case Language.ES:
      return es;
    case Language.PT:
      return pt;
    case Language.TR:
      return tr;
    case Language.VI:
      return vi;
    case Language.EN:
    default:
      return enUS;
  }
};

export function getTranslatedMonth(
  date: Date,
  language: Language,
  short?: boolean
) {
  return format(date, short ? 'MMM' : 'MMMM', {
    locale: getFNSLocale(language),
  });
}

export const formatLocale = (
  date: Date | number,
  formatString: string,
  language: Language
): string => {
  return format(date, formatString, {
    locale: getFNSLocale(language),
  });
};

export function getTranslatedDay(
  date: Date | number,
  language: Language,
  stringCase: 'lower' | 'upper' | 'capitalize' = 'lower',
  dateFormat: string = 'eeee do'
) {
  // In Spanish and Portuguese there is an ordinal symbol º that's never used
  // and most of the time confuses people, so we remove it to have a format like: Martes 12

  let response = formatLocale(date, dateFormat, language);
  response = capitalizeFirstLetter(response);

  if (response.charCodeAt(response.length - 1) === ORDINAL_CHAR_CODE) {
    response = response.substring(0, response.length - 1);
  }

  switch (stringCase) {
    case 'lower':
      return response.toLocaleLowerCase();
    case 'upper':
      return response.toLocaleUpperCase();
    case 'capitalize':
      return capitalizeFirstLetter(response);
  }
}

export const getFullTranslatedDate = (date: Date, language: Language) => {
  return format(date, 'MMM dd yyyy', {
    locale: getFNSLocale(language),
  });
};

export function dateArrayToDate(dateArray: number[]) {
  if (!dateArray || !dateArray.length) {
    return;
  }
  const [year, month, day, hour = 0, minute = 0] = dateArray;
  return new Date(year, month - 1, day, hour, minute);
}
