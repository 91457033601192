import type { AdditionalPaymentInfoProps } from '@openeducation/pp-rn-shared-types';
import React, { useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInput } from '../../atoms';
import { InputRow } from '../../molecules';

interface DocumentNumberInputProps {
  value: string;
  validation: AdditionalPaymentInfoProps['validation'];
  onValueChange: (value: string, name?: string) => void;
}

const DocumentNumberInput = ({
  validation,
  value,
  onValueChange,
}: DocumentNumberInputProps) => {
  const { t } = useTranslation();
  const {
    isDocumentNumberTooShort,
    isDocumentNumberValid,
    isDocumentNumberTooLong,
    minDocumentNumberLength,
    maxDocumentNumberLength,
  } = validation;

  const documentNumberError = useMemo(() => {
    if (!value) {
      return t('input.document_number.error.required');
    }
    if (isDocumentNumberTooShort) {
      return t('input.document_number.error.minlength', {
        minLength: minDocumentNumberLength,
      });
    }
    if (isDocumentNumberTooLong) {
      return t('input.document_number.error.maxlength', {
        maxLength: maxDocumentNumberLength,
      });
    }
    return isDocumentNumberValid
      ? ''
      : t('ppui.input.document_number.error.invalidchars');
  }, [
    isDocumentNumberTooShort,
    isDocumentNumberTooLong,
    isDocumentNumberValid,
    value,
    t,
    minDocumentNumberLength,
    maxDocumentNumberLength,
  ]);

  return (
    <InputRow
      label={t('input.document_number.label')}
      error={documentNumberError}
    >
      <TextInput
        name="documentNumber"
        value={value}
        onValueChange={onValueChange}
        placeholder={t('input.document_number.label') as string}
      />
    </InputRow>
  );
};

export default memo(DocumentNumberInput);
