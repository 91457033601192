import { FontAwesome5 } from '@expo/vector-icons';
import type { PickedFile } from '@openeducation/pp-rn-shared-types';
import { styled, useStyledTheme } from '@openeducation/pp-rn-themes';
import React, { memo, useEffect } from 'react';

import { useFileInput } from './use-file-input';
import { PPText } from '../../atoms';

export interface FileInputProps {
  onValueChange: (value: PickedFile, name?: string) => void;
  placeholder?: string;
  disabled?: boolean;
  name?: string;
}

export const FileInput = ({
  name,
  onValueChange,
  disabled,
  placeholder,
}: FileInputProps) => {
  const theme = useStyledTheme();
  const { base64Document, pickDocument } = useFileInput();
  const documentContent = base64Document.content;
  const textColor =
    disabled || !documentContent ? theme.colors.dark3 : theme.colors.text;

  useEffect(() => {
    if (base64Document?.content) {
      onValueChange(base64Document, name);
    }
  }, [base64Document, onValueChange, name]);

  return (
    <Container disabled={disabled} onPress={pickDocument}>
      <InputText color={textColor} fontSize={13}>
        {base64Document.name || placeholder}
      </InputText>
      <FontAwesome5 name="file" size={16} color={textColor} />
    </Container>
  );
};

type ContainerType = {
  disabled?: boolean;
};

const Container = styled.TouchableOpacity<ContainerType>`
  padding-horizontal: ${({ theme }) => theme.spacing.primarySmall}px;
  padding-vertical: ${({ theme }) => theme.spacing.small}px;
  border-radius: ${({ theme }) => theme.border.radius.primary}px;
  border-width: ${({ theme }) => theme.border.width.primary}px;
  border-color: ${({ theme }) => theme.colors.border};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.dark0 : theme.colors.light};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  flex-direction: row;
  align-items: center;
`;

const InputText = styled(PPText)`
  flex: 1;
`;

export default memo(FileInput);
