import { useMemo } from 'react';

import {
  ValidatePaymentInfoParams,
  validatePaymentInfo,
  validateAdditionalPaymentInfo,
  ValidateAdditionalPaymentInfoParams,
  validateContract,
  ValidateContractParams,
  validateManualPaymentInfo,
  ValidateManualPaymentInfoParams,
} from '../helpers';

export interface IsSubmitEnabledParams {
  personalInfo: {
    isCpfEnabled: boolean;
    isCpfValid: boolean;
  };
  paymentInfo: ValidatePaymentInfoParams;
  additionalPaymentInfo?: ValidateAdditionalPaymentInfoParams;
  manualPaymentInfo?: ValidateManualPaymentInfoParams;
  contractInfo?: ValidateContractParams;
}

export const useIsSubmitEnabled = ({
  personalInfo: { isCpfEnabled, isCpfValid },
  paymentInfo,
  additionalPaymentInfo,
  manualPaymentInfo,
  contractInfo,
}: IsSubmitEnabledParams) => {
  const isPersonalInfoValid = isCpfEnabled ? isCpfValid : true;

  const isPaymentInfoValid = useMemo(
    () => validatePaymentInfo(paymentInfo),
    [paymentInfo]
  );

  const isAdditionalPaymentInfoValid = useMemo(
    () =>
      additionalPaymentInfo
        ? validateAdditionalPaymentInfo(additionalPaymentInfo)
        : true,
    [additionalPaymentInfo]
  );

  const isManualPaymentInfoValid = useMemo(
    () =>
      manualPaymentInfo ? validateManualPaymentInfo(manualPaymentInfo) : true,
    [manualPaymentInfo]
  );

  const isContractValid = useMemo(
    () => (contractInfo ? validateContract(contractInfo) : true),
    [contractInfo]
  );

  const isSubmitEnabled =
    isPersonalInfoValid &&
    isPaymentInfoValid &&
    isAdditionalPaymentInfoValid &&
    isManualPaymentInfoValid &&
    isContractValid;

  return { isSubmitEnabled };
};
