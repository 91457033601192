import { PaymentMethodStatus } from '@openeducation/pp-rn-shared-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoBannerVariant, InfoBannerProps } from '../../molecules';

export const usePaymentMethodStatusInfoBanner = (
  status?: PaymentMethodStatus
): InfoBannerProps => {
  const { t } = useTranslation();

  const variant = useMemo(() => {
    switch (status) {
      case PaymentMethodStatus.OK:
        return InfoBannerVariant.SUCCESS;
      case PaymentMethodStatus.WARN:
        return InfoBannerVariant.INFO;
      case PaymentMethodStatus.SUSPENDED:
        return InfoBannerVariant.WARNING;
      case PaymentMethodStatus.CANCELLED:
        return InfoBannerVariant.ERROR;
      default:
        return InfoBannerVariant.INFO;
    }
  }, [status]);

  return {
    children: t(`account_status.${status}`) as string,
    variant,
  };
};
