import { removeEmptyKeys } from '@openeducation/pp-js-utils';
import { PPApiService } from '@openeducation/pp-rn-services';
import type {
  Buyer,
  BuyerParams,
  ApiError,
} from '@openeducation/pp-rn-shared-types';
import type { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

export const useGetBuyer = (params: BuyerParams, enabled = true) => {
  const { environment } = useEnvironmentContext();
  const { sfdcLeadId, sfdcAccountId, sfdcPurchaserContactId } = params;

  const { data, isError, error, isLoading } = useQuery<
    Buyer,
    AxiosError<ApiError>
  >(
    [QueryKey.GetBuyer, ...Object.entries(params)],
    () => PPApiService.getBuyer(environment, removeEmptyKeys(params)),
    {
      enabled:
        enabled &&
        (!!sfdcLeadId || (!!sfdcAccountId && !!sfdcPurchaserContactId)),
    }
  );

  const apiError: ApiError | undefined = error?.response?.data;

  return {
    data,
    isError,
    isLoading,
    apiError,
  };
};
