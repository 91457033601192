import type { ErrorModalProps } from '@openeducation/pp-rn-shared-types';
import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { PPText, PPModal, PPButton } from '../../atoms';
import { PhoneButton } from '../../molecules';

export const ErrorModal = ({
  visible,
  errorKey,
  onClose,
  contactPhone,
  isB2B,
  showMessage = true,
}: ErrorModalProps) => {
  const { t } = useTranslation();

  return (
    <PPModal visible={visible} title={t('error.modal.title') as string}>
      <ErrorText>{t(errorKey || 'error.unknown')}</ErrorText>

      {showMessage && (
        <BodyText>
          {t(isB2B || !contactPhone ? 'pp.error.generic_b2b' : 'error.generic')}
        </BodyText>
      )}

      {!isB2B && contactPhone && (
        <StyledPhoneButton contactPhone={contactPhone} />
      )}

      {!!onClose && (
        <RetryButton onPress={onClose}>{t('error.modal.button')}</RetryButton>
      )}
    </PPModal>
  );
};

const ErrorText = styled(PPText).attrs({
  fontSize: 20,
  fontType: 'medium',
})`
  margin-bottom: ${({ theme }) => theme.spacing.primarySmall}px;
`;

const BodyText = styled(PPText)`
  margin-bottom: ${({ theme }) => theme.spacing.primarySmall}px;
`;

const StyledPhoneButton = styled(PhoneButton)`
  margin-bottom: ${({ theme }) => theme.spacing.primary}px;
`;

const RetryButton = styled(PPButton).attrs({
  labelStyle: {
    textTransform: 'uppercase',
  },
})`
  align-self: flex-end;
  cursor: pointer;
`;

export default memo(ErrorModal);
