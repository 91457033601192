import type { SelectInputItem } from '@openeducation/pp-rn-shared-types';

export const mapSelectInputData = <T>(
  arr: T[],
  labelKey?: keyof T,
  valueKey?: keyof T
) =>
  arr.map((item: T) => ({
    label: labelKey ? item[labelKey] : item,
    value: valueKey ? item[valueKey] : item,
  })) as unknown as SelectInputItem[];

export const generateArray = (arrayLength: number) =>
  Array.from({ length: arrayLength }, (_: any, index: number) => index);

export const compareArrayValues = (array1: any[], array2: any[]) => {
  if (array1.length !== array2.length) {
    return false;
  }
  const sortedArray1 = array1.sort();
  const sortedArray2 = array2.sort();

  return sortedArray1.every(
    (element, index) => element === sortedArray2[index]
  );
};
