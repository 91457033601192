import type { PaymentMethodStatusInfoProps } from '@openeducation/pp-rn-shared-types';
import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';

import { usePaymentMethodStatusInfoBanner } from './use-payment-method-status-info-banner';
import { InfoBanner } from '../../molecules';

export const PaymentMethodStatusInfo = ({
  status,
}: PaymentMethodStatusInfoProps) => {
  const infoBannerProps = usePaymentMethodStatusInfoBanner(status);

  if (!status) {
    return null;
  }

  return <Banner {...infoBannerProps} />;
};

const Banner = styled(InfoBanner)`
  margin-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

export default memo(PaymentMethodStatusInfo);
