import { configureFonts, DefaultTheme } from 'react-native-paper';

import { ColorsType, colors } from './colors';
import { paperFontsConfig } from './paper-fonts';

declare global {
  namespace ReactNativePaper {
    interface ThemeColors extends ColorsType {
      backdrop: string;
      disabled: string;
      notification: string;
      onSurface: string;
      placeholder: string;
      surface: string;
      text: string;
    }
  }
}

const themeShared = {
  roundness: 4,
  fonts: configureFonts({ config: paperFontsConfig as any }),
};

export const defaultTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    ...colors,
    backdrop: colors.dark2,
    disabled: colors.disabled,
    notification: colors.primary,
    onSurface: colors.light,
    placeholder: colors.dark3,
    surface: colors.light,
    text: colors.text,
    // NOTE: any new color should be added in `oe-colors.ts`, not here
  },
  ...themeShared,
};
