import { addressText } from '@openeducation/pp-rn-services';
import type { AddressValidationModalProps } from '@openeducation/pp-rn-shared-types';
import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { PPText, PPButton } from '../../atoms';

type AddressValidationCorrectedProps = Pick<
  AddressValidationModalProps,
  'accept' | 'proceed' | 'address' | 'validatedAddress'
>;

export const AddressValidationCorrected = ({
  accept,
  proceed,
  address,
  validatedAddress,
}: AddressValidationCorrectedProps) => {
  const { t } = useTranslation();

  return (
    <>
      <CompareBox>
        <AddressBox>
          <BodyText>
            {t('input.address_validation_modal.original_address_title')}
          </BodyText>
          <BodyText>{addressText(address)}</BodyText>
        </AddressBox>
        <AddressBox>
          <BodyText>
            {t('input.address_validation_modal.corrected_address_title')}
          </BodyText>
          <BodyText>
            {validatedAddress && addressText(validatedAddress)}
          </BodyText>
        </AddressBox>
      </CompareBox>
      <QuestionText>
        {t('input.address_validation_modal.change_address_question')}
      </QuestionText>
      <CompareBox>
        <ActionButton onPress={proceed}>
          {t('input.address_validation_modal.original_address_button')}
        </ActionButton>
        <ActionButton onPress={accept}>
          {t('input.address_validation_modal.corrected_address_button')}
        </ActionButton>
      </CompareBox>
    </>
  );
};

const QuestionText = styled(PPText).attrs({
  fontSize: 20,
  fontType: 'medium',
})`
  margin-bottom: ${({ theme }) => theme.spacing.primarySmall}px;
  text-align: center;
`;

const BodyText = styled(PPText)`
  margin-bottom: ${({ theme }) => theme.spacing.primarySmall}px;
  text-align: center;
`;

const ActionButton = styled(PPButton).attrs({
  labelStyle: {
    textTransform: 'uppercase',
  },
})`
  align-self: flex-end;
  cursor: pointer;
  margin-left: 10px;
`;
const AddressBox = styled.View`
  flex: 1;
  text-align: center;
`;
const CompareBox = styled.View`
  flex-direction: row;
  justify-content: space-around;
`;
export default memo(AddressValidationCorrected);
