import type {
  Environment,
  PayPalToken,
  YunoPaymentLink,
} from '@openeducation/pp-rn-shared-types';
import { Linking } from 'react-native';

import { hostsConfig } from './hosts-config';

export const redirectToPayPal = (env: Environment, token: PayPalToken) => {
  const url = hostsConfig[env].paypalUrl;
  const redirectionUrl = `${url}${token}`;

  redirect(redirectionUrl);
};

export const getPayUUrl = (env: Environment) => hostsConfig[env].payuUrl;

export const redirectToYuno = (yunoPaymentLink: YunoPaymentLink) => {
  redirect(yunoPaymentLink);
};

function redirect(redirectionUrl: string) {
  if (window.location) {
    // opens the url in the same tab - expected behaviour
    window.location.href = redirectionUrl;
  } else {
    // opens the url in another tab - fallback
    Linking.openURL(redirectionUrl);
  }
}
