import { PPPageTemplate, PPHomeScreen } from '@openeducation/pp-rn-components';
import { useDefaultContactPhone } from '@openeducation/pp-rn-providers';
import React from 'react';

import { footerProps } from '../helpers';

const HomeScreen = () => {
  const { contactPhone } = useDefaultContactPhone();

  return (
    <PPPageTemplate headerProps={{ contactPhone }} footerProps={footerProps}>
      <PPHomeScreen contactPhone={contactPhone} />
    </PPPageTemplate>
  );
};

export default HomeScreen;
