import { getPayUUrl } from '@openeducation/pp-rn-services';
import type {
  Currency,
  WebCheckoutFormData,
} from '@openeducation/pp-rn-shared-types';

import { useEnvironmentContext } from '../../environment/environment.provider';

export interface SubmitPayUParams extends WebCheckoutFormData {
  baseUrl: string;
  description: string;
  amount: number;
  currency: Currency;
  buyerEmail: string;
  extra1: string;
}

export const useSubmitPayU = ({
  baseUrl,
  merchantId,
  accountId,
  description,
  referenceCode,
  expirationDate,
  amount,
  taxAmount,
  taxReturnBase,
  currency,
  signature,
  buyerEmail,
  extra1,
}: SubmitPayUParams) => {
  const { environment } = useEnvironmentContext();
  const payUUrl = getPayUUrl(environment);

  const responseUrl = `${baseUrl}/callback-success`;

  const payUParams: { [key: string]: any } = {
    merchantId,
    accountId,
    description,
    referenceCode,
    expirationDate,
    amount,
    tax: taxAmount,
    taxReturnBase,
    currency,
    signature,
    buyerEmail,
    extra1,
    responseUrl,
  };

  const submitPayU = () => {
    if (!document) {
      throw new Error('No document enabled');
    }
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', payUUrl); // Use the appropriate PayU URL for your region

    Object.keys(payUParams).forEach((key: string) => {
      const value = payUParams[key];
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', key);
      input.setAttribute('value', value);
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
  };

  return {
    submitPayU,
  };
};
