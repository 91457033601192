import {
  startOfMonth,
  endOfMonth,
  subDays,
  differenceInMonths,
  addMonths,
  subMonths,
  addYears,
  format,
} from 'date-fns';

import { generateArray } from '../../array-utils';
import { formatTz } from '../date-fns-tz-facades';

export const dateToStandardString = (date: Date): string => {
  return formatTz(date, 'yyyy-MM-dd');
};

export const getNYearsAhead = (
  date: Date,
  nYears: number,
  dateFormat: string = 'yyyy'
) => {
  return generateArray(nYears).map((index: number) =>
    formatTz(addYears(date, index), dateFormat)
  );
};

export {
  startOfMonth,
  endOfMonth,
  subDays,
  differenceInMonths,
  addMonths,
  subMonths,
  format,
};
