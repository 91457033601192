import { PPApiService } from '@openeducation/pp-rn-services';
import type { ApiError, Subscription } from '@openeducation/pp-rn-shared-types';
import type { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetCompanyInfoInput {
  subscriptionName: string;
  sfdcToken: string;
}

export const useGetSubscription = (
  { subscriptionName, sfdcToken }: UseGetCompanyInfoInput,
  enabled: boolean = true
) => {
  const { environment } = useEnvironmentContext();

  const { data, isError, isLoading, error } = useQuery<
    Subscription,
    AxiosError<ApiError>
  >(
    [QueryKey.GetSubscription, subscriptionName, sfdcToken],
    () =>
      PPApiService.getSubscription(environment, subscriptionName, sfdcToken),
    { enabled: enabled && !!subscriptionName && !!sfdcToken, retry: false }
  );

  const apiError: ApiError | undefined = error?.response?.data;

  return {
    data,
    apiError,
    isError,
    isLoading,
  };
};
