import { injectGoogleTagManager } from '@openeducation/pp-js-utils';
import { useConfig } from '@openeducation/pp-rn-providers';
import { hostsConfig } from '@openeducation/pp-rn-services';
import { Provider as AtomProvider } from 'jotai';
import React, { useEffect } from 'react';

import { initializeAnalytics } from './src/analytics';
import { AppProviders } from './src/providers/app-providers';
import AppRoutes from './src/router/app-routing';
import { Router } from './src/router/router';

export default function App() {
  const { config } = useConfig();
  const isProd = config?.environment === 'prod';

  useEffect(() => {
    if (isProd) {
      initializeAnalytics();
    }
    injectGoogleTagManager(
      hostsConfig[isProd ? 'prod' : 'stg'].googleTagManagerId
    );
  }, [isProd]);

  if (!config) {
    return null;
  }

  return (
    <AtomProvider>
      <AppProviders env={config.environment}>
        <Router>
          <AppRoutes />
        </Router>
      </AppProviders>
    </AtomProvider>
  );
}
