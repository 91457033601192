import { styled, ThemeType } from '@openeducation/pp-rn-themes';
import { rgba } from 'polished';

import { PPText } from '../../atoms';

type MediumSizeType = {
  isMediumSize?: boolean;
};

type ContainerType = {
  isTablet?: boolean;
} & MediumSizeType;

export const Container = styled.View<ContainerType>`
  background-color: ${({ theme }) => theme.colors.light};
  border-radius: ${({ theme }) => theme.border.radius.medium}px;
  border-left-width: ${({ theme }) => theme.spacing.small}px;
  border-left-color: ${({ theme }) => theme.colors.primary};
  border-right-width: ${({ theme }) => theme.spacing.small}px;
  border-right-color: ${({ theme }) => theme.colors.primary};
  box-shadow: ${({ theme }) =>
    `0 ${theme.spacing.extraSmall}px ${theme.spacing.primarySmall}px ${rgba(
      theme.colors.black,
      0.05
    )}`};
  flex-direction: ${({ isTablet, isMediumSize }) =>
    isTablet || isMediumSize ? 'column' : 'row'};
`;

export const Content = styled.View<ContainerType>`
  ${({ isMediumSize, isTablet }) => !isMediumSize && !isTablet && `flex: 1;`}
`;

export const ContentWrapper = styled.View<MediumSizeType>`
  flex-direction: ${({ isMediumSize }) => (isMediumSize ? 'column' : 'row')};
`;

type CellType = {
  hasBorderBottom?: boolean;
  hasBorderTop?: boolean;
  hasBorderRight?: boolean;
  alignEnd?: boolean;
  isTablet?: boolean;
} & MediumSizeType;

export const Cell = styled.View<CellType>`
  padding: ${({ theme }) => theme.spacing.primary}px;
  ${({ hasBorderBottom, theme }) =>
    hasBorderBottom &&
    `
    border-bottom-width: 1px;
    border-bottom-color: ${theme.colors.border};
  `}
  ${({ hasBorderRight, theme }) =>
    hasBorderRight &&
    `
    border-right-width: 1px;
    border-right-color: ${theme.colors.border};
  `}
  align-items: ${({ alignEnd }) => (alignEnd ? 'flex-end' : 'flex-start')};
  ${({ isMediumSize, isTablet }) => !isMediumSize && !isTablet && `flex: 1;`}
`;

type CommenTextType = {
  theme: ThemeType;
  isBig?: boolean;
};

export const Label = styled(PPText).attrs(
  ({ theme, isBig }: CommenTextType) => ({
    color: theme.colors.dark2,
    fontSize: isBig ? 18 : 12,
    fontType: isBig ? 'medium' : 'regular',
  })
)<CommenTextType>`
  margin-bottom: ${({ theme }) => theme.spacing.tiny}px;
`;

export const Value = styled(PPText).attrs(
  ({ theme, isBig }: CommenTextType) => ({
    color: theme.colors.text,
    fontSize: isBig ? 32 : 24,
    fontType: 'bold',
  })
)<CommenTextType>``;
