export enum CreditCard {
  VISA = 'Visa',
  MASTER_CARD = 'MasterCard',
  ARGEN_CARD = 'ArgenCard',
  CABAL = 'Cabal',
  SHOPPING = 'Shopping',
  TARJETA_NARANJA = 'TarjetaNaranja',
  AMERICAN_EXPRESS = 'AmericanExpress',
  AURA = 'Aura',
  ELO = 'Elo',
  DINERS_CLUB = 'DinersClub',
  DISCOVER = 'Discover',
  HIPER_CARD = 'HiperCard',
  TROY = 'Troy',
}
