import type { PaymentInfoProps } from '@openeducation/pp-rn-shared-types';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface UsePaymentInfo {
  formData: PaymentInfoProps['formData'];
  validation: PaymentInfoProps['validation'];
}

export const usePaymentInfo = ({ formData, validation }: UsePaymentInfo) => {
  const { t } = useTranslation();
  const [focusedField, setFocusedField] = useState<string>();
  const {
    isCardTypeValid,
    isCardNumberValid,
    isCardExpirationValid,
    isHolderNameValid,
  } = validation;

  const cardTypeError = useMemo(() => {
    if (!formData.cardType) {
      return t('input.card_type.error.required');
    }
    return isCardTypeValid ? '' : t('input.card_type.error.valid');
  }, [isCardTypeValid, formData.cardType, t]);

  const cardNumberError = useMemo(() => {
    if (!formData.cardNumber) {
      return t('input.card_number.error.required');
    }
    return isCardNumberValid ? '' : t('input.card_number.error.valid');
  }, [isCardNumberValid, formData.cardNumber, t]);

  const expirationDateError = useMemo(() => {
    if (!formData.expirationMonth && !formData.expirationYear) {
      return t('input.card_expiration.error.required');
    }
    if (!formData.expirationMonth || !formData.expirationYear) {
      return t('input.card_expiration.error.valid');
    }
    return isCardExpirationValid ? '' : t('input.card_expiration.error.tooold');
  }, [
    isCardExpirationValid,
    formData.expirationMonth,
    formData.expirationYear,
    t,
  ]);

  const holderNameError = useMemo(() => {
    if (!formData.holderName) {
      return t('input.card_name.error.required');
    }

    return isHolderNameValid ? '' : t('input.card_name.error.valid');
  }, [formData.holderName, isHolderNameValid, t]);

  const handleFocus = useCallback(
    (name?: string) => name && setFocusedField(name),
    [setFocusedField]
  );

  const handleBlur = useCallback(
    () => setFocusedField(undefined),
    [setFocusedField]
  );

  const cardExpiryDate = useMemo(() => {
    if (!formData.expirationMonth || !formData.expirationYear) {
      return '';
    }

    return `${formData.expirationMonth.padStart(2, '0')}/${
      parseInt(formData.expirationYear, 10) + 2000
    }`;
  }, [formData.expirationMonth, formData.expirationYear]);

  const focusedCardField = useMemo(() => {
    switch (focusedField) {
      case 'cardNumber':
        return 'number';
      case 'expirationMonth':
      case 'expirationYear':
        return 'expiry';
      case 'holderName':
        return 'name';
      case 'cvv':
        return 'cvc';
      default:
        return undefined;
    }
  }, [focusedField]);

  return {
    cardTypeError,
    cardNumberError,
    expirationDateError,
    holderNameError,
    handleFocus,
    handleBlur,
    focusedCardField,
    cardExpiryDate,
  };
};
