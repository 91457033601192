import { FontAwesome5 } from '@expo/vector-icons';
import { WorkflowStatus } from '@openeducation/pp-rn-shared-types';
import { useStyledTheme } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';

import { Loading } from '../../atoms';

interface PaymentStatusModalIconProps {
  status?: WorkflowStatus;
  stoppedPolling?: boolean;
}

const ICON_SIZE = 50;

const PaymentStatusModalIcon = ({
  status,
  stoppedPolling,
}: PaymentStatusModalIconProps) => {
  const { colors } = useStyledTheme();

  switch (status) {
    case WorkflowStatus.PAID:
    case WorkflowStatus.SUCCESS:
      return (
        <FontAwesome5 size={ICON_SIZE} color={colors.success} name="check" />
      );

    case WorkflowStatus.ERROR:
      return (
        <FontAwesome5
          size={ICON_SIZE}
          color={colors.error}
          name="times-circle"
        />
      );
    case WorkflowStatus.RETRY:
    case WorkflowStatus.PENDING:
      return stoppedPolling ? (
        <FontAwesome5 size={ICON_SIZE} color={colors.text} name="clock" />
      ) : (
        <Loading size={ICON_SIZE} />
      );
    default:
      return <Loading size={ICON_SIZE} />;
  }
};

export default memo(PaymentStatusModalIcon);
