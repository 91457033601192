import type {
  Environment,
  ContractParams,
} from '@openeducation/pp-rn-shared-types';

import PPProductCatalogDao from '../dao/pp-product-catalog.dao';
import type { GetTermsOfPurchaseParams } from '../types';

export abstract class PPProductCatalogService {
  static async createContract(env: Environment, contract: ContractParams) {
    const data = await PPProductCatalogDao.createContract(env, contract);
    return data;
  }

  static async acceptContract(env: Environment, contract: ContractParams) {
    const data = await PPProductCatalogDao.acceptContract(env, contract);
    return data;
  }

  static async getTermsOfPurchase(
    env: Environment,
    params: GetTermsOfPurchaseParams
  ) {
    const data = await PPProductCatalogDao.getTermsOfPurchase(env, params);
    return data;
  }
}
