import type { Currency } from '@openeducation/pp-rn-shared-types';

import { useAccountPaymentStatusModalProps } from './use-account-payment-status-modal-props';
import { useAccountStatusInfoProps } from './use-account-status-info-props';
import { useGetAccountScreenData } from './use-get-account-screen-data';
import { useHandleAccountPaymentSubmit } from './use-handle-account-payment-submit';
import { getSubmitPayUParams } from '../../helpers';
import { useErrorModalProps } from '../use-error-modal-props';
import { usePageTemplateProps } from '../use-page-template-props';
import { usePersonalInfoProps } from '../use-personal-info-props';
import type { SubmitYunoParams } from '../use-submit-yuno';

export const useAccountScreen = (accountId: string, baseUrl: string) => {
  const {
    data,
    isScreenLoading,
    contactPhone,
    organization,
    isPendingPayment,
    countryISOCode,
    alternativePaymentInfo,
  } = useGetAccountScreenData({ accountId });

  const { statusInfo, webCheckoutFormData, personalDetails } = data;
  const personalInfo = statusInfo?.personalInfo;

  const { pageTemplateProps } = usePageTemplateProps({
    isScreenLoading,
    contactPhone,
    organization,
  });

  const { accountStatusInfoProps } = useAccountStatusInfoProps({ statusInfo });

  const submitPayUParams = getSubmitPayUParams({
    baseUrl,
    webCheckoutFormData,
    buyerEmail: statusInfo?.personalInfo?.email || '',
    productDescription: statusInfo?.invoice?.number || '',
    amount: statusInfo?.invoice?.amount as number,
    currency: statusInfo?.invoice?.currencyIso as Currency,
  });

  const submitYunoParams: SubmitYunoParams = {
    baseUrl,
    pmCountry: countryISOCode,
    accountId,
  };

  const { paymentSubmitProps } = useHandleAccountPaymentSubmit({
    alternativePaymentInfo,
    accountId,
    submitPayUParams,
    submitYunoParams,
  });

  const hasPendingInvoice = !!accountStatusInfoProps.invoiceId;

  const { errorModalProps } = useErrorModalProps({
    contactPhone,
  });

  const { paymentStatusModalProps } = useAccountPaymentStatusModalProps({
    alternativePaymentGateway: alternativePaymentInfo?.paymentGateway,
  });

  const { personalInfoProps } = usePersonalInfoProps({
    fullName: personalInfo?.fullName,
    email: personalInfo?.email,
    personalDetails: personalDetails && {
      ...personalDetails,
      cpf: false, // NOTE: cpf should be always hidden on the account screen
    },
    countryISOCode,
  });

  return {
    pageTemplateProps,
    accountStatusInfoProps,
    paymentSubmitProps,
    isPendingPayment,
    hasPendingInvoice,
    errorModalProps,
    paymentStatusModalProps,
    personalInfoProps,
    contactPhone,
  };
};
