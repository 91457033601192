import { errorModalAtom } from '@openeducation/pp-rn-jotai-atoms';
import type { ErrorModalProps } from '@openeducation/pp-rn-shared-types';
import { useAtomValue, useSetAtom } from 'jotai';

interface UseErrorModalProps {
  contactPhone?: string;
  isB2B?: boolean;
  showMessage?: boolean;
}

export const useErrorModalProps = ({
  contactPhone,
  isB2B = false,
  showMessage = true,
}: UseErrorModalProps) => {
  const setErrorModal = useSetAtom(errorModalAtom);
  const errorModal = useAtomValue(errorModalAtom);

  const visible = !!errorModal?.visible;
  const errorKey = errorModal?.errorKey;
  const retryEnabled = !!errorModal?.retryEnabled;
  const onRetry = errorModal?.onRetry;

  const handleClose = () => {
    // NOTE: errorModal can't be set to null here, because it clears the errorKey
    // causing weird UI behaviour when closing the modal
    onRetry?.();
    setErrorModal({
      ...errorModal,
      visible: false,
    });
  };

  const errorModalProps: ErrorModalProps = {
    visible,
    contactPhone,
    errorKey,
    onClose: retryEnabled ? handleClose : undefined,
    isB2B,
    showMessage,
  };

  return { errorModalProps };
};
