import type { Organization } from '@openeducation/pp-rn-shared-types';
import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import type { ImageStyle, StyleProp } from 'react-native';

import * as logoAssets from '../../assets/logo';

export interface LogoProps {
  organization: Organization;
  style?: StyleProp<ImageStyle>;
}

export const Logo = ({ organization, style }: LogoProps) => {
  return (
    <LogoImage
      source={logoAssets[organization]}
      resizeMode="contain"
      style={style}
    />
  );
};

const LogoImage = styled.Image`
  width: 110px;
  height: 60px;
`;

export default memo(Logo);
