import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import { Button, ButtonProps } from 'react-native-paper';

export interface PPButtonProps extends Omit<ButtonProps, 'theme'> {}

export const PPButton = ({ mode = 'contained', ...props }: PPButtonProps) => {
  return <StyledButton mode={mode} {...props} />;
};

const StyledButton = styled(Button)`
  border-radius: ${({ theme }) => theme.border.radius.primary}px;
`;

export default memo(PPButton);
