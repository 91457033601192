import { PPPaymentInfoService } from '@openeducation/pp-rn-services';
import type {
  ManualPaymentInfo,
  ManualPaymentInfoParams,
} from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

export const useGetManualPaymentInfoB2B = (
  params: ManualPaymentInfoParams,
  enabled: boolean
) => {
  const { environment } = useEnvironmentContext();
  const { data, isError, isLoading } = useQuery<ManualPaymentInfo>(
    [QueryKey.GetFreeTrialsInfo, params],
    () => PPPaymentInfoService.getManualPaymentInfoB2B(environment, params),
    {
      enabled:
        enabled &&
        (!!params?.country || !!params?.currency || !!params?.organization),
    }
  );

  if (!enabled) {
    return {
      data: {} as ManualPaymentInfo,
      isLoading: false,
      isError: false,
    };
  }

  return {
    data,
    isError,
    isLoading,
  };
};
