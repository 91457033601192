import * as DocumentPicker from 'expo-document-picker';
import { useState } from 'react';

const initialDocumentData = {
  name: '',
  content: '',
  size: 0,
};

export const useFileInput = () => {
  const [base64Document, setBase64Document] = useState(initialDocumentData);
  const pickDocument = async () => {
    try {
      const result = await DocumentPicker.getDocumentAsync({
        type: 'application/pdf,image/jpeg,image/png',
      });

      if (result.type !== 'cancel') {
        setBase64Document({
          name: result.name,
          content: result.uri,
          size: result.size || 0,
        });
      }
    } catch {
      setBase64Document(initialDocumentData);
    }
  };

  return { base64Document, pickDocument };
};
