import {
  pendingPaymentIdAtom,
  selectedPaymentMethodAtom,
} from '@openeducation/pp-rn-jotai-atoms';
import {
  PaymentMethod,
  PaymentGateway,
  PaymentParams,
  PaymentSubmitProps,
} from '@openeducation/pp-rn-shared-types';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { useSubmitInvoicePayment } from '../../../hooks/pp-api/use-submit-invoice-payment';
import { SubmitYunoParams, useSubmitYuno } from '../use-submit-yuno';

interface UseInvoicePaymentSubmitProps {
  invoiceId: string;
  accountId: string;
  baseUrl: string;
  pmCountry?: string | null;
  paymentParams: PaymentParams;
  alternativePaymentGateway?: PaymentGateway;
  isSubmitEnabled: boolean;
  submitYunoParams: SubmitYunoParams;
}
export const useInvoicePaymentSubmitProps = ({
  invoiceId,
  accountId,
  paymentParams,
  submitYunoParams,
  alternativePaymentGateway,
  isSubmitEnabled,
}: UseInvoicePaymentSubmitProps) => {
  const setPendingPaymentId = useSetAtom(pendingPaymentIdAtom);
  const selectedPaymentMethod = useAtomValue(selectedPaymentMethodAtom);

  const {
    submitPayment: submitInvoicePayment,
    isLoading: isInvoicePaymentLoading,
    data: paymentResponse,
    isError: isInvoicePaymentError,
    apiError: invoicePaymentError,
  } = useSubmitInvoicePayment({
    invoiceId,
    accountId,
  });

  const { submitYuno, isYunoLoading, yunoApiError, isYunoError } =
    useSubmitYuno(submitYunoParams);

  const isAlternative = selectedPaymentMethod === PaymentMethod.ALTERNATIVE;
  const isYuno =
    isAlternative && alternativePaymentGateway === PaymentGateway.YUNO;

  const onSubmitButtonPress = () => {
    if (!isSubmitEnabled) {
      return;
    }
    submitInvoicePayment(paymentParams);
  };

  useEffect(() => {
    if (paymentResponse) {
      setPendingPaymentId(paymentResponse);

      if (isYuno) {
        submitYuno();
      }
    }
  }, [paymentResponse, setPendingPaymentId, isYuno, submitYuno]);

  const paymentSubmitProps: PaymentSubmitProps = {
    submitPayment: onSubmitButtonPress,
    isLoading: isInvoicePaymentLoading || isYunoLoading,
    isSubmitEnabled,
    isPayPal: false,
    isPayU: false,
    isYuno,
  };

  return {
    paymentSubmitProps,
    isError: isYunoError || isInvoicePaymentError,
    error: yunoApiError || invoicePaymentError,
    isLoading: isInvoicePaymentLoading || isYunoLoading,
  };
};
