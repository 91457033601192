import * as styledComponents from 'styled-components/native';

import type { ThemeType } from './ThemeType';
import { border } from './border';
import { colors } from './colors';
import { primaryFontFamily } from './fonts';
import { spacing } from './spacing';

const theme: ThemeType = {
  font: primaryFontFamily,
  colors,
  spacing,
  border,
};

const {
  default: styled,
  css,
  ThemeProvider,
  useTheme: useStyledTheme,
} = styledComponents as unknown as styledComponents.ReactNativeThemedStyledComponentsModule<ThemeType>;

export { styled, css, ThemeProvider, theme, useStyledTheme };
