import type { AccountStatusInfoProps } from '@openeducation/pp-rn-shared-types';
import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import InvoiceInfoTableCell from './invoice-info-table-cell';
import { useAccountStatusInfoBanner } from './use-account-status-info-banner';
import { InfoBanner } from '../../molecules';
import { FormSection } from '../../templates';

export const AccountStatusInfo = ({
  status,
  invoiceId,
  dueDate,
  price,
  isSuspended,
}: AccountStatusInfoProps) => {
  const { t } = useTranslation();
  const infoBannerProps = useAccountStatusInfoBanner(status, isSuspended);

  const hasPendingInvoice = !!invoiceId;

  return (
    <Container>
      <InfoBanner {...infoBannerProps} />
      {!!hasPendingInvoice && (
        <FormSection title={t('title.your_account_status')}>
          <InvoiceInfo>
            <TableHeading>
              <InvoiceInfoTableCell isHeading>
                {t('invoice.number')}
              </InvoiceInfoTableCell>
              <InvoiceInfoTableCell isHeading>
                {t('invoice.due_date')}
              </InvoiceInfoTableCell>
              <InvoiceInfoTableCell isHeading>
                {t('invoice.amount_due')}
              </InvoiceInfoTableCell>
            </TableHeading>
            <TableRow>
              <InvoiceInfoTableCell>{invoiceId}</InvoiceInfoTableCell>
              <InvoiceInfoTableCell>{dueDate}</InvoiceInfoTableCell>
              <InvoiceInfoTableCell>{price}</InvoiceInfoTableCell>
            </TableRow>
          </InvoiceInfo>
        </FormSection>
      )}
    </Container>
  );
};

const Container = styled.View`
  margin-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const InvoiceInfo = styled.View`
  border-width: ${({ theme }) => theme.border.width.primary}px;
  border-color: ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.light};
`;

const TableRow = styled.View`
  flex-direction: row;
  align-items: center;
`;

const TableHeading = styled(TableRow)`
  border-bottom-width: ${({ theme }) => theme.border.width.primary}px;
  border-bottom-color: ${({ theme }) => theme.colors.border};
`;

export default memo(AccountStatusInfo);
