import type { InvoiceCardProps } from '@openeducation/pp-rn-shared-types';

export function formatApplovinEventData(invoiceCardProps: InvoiceCardProps) {
  // https://developers.applovin.com/en/audience-plus/events-and-objects#category-ids
  const itemCategoryId: number = 317;
  const items = [
    {
      item_id: invoiceCardProps.id,
      item_name: invoiceCardProps.name,
      item_category_id: itemCategoryId,
      price: invoiceCardProps.total,
      quantity: invoiceCardProps.quantity,
    },
  ];
  const value = items.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );
  return {
    items,
    value,
    currency: invoiceCardProps.currency,
  };
}
