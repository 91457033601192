import { PPApiService } from '@openeducation/pp-rn-services';
import type { Company } from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetCompanyInfoInput {
  countryISOCode?: string;
  sfdcToken: string;
}

export const useGetCompanyInfo = ({
  countryISOCode = '',
  sfdcToken,
}: UseGetCompanyInfoInput) => {
  const { environment } = useEnvironmentContext();

  const { data, isError, isLoading } = useQuery<Company>(
    [QueryKey.GetCompanyInfo, countryISOCode, sfdcToken],
    () => PPApiService.getCompanyInfo(environment, countryISOCode, sfdcToken),
    { enabled: !!countryISOCode && !!sfdcToken }
  );

  return {
    data,
    isError,
    isLoading,
  };
};
