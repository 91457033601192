import { removeEmptyKeys } from '@openeducation/pp-js-utils';
import {
  PaymentParams,
  PaymentInfoFormData,
  PaymentMethod,
  BillingAddress,
  ManualPaymentParams,
  BillingAgreements,
} from '@openeducation/pp-rn-shared-types';
import type { PaymentGateway } from 'libs/pp-rn-shared-types/lib/typescript/src';

interface GetPaymentParams {
  paymentInfo: PaymentInfoFormData;
  documentNumber?: string;
  documentType?: string;
  contractId?: string;
  isUpsell?: boolean;
  sfdcToken?: string;
  billingAddress?: BillingAddress;
  manualPayment?: ManualPaymentParams;
  cpfNumber?: string;
  payPalToken?: string | null;
  usePaymentMethodOnFile?: boolean;
  billingAgreements?: BillingAgreements;
  alternativePaymentGateway?: PaymentGateway | null;
}

export const getPaymentParams = ({
  paymentInfo,
  contractId,
  documentNumber,
  documentType,
  isUpsell,
  sfdcToken,
  billingAddress,
  manualPayment,
  cpfNumber,
  usePaymentMethodOnFile,
  billingAgreements,
  alternativePaymentGateway,
}: GetPaymentParams): PaymentParams => {
  const {
    paymentMethod,
    country,
    expirationMonth,
    expirationYear,
    holderName,
    cardType,
    cardNumber,
    cvv,
    installments,
  } = paymentInfo;
  const { baid: billingAgreementId, email: payPalEmailAddress } =
    (billingAgreements || {}) as BillingAgreements;
  const isUPM = !sfdcToken;

  const creditCardInfo = {
    expirationMonth: parseInt(expirationMonth, 10),
    expirationYear: parseInt(expirationYear, 10) + 2000,
    holderName,
    number: cardNumber.replaceAll(' ', ''),
    type: cardType,
    securityCode: cvv,
    ...(installments ? { installments: parseInt(installments, 10) } : {}),
  };

  return {
    ...(country ? { billingAddress: removeEmptyKeys(billingAddress) } : {}),
    ...(paymentMethod === PaymentMethod.CREDIT_CARD ? { creditCardInfo } : {}),
    ...(paymentMethod === PaymentMethod.MANUAL ? { manualPayment } : {}),
    ...(contractId ? { contractId: contractId.toString() } : {}),
    ...(cpfNumber ? { cpfNumber } : {}),
    ...(documentNumber ? { documentNumber } : {}),
    ...(documentType ? { documentType } : {}),
    paymentMethod,
    ...(isUpsell ? { usePaymentMethodOnFile } : {}),
    ...(sfdcToken ? { paymentInfoToken: sfdcToken } : {}),
    ...(billingAgreements && isUPM
      ? { payPalBillingAgreement: billingAgreements }
      : {}),
    ...(!isUPM && billingAgreementId ? { billingAgreementId } : {}),
    ...(!isUPM && payPalEmailAddress
      ? { paypalEmailAddress: payPalEmailAddress }
      : {}),
    ...(alternativePaymentGateway ? { alternativePaymentGateway } : {}),
  };
};
