import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import type { StyleProp, ViewStyle } from 'react-native';
import { RadioButton } from 'react-native-paper';

export interface RadioProps {
  isChecked: boolean;
  label: string;
  name?: string;
  onPress: (name?: string) => void;
  style?: StyleProp<ViewStyle>;
}

export const Radio = ({
  isChecked,
  onPress,
  label,
  style,
  name,
}: RadioProps) => {
  return (
    <RadioItem
      onPress={() => onPress(name)}
      value=""
      label={label}
      labelVariant="bodyMedium"
      status={isChecked ? 'checked' : 'unchecked'}
      style={style}
    />
  );
};

const RadioItem = styled(RadioButton.Item).attrs(({ theme }) => ({
  labelStyle: {
    color: theme.colors.text,
  },
}))`
  flex-direction: row-reverse;
  padding: 0;
`;

export default memo(Radio);
