import { compareArrayValues } from '@openeducation/pp-js-utils';
import type { ContractInfoProps } from '@openeducation/pp-rn-shared-types';

export interface ValidateContractParams {
  validation: ContractInfoProps['validation'];
  additionalTerms: ContractInfoProps['terms']['additionalTerms'];
  checkedTerms: ContractInfoProps['terms']['checkedTerms'];
  freeTrial: ContractInfoProps['freeTrial'];
}

export const validateContract = ({
  validation: { isContractHolderNameValid },
  additionalTerms,
  checkedTerms,
  freeTrial,
}: ValidateContractParams) => {
  const isContractHolderNameOk = isContractHolderNameValid;

  const requiredTerms = [
    ...(freeTrial.hasFreeTrial ? ['freeTrialAgreement'] : []),
    'tosAgreement',
    ...additionalTerms.map(({ name }) => name),
  ];
  const isAllTermsChecked = compareArrayValues(requiredTerms, checkedTerms);

  return isContractHolderNameOk && isAllTermsChecked;
};
