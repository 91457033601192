import { useStyledTheme } from '@openeducation/pp-rn-themes';
import { marked } from 'marked';
import React, { memo } from 'react';
import RenderHTML from 'react-native-render-html';

export interface MarkdownViewProps {
  children: string;
}

export const MarkdownView = ({ children }: MarkdownViewProps) => {
  const { spacing } = useStyledTheme();
  return (
    <RenderHTML
      source={{ html: marked(children) }}
      tagsStyles={{ p: { marginTop: 0, marginBottom: spacing.primary } }}
    />
  );
};

export default memo(MarkdownView);
