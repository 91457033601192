import { styled, ThemeType } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';

import { PPButton } from '../../atoms';

interface PayPalSubmitButtonProps {
  disabled: boolean;
  isLoading?: boolean;
  onPress: () => void;
}

const PayPalSubmitButton = ({
  disabled,
  isLoading,
  onPress,
}: PayPalSubmitButtonProps) => {
  return (
    <SubmitButton disabled={disabled} loading={isLoading} onPress={onPress}>
      <ButtonImage
        source={{
          uri: 'https://www.paypal.com/en_US/i/btn/btn_xpressCheckout.gif',
        }}
        disabled={disabled}
      />
    </SubmitButton>
  );
};

type SubmitButtonType = {
  loading?: boolean;
  theme: ThemeType;
};

const SubmitButton = styled(PPButton).attrs(
  ({ theme, loading }: SubmitButtonType) => ({
    contentStyle: {
      flexDirection: 'row-reverse',
    },
    labelStyle: {
      textTransform: 'uppercase',
      marginBottom: theme.spacing.tiny,
      marginLeft: theme.spacing.small,
      marginRight: loading ? theme.spacing.medium : theme.spacing.small,
    },
  })
)<SubmitButtonType>`
  align-self: flex-end;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

type ButtonImageType = {
  disabled: boolean;
};

const ButtonImage = styled.Image<ButtonImageType>`
  width: 145px;
  height: 42px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export default memo(PayPalSubmitButton);
