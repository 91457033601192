import { redirectToYuno } from '@openeducation/pp-rn-services';
import type { Country } from '@openeducation/pp-rn-shared-types';
import { useEffect } from 'react';

import { useGetYunoPaymentLink } from '../../hooks';

export interface SubmitYunoParams {
  baseUrl: string;
  pmCountry: Country['isoCode'] | null;
  sfdcToken?: string | null;
  accountId?: string | null;
  invoiceId?: string | null;
  documentNumber?: string | null;
}

export const useSubmitYuno = ({
  baseUrl,
  sfdcToken,
  accountId,
  invoiceId,
  pmCountry,
  documentNumber,
}: SubmitYunoParams) => {
  const callbackUrl = `${baseUrl}/yuno-callback?accountId=${
    accountId || ''
  }&sfdcToken=${sfdcToken || ''}&invoiceId=${invoiceId || ''}`;

  const {
    reset,
    getYunoPaymentLink,
    data: yunoPaymentLink,
    isError,
    isLoading,
    apiError,
  } = useGetYunoPaymentLink({
    ...(sfdcToken ? { paymentInfoToken: sfdcToken } : {}),
    ...(accountId ? { zuoraAccountId: accountId } : {}),
    ...(documentNumber ? { documentNumber } : {}),
    countryIsoCode: pmCountry || '',
    callbackUrl,
  });

  useEffect(() => {
    if (yunoPaymentLink) {
      redirectToYuno(yunoPaymentLink);
    }
  }, [yunoPaymentLink]);

  return {
    yunoReset: reset,
    submitYuno: getYunoPaymentLink,
    yunoApiError: apiError,
    isYunoError: isError,
    isYunoLoading: isLoading,
  };
};
