import { removeEmptyKeys } from '@openeducation/pp-js-utils';
import { PPApiService } from '@openeducation/pp-rn-services';
import type { WebCheckoutFormData } from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetWebCheckoutFormInput {
  sfdcToken?: string;
  accountId?: string;
  countryISOCode?: string;
}

export const useGetWebCheckoutFormData = (
  { countryISOCode, sfdcToken, accountId }: UseGetWebCheckoutFormInput,
  enabled = true
) => {
  const { environment } = useEnvironmentContext();

  const { data, isError, isLoading, refetch } = useQuery<WebCheckoutFormData>(
    [QueryKey.GetWebCheckoutFormData, countryISOCode, sfdcToken],
    () =>
      PPApiService.getWebCheckoutFormData(
        environment,
        removeEmptyKeys({
          countryIsoCode: countryISOCode as string,
          paymentInfoToken: sfdcToken,
          accountId,
        })
      ),
    { enabled: enabled && (!!accountId || (!!countryISOCode && !!sfdcToken)) }
  );

  return {
    data,
    isError,
    isLoading,
    refetch,
  };
};
