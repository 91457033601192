import type {
  AdditionalPaymentInfoProps,
  BillingAddress,
} from '@openeducation/pp-rn-shared-types';

export const getAdditionalPaymentInfo = (
  data: BillingAddress
): AdditionalPaymentInfoProps['formData'] => {
  return {
    address: data?.address1 || '',
    number: data?.address2 || '',
    complement: data?.address3 || '',
    state: data?.state || '',
    city: data?.city || '',
    zipCode: data?.postcode || '',
    documentType: '',
    documentNumber: '',
  };
};
