import { useCallback } from 'react';
import { useNavigate } from 'react-router-native';

import { Path } from '../router';

interface UseCheckoutRedirectionParams {
  sfdcToken?: string;
  accountId?: string;
  invoiceId?: string;
}

export const useCheckoutRedirection = ({
  sfdcToken,
  invoiceId,
  accountId,
}: UseCheckoutRedirectionParams) => {
  const navigate = useNavigate();

  const openCheckoutAfterRedirection = useCallback(() => {
    if (sfdcToken) {
      navigate(`${Path.Checkout}?sfdcToken=${sfdcToken}`);
    }
    if (invoiceId && accountId) {
      navigate(
        Path.Invoice.replace(':accountId', accountId).replace(
          ':invoiceId',
          invoiceId
        )
      );
    } else if (accountId) {
      navigate(Path.PaymentMethod.replace(':accountId', accountId));
    }
  }, [sfdcToken, accountId, invoiceId, navigate]);

  return { openCheckoutAfterRedirection };
};
