import { PPPaymentInfoService } from '@openeducation/pp-rn-services';
import type { FreeTrialsPaymentInfo } from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetFreeTrialsInfoInput {
  sfdcToken: string;
}

export const useGetFreeTrialsInfo = ({
  sfdcToken,
}: UseGetFreeTrialsInfoInput) => {
  const { environment } = useEnvironmentContext();

  const { data, isError, isLoading } = useQuery<FreeTrialsPaymentInfo>(
    [QueryKey.GetFreeTrialsInfo, sfdcToken],
    () => PPPaymentInfoService.getFreeTrialsInfo(environment, sfdcToken),
    { enabled: !!sfdcToken }
  );

  return {
    data,
    isError,
    isLoading,
  };
};
