import { styled, useDimension } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { PPText } from '../../atoms';
import { PhoneButton } from '../../molecules';

export interface PPHomeScreenProps {
  contactPhone: string;
}

export const PPHomeScreen = ({ contactPhone }: PPHomeScreenProps) => {
  const { isMediumSize } = useDimension();
  const { t } = useTranslation();

  return (
    <Container isMediumSize={isMediumSize}>
      <ImageWrapper isMediumSize={isMediumSize}>
        <JennyImage
          source={require('../../assets/jenny3.png')}
          resizeMode="contain"
        />
      </ImageWrapper>
      <Content isMediumSize={isMediumSize}>
        <BodyText>{t('home.text.parag1')}</BodyText>
        <BodyText>{t('home.text.parag2')}</BodyText>
        <StyledPhoneButton contactPhone={contactPhone} />
      </Content>
    </Container>
  );
};

type CommonStyledType = {
  isMediumSize?: boolean;
};

const Container = styled.View<CommonStyledType>`
  margin-top: ${({ theme, isMediumSize }) =>
    isMediumSize ? theme.spacing.primary : theme.spacing.huge}px;
  margin-bottom: ${({ theme }) => theme.spacing.primary}px;
  flex-direction: ${({ isMediumSize }) =>
    isMediumSize ? 'column-reverse' : 'row'};
`;

const ImageWrapper = styled.View<CommonStyledType>`
  margin-top: ${({ theme, isMediumSize }) =>
    isMediumSize ? theme.spacing.primary : 0}px;
  margin-right: ${({ isMediumSize, theme }) =>
    isMediumSize ? 0 : theme.spacing.large}px;
  width: ${({ isMediumSize }) => (isMediumSize ? 50 : 35)}%;
  height: ${({ isMediumSize }) => (isMediumSize ? 300 : 500)}px;
  align-self: ${({ isMediumSize }) => (isMediumSize ? 'center' : 'flex-start')};
`;

const BodyText = styled(PPText).attrs({
  fontSize: 16,
})`
  margin-bottom: ${({ theme }) => theme.spacing.primary}px;
`;

const Content = styled.View<CommonStyledType>`
  ${({ isMediumSize }) => !isMediumSize && `flex: 1;`}
`;

const JennyImage = styled.Image`
  width: 100%;
  height: 100%;
`;

const StyledPhoneButton = styled(PhoneButton)`
  align-self: center;
`;

export default memo(PPHomeScreen);
