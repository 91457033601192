import { styled, useDimension } from '@openeducation/pp-rn-themes';
import React, { memo, ReactNode } from 'react';
import type { StyleProp, ViewStyle } from 'react-native';

import { PPText } from '../../atoms';

export interface InputRowProps {
  label: string;
  children: ReactNode;
  error?: string | null;
  style?: StyleProp<ViewStyle>;
}

export const InputRow = ({ label, error, children, style }: InputRowProps) => {
  const { isMediumSize } = useDimension();

  return (
    <Container isMediumSize={isMediumSize} style={style}>
      <LabelWrapper isMediumSize={isMediumSize}>
        <LabelText>{label}</LabelText>
      </LabelWrapper>
      <InputWrapper isMediumSize={isMediumSize}>
        {children}
        {!!error && <ErrorText>{error}</ErrorText>}
      </InputWrapper>
    </Container>
  );
};

type CommonStyledType = {
  isMediumSize?: boolean;
};

const Container = styled.View<CommonStyledType>`
  margin-bottom: ${({ theme }) => theme.spacing.primary}px;
  flex-direction: ${({ isMediumSize }) => (isMediumSize ? 'column' : 'row')};
`;

const LabelWrapper = styled.View<CommonStyledType>`
  ${({ isMediumSize }) => !isMediumSize && `flex: 1`}
  margin-bottom: ${({ theme, isMediumSize }) =>
    isMediumSize ? theme.spacing.tiny : 0}px;
  align-items: ${({ isMediumSize }) =>
    isMediumSize ? 'flex-start' : 'flex-end'};
  margin-right: ${({ isMediumSize, theme }) =>
    isMediumSize ? 0 : theme.spacing.large}px;
  padding-top: ${({ theme, isMediumSize }) =>
    isMediumSize ? 0 : theme.spacing.small}px;
`;

const LabelText = styled(PPText).attrs({
  fontSize: 14,
  fontType: 'bold',
})`
  text-align: right;
`;

const InputWrapper = styled.View<CommonStyledType>`
  ${({ isMediumSize }) => !isMediumSize && `flex: 5`}
`;

const ErrorText = styled(PPText).attrs(({ theme }) => ({
  fontSize: 14,
  fontType: 'light',
  color: theme.colors.error,
}))``;

export default memo(InputRow);
