import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectInputProps, SelectInput } from '../../../atoms';
import { InputRow } from '../../../molecules';

interface PaymentInfoExpirationDateProps {
  error: string;
  expirationMonthValue: SelectInputProps['selectedValue'];
  expirationMonthData: SelectInputProps['data'];
  expirationYearValue: SelectInputProps['selectedValue'];
  expirationYearData: SelectInputProps['data'];
  onFocus: SelectInputProps['onFocus'];
  onBlur: SelectInputProps['onBlur'];
  onValueChange: SelectInputProps['onValueChange'];
}

const PaymentInfoExpirationDate = ({
  expirationMonthValue,
  expirationMonthData,
  expirationYearValue,
  expirationYearData,
  onFocus,
  onBlur,
  onValueChange,
  error,
}: PaymentInfoExpirationDateProps) => {
  const { t } = useTranslation();

  const commonInputProps = {
    onBlur,
    onFocus,
    onValueChange,
  };

  return (
    <InputRow label={t('input.card_expiration.label')} error={error}>
      <ExpirationWrapper>
        <ExpirationMonthInput
          name="expirationMonth"
          selectedValue={expirationMonthValue}
          data={expirationMonthData}
          placeholder={t('input.card_expiration.month.label')}
          {...commonInputProps}
        />
        <SelectInput
          name="expirationYear"
          selectedValue={expirationYearValue}
          data={expirationYearData}
          placeholder={t('input.card_expiration.year.label')}
          {...commonInputProps}
        />
      </ExpirationWrapper>
    </InputRow>
  );
};

export const ExpirationWrapper = styled.View`
  flex-direction: row;
`;

export const ExpirationMonthInput = styled(SelectInput)`
  margin-right: ${({ theme }) => theme.spacing.primarySmall}px;
`;

export default memo(PaymentInfoExpirationDate);
