import { LocationService } from '@openeducation/pp-rn-services';
import type { Country } from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

export const useGetCountries = (enabled = true) => {
  const { environment } = useEnvironmentContext();

  const {
    data = [],
    isError,
    isLoading,
  } = useQuery<Country[]>(
    QueryKey.GetCountries,
    () => LocationService.getCountries(environment),
    { enabled }
  );

  return {
    data,
    isError,
    isLoading,
  };
};
