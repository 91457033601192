import type { PersonalInfoProps } from '@openeducation/pp-rn-shared-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInput } from '../../atoms';
import { InputRow } from '../../molecules';
import { FormSection } from '../../templates';

export const PersonalInfo = ({
  onFieldChange,
  formData,
  fields,
  validation,
}: PersonalInfoProps) => {
  const { t } = useTranslation();
  const { isFullNameEnabled, isEmailEnabled, isCountryEnabled, isCpfEnabled } =
    fields;
  const { isCpfValid } = validation;

  const cpfError = useMemo(() => {
    if (!formData.cpf) {
      return t('input.cpf.error.required');
    }
    return isCpfValid ? '' : t('input.cpf.error.valid');
  }, [formData.cpf, t, isCpfValid]);

  return (
    <FormSection title={t('title.personal_info')}>
      {isFullNameEnabled && (
        <InputRow label={t('input.full_name.label')}>
          <TextInput name="fullName" value={formData.fullName} disabled />
        </InputRow>
      )}
      {isEmailEnabled && (
        <InputRow label={t('input.email.label')}>
          <TextInput
            name="email"
            value={formData.email}
            placeholder={t('input.email.label') as string}
            disabled
          />
        </InputRow>
      )}
      {isCountryEnabled && (
        <InputRow label={t('input.country.label')}>
          <TextInput
            name="country"
            value={t(`country.${formData.country}`) as string}
            disabled
          />
        </InputRow>
      )}
      {isCpfEnabled && (
        <InputRow label={t('input.cpf.label')} error={cpfError}>
          <TextInput
            name="cpf"
            value={formData.cpf}
            onValueChange={onFieldChange}
            keyboardType="numeric"
            placeholder={t('input.cpf.label') as string}
          />
        </InputRow>
      )}
    </FormSection>
  );
};

export default PersonalInfo;
