export enum Language {
  EN = 'en',
  PT = 'pt',
  ES = 'es',
  TR = 'tr',
  VI = 'vi',
}

export const PP_UI_VALID_LANGUAGES = [
  Language.EN,
  Language.ES,
  Language.PT,
  Language.TR,
  Language.VI,
];

export function isLanguageInEnum(language: string) {
  return Object.values(Language).includes(language as Language);
}
