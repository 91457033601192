import { styled, useDimension } from '@openeducation/pp-rn-themes';
import { rgba } from 'polished';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { PPText } from '../../atoms';

export interface NextInvoiceProps {
  nextInvoiceDate?: string;
}
export const NextInvoice = ({ nextInvoiceDate }: NextInvoiceProps) => {
  const { isMediumSize } = useDimension();
  const { t } = useTranslation();
  if (!nextInvoiceDate) {
    return null;
  }
  return (
    <Container isMediumSize={isMediumSize}>
      <Label>{t('ppui.finaced.label')}</Label>
      <Value>{nextInvoiceDate}</Value>
    </Container>
  );
};

type MessageType = {
  isMediumSize?: boolean;
};

const Container = styled.View<MessageType>`
  background-color: ${({ theme }) => theme.colors.light};
  border-radius: ${({ theme }) => theme.border.radius.medium}px;
  box-shadow: ${({ theme }) =>
    `0 ${theme.spacing.extraSmall}px ${theme.spacing.primarySmall}px ${rgba(
      theme.colors.black,
      0.05
    )}`};
  flex-direction: ${({ isMediumSize }) => (isMediumSize ? 'column' : 'row')};
  align-items: ${({ isMediumSize }) =>
    isMediumSize ? 'flex-start' : 'center'};
  padding: 16px 24px;
  margin-top: 20px;
  justify-content: space-between;
`;

const Label = styled(PPText).attrs(({ theme }) => ({
  color: theme.colors.dark2,
  fontSize: 14,
  fontType: 'medium',
}))``;

export const Value = styled(PPText).attrs(({ theme }) => ({
  color: theme.colors.dark1,
  fontSize: 16,
  fontType: 'bold',
}))``;

export default memo(NextInvoice);
