import { removeEmptyKeys } from '@openeducation/pp-js-utils';
import { PPApiService } from '@openeducation/pp-rn-services';
import type { BillingAgreements } from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetBillingAgreementsInput {
  payPalToken: string | null;
  sfdcToken?: string;
  accountId?: string;
  countryISOCode?: string;
}

export const useGetBillingAgreements = ({
  payPalToken,
  countryISOCode,
  sfdcToken,
  accountId,
}: UseGetBillingAgreementsInput) => {
  const { environment } = useEnvironmentContext();

  const { data, isError, isLoading, refetch } = useQuery<BillingAgreements>(
    [QueryKey.GetBillingAgreements, countryISOCode, sfdcToken],
    () =>
      PPApiService.getBillingAgreements(
        environment,
        payPalToken as string,
        removeEmptyKeys({
          countryIsoCode: countryISOCode as string,
          sfdcToken,
          zuoraAccountId: accountId,
        })
      ),
    {
      enabled:
        !!payPalToken && (!!accountId || (!!countryISOCode && !!sfdcToken)),
    }
  );

  return {
    data,
    isError,
    isLoading,
    refetch,
  };
};
