import type {
  Currency,
  WebCheckoutFormData,
} from '@openeducation/pp-rn-shared-types';

import type { SubmitPayUParams } from '../../hooks/use-submit-payu';

interface GetSubmitPayUParams {
  baseUrl: string;
  webCheckoutFormData?: WebCheckoutFormData;
  buyerEmail: string;
  currency: Currency;
  productDescription: string;
  amount: number;
  extra1?: string;
}

export const getSubmitPayUParams = ({
  baseUrl,
  webCheckoutFormData: formData,
  buyerEmail,
  currency,
  productDescription,
  amount,
  extra1,
}: GetSubmitPayUParams): SubmitPayUParams => {
  return {
    baseUrl,
    merchantId: formData?.merchantId || '',
    accountId: formData?.accountId || '',
    referenceCode: formData?.referenceCode || '',
    expirationDate: formData?.expirationDate || '',
    taxAmount: formData?.taxAmount || 0,
    taxReturnBase: formData?.taxReturnBase || 0,
    signature: formData?.signature || '',
    currency: (currency || '') as Currency,
    description: productDescription,
    amount,
    buyerEmail,
    extra1: extra1 || '',
  };
};
