import { PPApiService } from '@openeducation/pp-rn-services';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetAccountInfoInput {
  subscriptionName: string;
}

export const useGetNextInvoice = (
  { subscriptionName }: UseGetAccountInfoInput,
  skip = true
) => {
  const { environment } = useEnvironmentContext();

  const { data, isError, isLoading } = useQuery<string>(
    [QueryKey.GetAccountInfo, subscriptionName],
    () => PPApiService.getNextInvoiceDate(environment, subscriptionName),
    { enabled: !skip && !!subscriptionName, retry: false }
  );

  return {
    data,
    isError,
    isLoading,
  };
};
