import type { Environment } from '@openeducation/pp-rn-shared-types';
import constate from 'constate';
import React from 'react';

const useEnvironment = ({ env }: { env: Environment }) => {
  const [environment, setEnvironment] = React.useState<Environment>(env);

  React.useEffect(() => setEnvironment(env), [env]);

  return {
    environment,
    changeEnvironment: setEnvironment,
  };
};

export const [EnvironmentProvider, useEnvironmentContext] =
  constate(useEnvironment);
