import { PPApiService } from '@openeducation/pp-rn-services';
import type {
  AccountStatusInfo,
  ApiError,
} from '@openeducation/pp-rn-shared-types';
import type { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetAccountStatusInfoInput {
  accountId: string;
}

export const useGetAccountStatusInfo = (
  { accountId }: UseGetAccountStatusInfoInput,
  enabled = true
) => {
  const { environment } = useEnvironmentContext();

  const { data, isError, error, isLoading } = useQuery<
    AccountStatusInfo,
    AxiosError<ApiError>
  >(
    [QueryKey.GetAccountStatus, accountId],
    () => PPApiService.getAccountStatus(environment, accountId),
    { enabled: enabled && !!accountId }
  );

  const apiError: ApiError | undefined = error?.response?.data;

  return {
    data,
    isError,
    apiError,
    isLoading,
  };
};
