import type {
  Country,
  CountryState,
  City,
  Environment,
} from '@openeducation/pp-rn-shared-types';
import axios from 'axios';

import { hostsConfig } from '../utils';

export default abstract class LocationDao {
  static async getCountryByCode(env: Environment, countryISOCode: string) {
    const url = hostsConfig[env].oeLocationService;
    const { data } = await axios.get<Country[]>(`${url}/countries`, {
      params: {
        isocode: countryISOCode,
      },
    });
    return data;
  }

  static async getCountries(env: Environment) {
    const url = hostsConfig[env].oeLocationService;
    const { data } = await axios.get<Country[]>(`${url}/countries`, {
      params: {
        excludeGeneric: true,
      },
    });
    return data;
  }

  static async getCountryStates(env: Environment, countryId: number) {
    const url = hostsConfig[env].oeLocationService;
    const { data } = await axios.get<CountryState[]>(
      `${url}/countries/${countryId}/states`
    );
    return data;
  }

  static async getCities(env: Environment, stateId: number) {
    const url = hostsConfig[env].oeLocationService;
    const { data } = await axios.get<City[]>(`${url}/states/${stateId}/cities`);
    return data;
  }
}
