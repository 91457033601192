import {
  pendingPaymentAtom,
  pendingPaymentIdAtom,
  errorModalAtom,
  payPalTokenAtom,
  contractIdAtom,
} from '@openeducation/pp-rn-jotai-atoms';
import type { ApiError } from '@openeducation/pp-rn-shared-types';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useEffect } from 'react';

interface UseHandlePaymentErrors {
  submitPaymentReset: () => void;
  acceptContractReset: () => void;
  payPalReset: () => void;
  yunoReset: () => void;
  isAcceptError: boolean;
  isSubmitError: boolean;
  isPayPalError: boolean;
  isYunoError: boolean;
  submitApiError?: ApiError;
  payPalApiError?: ApiError;
  yunoApiError?: ApiError;
}

export const useHandlePaymentErrors = ({
  submitPaymentReset,
  acceptContractReset,
  payPalReset,
  yunoReset,
  isAcceptError,
  isSubmitError,
  isPayPalError,
  isYunoError,
  submitApiError,
  payPalApiError,
  yunoApiError,
}: UseHandlePaymentErrors) => {
  const setPendingPaymentId = useSetAtom(pendingPaymentIdAtom);
  const [pendingPayment, setPendingPayment] = useAtom(pendingPaymentAtom);
  const setPayPalToken = useSetAtom(payPalTokenAtom);
  const setErrorModal = useSetAtom(errorModalAtom);
  const setContractId = useSetAtom(contractIdAtom);

  const resetSubmitState = useCallback(() => {
    submitPaymentReset();
    acceptContractReset();
    payPalReset();
    yunoReset();
  }, [submitPaymentReset, acceptContractReset, payPalReset, yunoReset]);

  const onRetry = useCallback(() => {
    setPayPalToken(null);
    setContractId(null);
  }, [setPayPalToken, setContractId]);

  useEffect(() => {
    if (pendingPayment === null) {
      resetSubmitState();
    }
  }, [pendingPayment, resetSubmitState]);

  useEffect(() => {
    if (isAcceptError || isSubmitError || isYunoError || isPayPalError) {
      setPendingPayment(null);
      setPendingPaymentId(null);
      resetSubmitState();
    }
  }, [
    isAcceptError,
    isSubmitError,
    isPayPalError,
    isYunoError,
    setPendingPayment,
    setPendingPaymentId,
    resetSubmitState,
  ]);

  useEffect(() => {
    if (submitApiError || payPalApiError || yunoApiError) {
      setErrorModal({
        visible: true,
        errorKey:
          submitApiError?.message ||
          payPalApiError?.message ||
          yunoApiError?.message,
        retryEnabled: true,
        onRetry,
      });
    }
  }, [
    submitApiError,
    payPalApiError,
    yunoApiError,
    setErrorModal,
    setPayPalToken,
    onRetry,
  ]);
};
