import type {
  Environment,
  PersonalPaymentDetails,
  CommonPaymentInfo,
  CreditCardPaymentInfo,
  AlternativePaymentInfo,
  FreeTrialsPaymentInfo,
  AdditionalPaymentInfo,
  Installment,
  BillingAddress,
  AddressValidation,
  ManualPaymentInfo,
  CommonPaymentInfoParams,
  ManualPaymentInfoParams,
} from '@openeducation/pp-rn-shared-types';
import axios from 'axios';

import type {
  GetAdditionalPaymentInfoParams,
  GetCreditCardPaymentInfoParams,
  GetInstallmentsParams,
  GetAlternativePaymentInfoParams,
  GetManualPaymentBanksParams,
} from '../types';
import { hostsConfig } from '../utils';

export default abstract class PPPaymentInfoDao {
  static async getPersonalDetails(env: Environment, countryISOCode: string) {
    const url = hostsConfig[env].ppPaymentInfo;
    const { data } = await axios.get<PersonalPaymentDetails>(
      `${url}/data/personal-details`,
      {
        params: {
          country: countryISOCode,
        },
      }
    );
    return data;
  }

  static async getCommonPaymentInfo(
    env: Environment,
    params: CommonPaymentInfoParams,
    isB2B: boolean = false
  ) {
    const url = hostsConfig[env].ppPaymentInfo;
    const { data } = await axios.get<CommonPaymentInfo>(
      `${url}${isB2B ? '/b2b' : ''}/data/payment-information/common`,
      {
        params,
      }
    );
    return data;
  }

  static async getCreditCardPaymentInfo({
    env,
    countryISOCode,
    currency,
    pmCountry,
    isB2B = false,
  }: GetCreditCardPaymentInfoParams) {
    const url = hostsConfig[env].ppPaymentInfo;
    const { data } = await axios.get<CreditCardPaymentInfo>(
      `${url}${isB2B ? '/b2b' : ''}/data/payment-information/credit-card`,
      {
        params: {
          country: countryISOCode,
          currency,
          pmCountry,
        },
      }
    );
    return data;
  }

  static async getAlternativePaymentInfo({
    env,
    currency,
    organization,
    paymentInfoToken,
    accountId,
  }: GetAlternativePaymentInfoParams) {
    const url = hostsConfig[env].ppPaymentInfo;
    const { data } = await axios.get<AlternativePaymentInfo>(
      `${url}/data/payment-information/alternative`,
      {
        params: {
          paymentInfoToken,
          accountId,
          currency,
          organization,
        },
      }
    );
    return data;
  }

  static async getFreeTrialsInfo(env: Environment, sfdcToken: string) {
    const url = hostsConfig[env].ppPaymentInfo;
    const { data } = await axios.get<FreeTrialsPaymentInfo>(
      `${url}/data/payment-information/free-trials`,
      {
        params: {
          paymentInfoToken: sfdcToken,
        },
      }
    );
    return data;
  }

  static async getAdditionalPaymentInfo({
    env,
    countryISOCode,
    currency,
    organization,
    paymentMethod,
    pmCountry,
    isB2B = false,
  }: GetAdditionalPaymentInfoParams) {
    const url = hostsConfig[env].ppPaymentInfo;
    const { data } = await axios.get<AdditionalPaymentInfo>(
      `${url}${isB2B ? '/b2b' : ''}/data/payment-information/additional`,
      {
        params: {
          country: countryISOCode,
          currency,
          organization,
          paymentMethod,
          pmCountry,
        },
      }
    );
    return data;
  }

  static async getInstallments({
    env,
    cardType,
    amount,
    billingFrequency,
    currency,
    initialTerm,
    organization,
    cardBinNumber,
    splitInvoice,
    isB2B = false,
  }: GetInstallmentsParams) {
    const url = hostsConfig[env].ppPaymentInfo;
    const { data } = await axios.get<Installment[]>(
      `${url}${
        isB2B ? '/b2b' : ''
      }/data/payment-information/credit-card/${cardType}`,
      {
        params: {
          amount,
          billingFrequency,
          currency,
          initialTerm,
          organization,
          cardBinNumber,
          splitInvoice,
        },
      }
    );
    return data;
  }

  static async getManualPaymentInfo(
    env: Environment,
    paymentInfoToken: string,
    country: string
  ) {
    const url = hostsConfig[env].ppPaymentInfo;
    const { data } = await axios.get<ManualPaymentInfo>(
      `${url}/data/payment-information/manual-payment?paymentInfoToken=${paymentInfoToken}&country=${country}`
    );
    return data;
  }
  static async getManualPaymentInfoB2B(
    env: Environment,
    params: ManualPaymentInfoParams
  ) {
    const url = hostsConfig[env].ppPaymentInfo;
    const { data } = await axios.get<ManualPaymentInfo>(
      `${url}/b2b/data/payment-information/manual-payment`,
      {
        params,
      }
    );
    return data;
  }
  static async getManualPaymentBanks({
    env,
    paymentGateway,
    currency,
  }: GetManualPaymentBanksParams) {
    const url = hostsConfig[env].ppPaymentInfo;
    const { data } = await axios.get<string[]>(
      `${url}/data/payment-information/manual-payment/${paymentGateway}/banks?currency=${currency}`
    );
    return data;
  }

  static async getAddressValidation(env: Environment, params: BillingAddress) {
    const url = hostsConfig[env].ppPaymentInfo;
    const { data } = await axios.get<AddressValidation>(
      `${url}/validation/address`,
      { params }
    );
    return data;
  }
}
