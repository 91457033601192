import {
  WorkflowStatus,
  PaymentStatusModalProps,
  PaymentMethod,
  PaymentGateway,
} from '@openeducation/pp-rn-shared-types';
import { styled } from '@openeducation/pp-rn-themes';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import PaymentStatusModalIcon from './payment-status-modal-icon';
import { PPText, PPModal, PPButton } from '../../atoms';

export const PaymentStatusModal = ({
  visible,
  status,
  message,
  onClose,
  pendingPayment,
  stoppedPolling,
  isUPM,
  alternativePaymentGateway,
}: PaymentStatusModalProps) => {
  const { t } = useTranslation();

  const translationKey = useMemo(() => {
    if (pendingPayment === PaymentMethod.PAY_PAL) {
      return 'polling.text.paypal_redirect';
    }

    if (
      alternativePaymentGateway &&
      alternativePaymentGateway === PaymentGateway.YUNO &&
      pendingPayment === PaymentMethod.ALTERNATIVE
    ) {
      return 'polling.text.yuno_redirect';
    }
    if (pendingPayment === PaymentMethod.ALTERNATIVE) {
      return 'polling.text.payu_redirect';
    }

    if (isUPM) {
      switch (status) {
        case WorkflowStatus.PAID:
          return 'update_payment.paid';
        case WorkflowStatus.SUCCESS:
          return 'update_payment.success';
        case WorkflowStatus.ERROR:
          return 'update_payment.error';
        case WorkflowStatus.RETRY:
        case WorkflowStatus.PENDING:
          return stoppedPolling
            ? 'polling.text.inprogress'
            : 'update_payment.pending';
        default:
          if (message) {
            return `polling.text.${message}`;
          }
          return 'update_payment.pending';
      }
    }

    switch (status) {
      case WorkflowStatus.PAID:
      case WorkflowStatus.SUCCESS:
        return 'polling.text.success';
      case WorkflowStatus.ERROR:
        return 'polling.text.retry';
      case WorkflowStatus.RETRY:
      case WorkflowStatus.PENDING:
        return stoppedPolling
          ? 'polling.text.inprogress'
          : 'polling.text.pending';
      default:
        if (message) {
          return `polling.text.${message}`;
        }
        return 'polling.text.pending';
    }
  }, [
    pendingPayment,
    alternativePaymentGateway,
    isUPM,
    status,
    stoppedPolling,
    message,
  ]);

  const retryEnabled = !!onClose && status === WorkflowStatus.ERROR;

  return (
    <Modal visible={visible}>
      <IconWrapper>
        <PaymentStatusModalIcon
          status={status}
          stoppedPolling={stoppedPolling}
        />
      </IconWrapper>
      <BodyText>
        {t(translationKey, message ? String(message) : translationKey)}
      </BodyText>

      {retryEnabled && (
        <RetryButton onPress={onClose}>{t('button.retry')}</RetryButton>
      )}
    </Modal>
  );
};

const Modal = styled(PPModal)`
  padding-horizontal: ${({ theme }) => theme.spacing.medium}px;
  padding-vertical: ${({ theme }) => theme.spacing.primary}px;
`;

const IconWrapper = styled.View`
  justify-content: center;
  align-items: center;
`;

const BodyText = styled(PPText).attrs({
  fontSize: 20,
  fontType: 'medium',
})`
  margin-top: ${({ theme }) => theme.spacing.medium}px;
  text-align: center;
`;

const RetryButton = styled(PPButton).attrs({
  labelStyle: {
    textTransform: 'uppercase',
  },
})`
  align-self: flex-end;
  cursor: pointer;
  margin-top: ${({ theme }) => theme.spacing.primary}px;
`;

export default PaymentStatusModal;
