import { LocationService } from '@openeducation/pp-rn-services';
import type { Country } from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetCountryByCodeInput {
  countryISOCode?: string;
}

export const useGetCountryByCode = (
  { countryISOCode = '' }: UseGetCountryByCodeInput,
  enabled = true
) => {
  const { environment } = useEnvironmentContext();

  const { data, isError, isLoading } = useQuery<Country>(
    [QueryKey.GetCountryByCode, countryISOCode],
    () => LocationService.getCountryByCode(environment, countryISOCode),
    { enabled: enabled && !!countryISOCode }
  );

  return {
    data,
    isError,
    isLoading,
  };
};
