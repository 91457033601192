import {
  PPPageTemplate,
  AccountStatusInfo,
  PaymentStatusModal,
  ErrorModal,
  PaymentSubmit,
  PersonalInfo,
  AccountFooterInfo,
} from '@openeducation/pp-rn-components';
import { useAccountScreen } from '@openeducation/pp-rn-providers';
import { getBaseUrl } from '@openeducation/pp-rn-services';
import React from 'react';

import { footerProps } from '../helpers';
import { useParams } from '../router';

const AccountScreen = () => {
  const { accountId = '' } = useParams();
  const baseUrl = getBaseUrl();
  const {
    pageTemplateProps,
    accountStatusInfoProps,
    paymentSubmitProps,
    isPendingPayment,
    hasPendingInvoice,
    personalInfoProps,
    paymentStatusModalProps,
    errorModalProps,
    contactPhone,
  } = useAccountScreen(accountId, baseUrl);

  return (
    <>
      <PPPageTemplate {...pageTemplateProps} footerProps={footerProps}>
        <AccountStatusInfo {...accountStatusInfoProps} />
        {hasPendingInvoice && !isPendingPayment && (
          <PaymentSubmit {...paymentSubmitProps} />
        )}
        {hasPendingInvoice && !isPendingPayment && (
          <PersonalInfo {...personalInfoProps} />
        )}
        <AccountFooterInfo
          isPendingPayment={isPendingPayment}
          contactPhone={contactPhone}
        />
      </PPPageTemplate>
      <PaymentStatusModal {...paymentStatusModalProps} />
      <ErrorModal {...errorModalProps} />
    </>
  );
};

export default AccountScreen;
