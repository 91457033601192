export const validateDocumentNumber = (
  value: string,
  supportedCharacters?: string
) => {
  if (!value) {
    return false;
  }

  const supportedCharsRegex = new RegExp(supportedCharacters || /^[0-9]*$/);
  return supportedCharsRegex.test(value);
};
