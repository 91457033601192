import type { ConfigType, UrlConfigType } from './config.type';

const devUrls: UrlConfigType = {
  paypalUrl:
    'https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
  payuUrl: 'https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/',
  ppApiGateway: 'https://pp-api-gateway.dev.openenglish.com',
  ppPaymentInfo: 'https://pp-payment-info.dev.openenglish.com',
  ppProductCatalog: 'https://pp-product-catalog.dev.openenglish.com',
  oeLocationService: 'https://oe-location-service.dev.openenglish.com',
  oeThirdpartyService: 'http://oe-thirdparty-service.dev.openenglish.com',
  storeUrl: 'http://localhost:19006',
  googleTagManagerId: 'GTM-WZT5CTZN',
};

const stgUrls: UrlConfigType = {
  paypalUrl:
    'https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
  payuUrl: 'https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/',
  ppApiGateway: 'https://pp-api-gateway-ext.stg.openenglish.com',
  ppPaymentInfo: 'https://pp-payment-info-ext.stg.openenglish.com',
  ppProductCatalog: 'https://pp-product-catalog-ext.stg.openenglish.com',
  oeLocationService: 'https://oe-location-service-ext.stg.openenglish.com',
  oeThirdpartyService: 'https://oe-thirdparty-service-ext.stg.openenglish.com',
  storeUrl: 'https://pay.stg.openenglish.com',
  googleTagManagerId: 'GTM-WZT5CTZN',
};

const prodUrls: UrlConfigType = {
  paypalUrl:
    'https://www.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
  payuUrl: 'https://checkout.payulatam.com/ppp-web-gateway-payu/',
  ppApiGateway: 'https://pp-api-gateway-ext.openenglish.com',
  ppPaymentInfo: 'https://pp-payment-info-ext.openenglish.com',
  ppProductCatalog: 'https://pp-product-catalog-ext.openenglish.com',
  oeLocationService: 'https://oe-location-service-ext.openenglish.com',
  oeThirdpartyService: 'https://oe-thirdparty-service-ext.openenglish.com',
  storeUrl: 'https://pay.openenglish.com',
  googleTagManagerId: 'GTM-PQ4P6TZN',
};

const addBearerListToConfig = (list: UrlConfigType): ConfigType => {
  const bearers = ['thirdpartyService'];

  const bearerList = Object.entries(list)
    .filter((item) => bearers.includes(item[0]))
    .map((item) => item[1]);

  return {
    ...list,
    authorizationList: [],
    bearerList,
  };
};

export const hostsConfig = {
  dev: addBearerListToConfig(devUrls),
  stg: addBearerListToConfig(stgUrls),
  prod: addBearerListToConfig(prodUrls),
};
