import { dateToStandardString } from '@openeducation/pp-js-utils';
import type { FreeTrialsPaymentInfo } from '@openeducation/pp-rn-shared-types';
import { styled, useDimension } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { PPText } from '../../atoms';
import { BulletListItem } from '../../molecules';

const InvoiceCardFreeTrial = ({
  freePCs,
  trialDays: freeTrialDays,
  trialEndDateEpoch,
}: FreeTrialsPaymentInfo) => {
  const { isMediumSize, isTablet } = useDimension();
  const { t } = useTranslation();
  const trialDays = freeTrialDays || 1;
  const trialEndDate = trialEndDateEpoch
    ? dateToStandardString(new Date(trialEndDateEpoch * 1000))
    : '';

  return (
    <Container isMediumSize={isMediumSize || isTablet}>
      <Title>{t('ppui.freeTrial.title', { trialDays })}</Title>
      <BulletListItem>{t('ppui.freeTrial.term1')}</BulletListItem>
      <BulletListItem>{t('ppui.freeTrial.term2')}</BulletListItem>
      {freePCs && freePCs > 0 ? (
        <BulletListItem>
          {t('ppui.freeTrial.term3', { freePCs })}
        </BulletListItem>
      ) : null}
      <BulletListItem>
        {t('ppui.freeTrial.term4', { trialDays })}
      </BulletListItem>
      <BulletListItem>
        {t('ppui.freeTrial.term5', { trialEndDate })}
      </BulletListItem>
    </Container>
  );
};

type ContainerType = {
  isMediumSize?: boolean;
  isTablet?: boolean;
};

const Container = styled.View<ContainerType>`
  padding: ${({ theme }) => theme.spacing.primary}px;
  border-right-width: ${({ isMediumSize }) => (isMediumSize ? 0 : 1)}px;
  border-right-color: ${({ theme }) => theme.colors.border};
  border-bottom-width: ${({ isMediumSize }) => (isMediumSize ? 1 : 0)}px;
  border-bottom-color: ${({ theme }) => theme.colors.border};
  ${({ isMediumSize, isTablet }) =>
    !isMediumSize && !isTablet && `max-width: 40%;`}
`;

export const Title = styled(PPText).attrs({
  fontSize: 20,
  fontType: 'semiBold',
})`
  margin-bottom: ${({ theme }) => theme.spacing.primarySmall}px;
`;

export default memo(InvoiceCardFreeTrial);
