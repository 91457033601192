import { addressText } from '@openeducation/pp-rn-services';
import type { AddressValidationModalProps } from '@openeducation/pp-rn-shared-types';
import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { PPText, PPButton } from '../../atoms';

type AddressValidationErrorProps = Pick<
  AddressValidationModalProps,
  'cancel' | 'proceed' | 'address'
>;

export const AddressValidationError = ({
  cancel,
  proceed,
  address,
}: AddressValidationErrorProps) => {
  const { t } = useTranslation();

  return (
    <>
      <BodyText>
        {t('input.address_validation_modal.couldnt_validate')}
      </BodyText>

      <BodyText>{addressText(address)}</BodyText>
      <BodyText>{t('input.address_validation_modal.want_proceed')}</BodyText>
      <ActionBox>
        <ActionButton onPress={cancel}>
          {t('input.address_validation_modal.correctit_button')}
        </ActionButton>
        <ActionButton onPress={proceed}>
          {t('input.address_validation_modal.proceed_button')}
        </ActionButton>
      </ActionBox>
    </>
  );
};

const BodyText = styled(PPText)`
  margin-bottom: ${({ theme }) => theme.spacing.primarySmall}px;
  text-align: center;
`;

const ActionButton = styled(PPButton).attrs({
  labelStyle: {
    textTransform: 'uppercase',
  },
})`
  align-self: flex-end;
  cursor: pointer;
  margin-left: 10px;
`;
const ActionBox = styled.View`
  flex-direction: row;
  justify-content: end;
`;
export default memo(AddressValidationError);
