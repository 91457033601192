import { PPPaymentInfoService } from '@openeducation/pp-rn-services';
import type { ManualPaymentInfo } from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

export const useGetManualPaymentInfo = (
  paymentInfoToken: string,
  country: string,
  enabled = true
) => {
  const { environment } = useEnvironmentContext();
  const { data, isError, isLoading } = useQuery<ManualPaymentInfo>(
    [QueryKey.GetFreeTrialsInfo, paymentInfoToken],
    () =>
      PPPaymentInfoService.getManualPaymentInfo(
        environment,
        paymentInfoToken,
        country
      ),
    { enabled: enabled && !!paymentInfoToken }
  );

  return {
    data,
    isError,
    isLoading,
  };
};
