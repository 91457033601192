export enum ExternalGatewayPaymentStatus {
  /**
   * This status is used for offline payment methods and means the transaction has not been initiated by the customer.
   */
  NOT_INITIATED = 'NotInitiated',
  /**
   * Payment is pending to be processed on gateway side.
   */
  PENDING = 'Pending',
  /**
   * Payment was processed by the gateway, but was rejected.
   */
  FAILED = 'Failed',
  /**
   * Payment was successfully processed by the gateway.
   */
  SUCCESS = 'Success',
  /**
   * Gateway response was not received within the expected time.
   */
  TIMEOUT = 'Timeout',
  /**
   * Gateway failed to process the request, so payment processing should not have been triggered.
   */
  ERROR = 'Error',
}
