import { PPApiService } from '@openeducation/pp-rn-services';
import {
  PaymentFlowType,
  PaymentParams,
  PaymentResponse,
  ApiError,
  SubmitPaymentReturn,
} from '@openeducation/pp-rn-shared-types';
import type { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';

interface UseSubmitPaymentInput {
  paymentFlowType?: PaymentFlowType;
}

export const useSubmitPayment = ({
  paymentFlowType,
}: UseSubmitPaymentInput): SubmitPaymentReturn => {
  const { environment } = useEnvironmentContext();

  const endpoint =
    paymentFlowType === PaymentFlowType.UPSELL
      ? PPApiService.submitUpsellPayment
      : PPApiService.submitFirstPayment;

  const { mutate, reset, data, isError, error, isLoading } = useMutation<
    PaymentResponse,
    AxiosError<any>,
    PaymentParams
  >((params: PaymentParams) => endpoint(environment, params));

  const apiError: ApiError | undefined = error?.response?.data;

  return {
    submitPaymentReset: reset,
    submitPayment: mutate,
    data,
    apiError,
    isError,
    isLoading,
  };
};
