import { CreditCard } from '@openeducation/pp-rn-shared-types';
import cardValidator, { creditCardType } from 'card-validator';

creditCardType.addCard({
  niceType: 'Troy',
  type: 'troy',
  patterns: [9792],
  gaps: [4, 8, 12],
  lengths: [16],
  code: {
    name: 'CVV',
    size: 3,
  },
});

export const validateCardNumber = (value: string) => {
  const response = cardValidator.number(value.replaceAll(' ', ''));

  return response.isValid;
};

export const detectCardType = (value: string): CreditCard | null => {
  const cardType = cardValidator.number(
    value.replaceAll(' ', '').replaceAll('*', '0')
  ).card?.type;
  switch (cardType) {
    case 'visa':
      return CreditCard.VISA;
    case 'mastercard':
      return CreditCard.MASTER_CARD;
    case 'american-express':
      return CreditCard.AMERICAN_EXPRESS;
    case 'troy':
      return CreditCard.TROY;
    default:
      return null;
  }
};

export const validateHolderName = (value: string) => {
  const isValid = cardValidator.cardholderName(value).isValid;
  const holderNameRegex =
    /^[a-zA-Z\s.ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿ\-&#]*$/;

  return isValid && holderNameRegex.test(value);
};
