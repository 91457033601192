import { ExternalGatewayPaymentStatus } from '@openeducation/pp-rn-shared-types';
import { useCallback } from 'react';

import { useNavigate, Path } from '../router';

export const useSuccessfulPaymentRedirection = () => {
  const navigate = useNavigate();

  const openScreenAfterSuccessfulPayment = useCallback(
    (paymentStatus?: ExternalGatewayPaymentStatus) => {
      if (
        paymentStatus === ExternalGatewayPaymentStatus.PENDING ||
        paymentStatus === ExternalGatewayPaymentStatus.SUCCESS
      ) {
        navigate(`${Path.CallbackSuccess}`);
      }
    },
    [navigate]
  );

  return { openScreenAfterSuccessfulPayment };
};
