import { hostsConfig } from '@openeducation/pp-rn-services';
import { Language, Environment } from '@openeducation/pp-rn-shared-types';
import type { i18n } from 'i18next';
import React, { useEffect, ReactNode } from 'react';
import { useTranslation, I18nextProvider } from 'react-i18next';
import { View } from 'react-native';

import { initTranslationWithXHREndpoint } from './i18n';

export interface LocalizationProviderProps {
  children: ReactNode;
  loadingComponent?: React.ReactNode;
  language?: Language;
  environment?: Environment;
  defaultI18n?: i18n;
  endpoint?: string;
}

const LocalizationProviderCtx = ({
  children,
  language,
  environment = 'prod',
  defaultI18n,
  endpoint: customEndpoint,
}: LocalizationProviderProps) => {
  const { i18n, ready: i18nReady } = useTranslation();

  useEffect(() => {
    if (defaultI18n) {
      return;
    }

    const loadPath =
      customEndpoint ||
      `${hostsConfig[environment].oeThirdpartyService}/v1/nls/{{lng}}/messages`;

    initTranslationWithXHREndpoint(loadPath, language);
  }, [environment, defaultI18n, language, customEndpoint]);

  useEffect(() => {
    if (!i18nReady || defaultI18n) {
      return;
    }

    i18n.changeLanguage(language ?? Language.EN);
  }, [i18n, language, i18nReady, defaultI18n]);

  return (
    <I18nextProvider i18n={defaultI18n || i18n}>{children}</I18nextProvider>
  );
};

export const LocalizationProvider = ({
  children,
  loadingComponent,
  language = Language.EN,
  environment = 'prod',
  endpoint,
}: LocalizationProviderProps) => {
  return (
    <React.Suspense fallback={loadingComponent || <View />}>
      <LocalizationProviderCtx
        environment={environment}
        language={language}
        endpoint={endpoint}
      >
        {children}
      </LocalizationProviderCtx>
    </React.Suspense>
  );
};
