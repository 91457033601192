import {
  pmCountryAtom,
  errorModalAtom,
  selectedPaymentMethodAtom,
  selectedPaymentGatewayAtom,
  preferredLanguageAtom,
  paymentMethodOnFileAtom,
  payPalTokenAtom,
} from '@openeducation/pp-rn-jotai-atoms';
import {
  PaymentMethod,
  PaymentFlowType,
  Organization,
  PaymentMethodOnFile,
  PaymentGateway,
  BillingFrequency,
} from '@openeducation/pp-rn-shared-types';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';

import {
  useGetBuyer,
  useGetTinyPayloads,
  useGetCountryByCode,
  useGetCardNumber,
  useGetPersonalDetails,
  useGetCommonPaymentInfo,
  useGetCreditCardPaymentInfo,
  useGetAlternativePaymentInfo,
  useGetCompanyInfo,
  useGetFreeTrialsInfo,
  useGetAdditionalPaymentInfo,
  useGetCountryStates,
  useGetCountries,
  useGetSubscription,
  useGetWebCheckoutFormData,
  useGetManualPaymentInfo,
  useGetBillingAgreements,
  useGetYunoPaymentStatus,
  useGetManualPaymentBanks,
  useGetNextInvoice,
} from '../../../hooks';
import { getOrganizationPhoneKey } from '../../helpers';

interface UseGetCheckoutScreenData {
  sfdcToken: string;
}

export const useGetCheckoutScreenData = ({
  sfdcToken,
}: UseGetCheckoutScreenData) => {
  const pmCountry = useAtomValue(pmCountryAtom);
  const setErrorModal = useSetAtom(errorModalAtom);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useAtom(
    selectedPaymentMethodAtom
  );
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useAtom(
    selectedPaymentGatewayAtom
  );

  const [preferredLanguage, setPreferredLanguage] = useAtom(
    preferredLanguageAtom
  );
  const paymentMethodOnFile = useAtomValue(paymentMethodOnFileAtom);
  const payPalToken = useAtomValue(payPalTokenAtom);
  const isExistingPaymentMethod =
    paymentMethodOnFile === PaymentMethodOnFile.EXISTING;

  const {
    data: tinyPayloads,
    isLoading: tinyPayloadsLoading,
    apiError: tinyPayloadError,
  } = useGetTinyPayloads({ sfdcToken });

  const isUpsell = tinyPayloads?.paymentFlowType === PaymentFlowType.UPSELL;

  const {
    data: buyer,
    isLoading: isBuyerLoading,
    isError: isBuyerError,
    apiError: buyerError,
  } = useGetBuyer(
    {
      sfdcLeadId: tinyPayloads?.sfdcLeadId,
      sfdcAccountId: tinyPayloads?.salesForcePurchaserId,
      sfdcPurchaserContactId: tinyPayloads?.salesForceContactId,
    },
    !isUpsell
  );

  const {
    data: subscription,
    isLoading: isSubscriptionLoading,
    apiError: subscriptionError,
  } = useGetSubscription(
    {
      subscriptionName: tinyPayloads?.subscriptionName || '',
      sfdcToken,
    },
    isUpsell
  );

  const { data: creditCardNumber, isLoading: isLoadingCardNumber } =
    useGetCardNumber({
      creditCardToken: tinyPayloads?.creditCardToken,
    });

  const currency = tinyPayloads?.currencyISOCode;
  const organization = tinyPayloads?.organization;
  const paymentMethod =
    tinyPayloads?.paymentMethod || subscription?.paymentMethod;
  const countryISOCode = (
    buyer?.countryISOCode ||
    subscription?.countryISOCode ||
    ''
  ).toLowerCase();

  useEffect(() => {
    if (!selectedPaymentMethod && paymentMethod) {
      setSelectedPaymentMethod(paymentMethod);
    }
  }, [paymentMethod, selectedPaymentMethod, setSelectedPaymentMethod]);

  useEffect(() => {
    if (tinyPayloadError || buyerError || subscriptionError) {
      setErrorModal({
        visible: true,
        errorKey:
          tinyPayloadError?.message ||
          buyerError?.message ||
          subscriptionError?.message,
        retryEnabled: false,
      });
    }
  }, [tinyPayloadError, buyerError, setErrorModal, subscriptionError]);

  useEffect(() => {
    if (preferredLanguage === null && tinyPayloads?.language) {
      setPreferredLanguage(tinyPayloads?.language);
    }
  }, [tinyPayloads, preferredLanguage, setPreferredLanguage]);

  const { data: allCountries = [], isLoading: isAllCountriesLoading } =
    useGetCountries();

  const { data: country, isLoading: isCountryLoading } = useGetCountryByCode(
    {
      countryISOCode: countryISOCode || 'xx',
    },
    !tinyPayloadsLoading && !isBuyerLoading && !isSubscriptionLoading
  );

  const { data: personalDetails, isLoading: isPersonalDetailsLoading } =
    useGetPersonalDetails({
      countryISOCode,
    });

  const { data: commonPaymentInfo, isLoading: isCommonPaymentInfoLoading } =
    useGetCommonPaymentInfo({
      country: countryISOCode,
      paymentInfoToken: sfdcToken,
    });

  const paymentMethods = commonPaymentInfo?.paymentMethods || [];

  const {
    data: alternativePaymentInfo,
    isLoading: isAlternativePaymentInfoLoading,
  } = useGetAlternativePaymentInfo(
    {
      sfdcToken,
    },
    paymentMethods.includes(PaymentMethod.ALTERNATIVE)
  );

  const { data: yunoPaymentStatus, isLoading: isYunoPaymentStatusLoading } =
    useGetYunoPaymentStatus(
      {
        sfdcToken,
      },
      paymentMethods.includes(PaymentMethod.ALTERNATIVE) &&
        alternativePaymentInfo?.paymentGateway === PaymentGateway.YUNO
    );

  const { data: webCheckoutFormData, isLoading: isWebCheckoutFormDataLoading } =
    useGetWebCheckoutFormData(
      {
        countryISOCode,
        sfdcToken,
      },
      paymentMethods.includes(PaymentMethod.ALTERNATIVE) &&
        alternativePaymentInfo?.paymentGateway === PaymentGateway.PAYU
    );

  const { data: manualPaymentInfo, isLoading: manualPaymentInfoLoading } =
    useGetManualPaymentInfo(
      sfdcToken,
      countryISOCode,
      paymentMethods.includes(PaymentMethod.MANUAL)
    );
  useEffect(() => {
    if (!selectedPaymentGateway && manualPaymentInfo?.selectedGateway) {
      setSelectedPaymentGateway(manualPaymentInfo?.selectedGateway);
    }
  }, [
    manualPaymentInfo?.selectedGateway,
    selectedPaymentGateway,
    setSelectedPaymentGateway,
  ]);

  const { data: manualPaymentBanks, isLoading: isManualPaymentBanksLoading } =
    useGetManualPaymentBanks(
      {
        paymentGateway: selectedPaymentGateway,
        currency,
      },
      selectedPaymentMethod === PaymentMethod.MANUAL
    );

  const {
    data: creditCardPaymentInfo,
    isLoading: isCreditCardPaymentInfoLoading,
  } = useGetCreditCardPaymentInfo(
    {
      countryISOCode,
      currency,
      pmCountry: pmCountry || countryISOCode,
    },
    selectedPaymentMethod === PaymentMethod.CREDIT_CARD
  );

  const {
    data: additionalPaymentInfo,
    isLoading: isAdditionalPaymentInfoLoading,
  } = useGetAdditionalPaymentInfo({
    countryISOCode,
    currency,
    organization,
    paymentMethod: selectedPaymentMethod as PaymentMethod,
    pmCountry: pmCountry || countryISOCode,
  });

  const { data: company, isLoading: isCompanyLoading } = useGetCompanyInfo({
    countryISOCode,
    sfdcToken,
  });

  const { data: freeTrialsInfo, isLoading: hasFreeTrialsInfoLoading } =
    useGetFreeTrialsInfo({
      sfdcToken,
    });

  const { data: countryStates, isLoading: isCountryStatesLoading } =
    useGetCountryStates(
      { countryId: country?.countryId },
      !isExistingPaymentMethod && country?.isoCode !== 'xx'
    );

  const { data: billingAgreements, isLoading: isBillingAgreementsLoading } =
    useGetBillingAgreements({
      payPalToken,
      countryISOCode,
      sfdcToken,
    });

  const isNotFinanced =
    tinyPayloads?.billingFrequency !== BillingFrequency.FINANCED;

  const { data: nextInvoice, isLoading: isNextInvoiceLoading } =
    useGetNextInvoice(
      {
        subscriptionName: tinyPayloads?.subscriptionName || '',
      },
      isNotFinanced
    );

  const isLoadingArray = [
    !tinyPayloads, // NOTE: we should show the loader if BE is down
    isBuyerError,
    tinyPayloadsLoading,
    isBuyerLoading,
    isAllCountriesLoading,
    isCountryLoading,
    isPersonalDetailsLoading,
    isCommonPaymentInfoLoading,
    isCreditCardPaymentInfoLoading,
    isAlternativePaymentInfoLoading,
    isCompanyLoading,
    hasFreeTrialsInfoLoading,
    isAdditionalPaymentInfoLoading,
    isCountryStatesLoading,
    isSubscriptionLoading,
    isWebCheckoutFormDataLoading,
    isBillingAgreementsLoading,
    isLoadingCardNumber,
    isYunoPaymentStatusLoading,
    isManualPaymentBanksLoading,
    isNextInvoiceLoading,
  ];

  const data = {
    tinyPayloads,
    buyer,
    allCountries,
    country,
    personalDetails,
    commonPaymentInfo,
    creditCardPaymentInfo,
    alternativePaymentInfo,
    company,
    freeTrialsInfo,
    additionalPaymentInfo,
    countryStates,
    subscription,
    webCheckoutFormData,
    manualPaymentInfo,
    manualPaymentInfoLoading,
    manualPaymentBanks,
    billingAgreements,
    creditCardNumber,
    yunoPaymentStatus,
    nextInvoice,
  };

  const contactPhone =
    (country &&
      country.phone[
        getOrganizationPhoneKey(organization || Organization.OPEN_ENGLISH)
      ]) ||
    '';

  return {
    isScreenLoading: isLoadingArray.includes(true),
    data,
    contactPhone,
    organization,
    countryISOCode,
    isUpsell,
  };
};
