import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInputProps, TextInput } from '../../../atoms';
import { InputRow } from '../../../molecules';

interface PaymentInfoCardNumberProps extends TextInputProps {
  error?: string;
}

const PaymentInfoCardNumber = ({
  onFocus,
  onBlur,
  value,
  onValueChange,
  error,
  disabled,
}: PaymentInfoCardNumberProps) => {
  const { t } = useTranslation();

  return (
    <InputRow label={t('input.card_number.label')} error={error}>
      <TextInput
        name="cardNumber"
        maxLength={19}
        keyboardType="numeric"
        value={value}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        onValueChange={onValueChange}
        placeholder={t('input.card_number.label') as string}
      />
    </InputRow>
  );
};

export default memo(PaymentInfoCardNumber);
