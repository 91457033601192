import {
  errorModalAtom,
  pendingPaymentAtom,
  pendingPaymentIdAtom,
} from '@openeducation/pp-rn-jotai-atoms';
import {
  PaymentGateway,
  PaymentMethod,
  PaymentSubmitProps,
  AlternativePaymentInfo,
} from '@openeducation/pp-rn-shared-types';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { useUpdatePaymentMethod } from '../../../hooks';
import { useSubmitPayU, SubmitPayUParams } from '../use-submit-payu';
import { useSubmitYuno, SubmitYunoParams } from '../use-submit-yuno';

interface UseHandleAccountPaymentSubmitProps {
  alternativePaymentInfo?: AlternativePaymentInfo;
  accountId: string;
  submitPayUParams: SubmitPayUParams;
  submitYunoParams: SubmitYunoParams;
}

export const useHandleAccountPaymentSubmit = ({
  alternativePaymentInfo,
  accountId,
  submitPayUParams,
  submitYunoParams,
}: UseHandleAccountPaymentSubmitProps) => {
  const [pendingPayment, setPendingPayment] = useAtom(pendingPaymentAtom);
  const { submitPayU } = useSubmitPayU(submitPayUParams);
  const { submitYuno } = useSubmitYuno(submitYunoParams);
  const setPendingPaymentId = useSetAtom(pendingPaymentIdAtom);
  const setErrorModal = useSetAtom(errorModalAtom);

  const alternativePaymentGateway = alternativePaymentInfo?.paymentGateway;
  const isPayU = alternativePaymentGateway === PaymentGateway.PAYU;
  const isYuno = alternativePaymentGateway === PaymentGateway.YUNO;

  const {
    submitPayment: updatePaymentMethod,
    isSuccess: isUpdatePaymentMethodSuccess,
    submitPaymentReset: resetUpdatePaymentMethod,
    apiError: updatePaymentMethodError,
  } = useUpdatePaymentMethod({
    accountId,
  });

  useEffect(() => {
    if (isUpdatePaymentMethodSuccess) {
      if (isYuno) {
        submitYuno();
      } else {
        submitPayU();
      }
      resetUpdatePaymentMethod();
    }
  }, [
    isUpdatePaymentMethodSuccess,
    isYuno,
    resetUpdatePaymentMethod,
    submitPayU,
    submitYuno,
  ]);

  useEffect(() => {
    if (updatePaymentMethodError) {
      setPendingPayment(null);
      resetUpdatePaymentMethod();
      setErrorModal({
        visible: true,
        errorKey: updatePaymentMethodError?.message,
        retryEnabled: true,
      });
    }
  }, [
    updatePaymentMethodError,
    resetUpdatePaymentMethod,
    setPendingPayment,
    setPendingPaymentId,
    setErrorModal,
  ]);

  const submitPayment = () => {
    setPendingPayment(PaymentMethod.ALTERNATIVE);
    updatePaymentMethod({
      paymentMethod: PaymentMethod.ALTERNATIVE,
      ...(alternativePaymentGateway ? { alternativePaymentGateway } : {}),
    });
  };

  const paymentSubmitProps: PaymentSubmitProps = {
    submitPayment,
    isLoading: pendingPayment !== null,
    isSubmitEnabled: true,
    isPayPal: false,
    isPayU,
    isYuno,
  };

  return { paymentSubmitProps };
};
