import {
  paymentMethodOnFileAtom,
  pendingPaymentAtom,
  pendingPaymentIdAtom,
  payPalTokenAtom,
  paymentInfoAtom,
} from '@openeducation/pp-rn-jotai-atoms';
import {
  BillingAddress,
  ManualPaymentParams,
  PaymentInfoFormData,
  PaymentMethod,
  PaymentMethodOnFile,
  PaymentParams,
  PaymentResponse,
  BillingAgreements,
  PaymentGateway,
} from '@openeducation/pp-rn-shared-types';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useCallback, useState } from 'react';

import { getPaymentParams } from '../../../helpers/get-payment-params';

interface UseHandlePaymentSubmit {
  submitPaymentReset: () => void;
  submitPayU: () => void;
  validateAddress: () => void;
  acceptContract?: () => void;
  submitPayPal: () => void;
  submitYuno: () => void;
  submitPayment: (params: PaymentParams) => void;
  paymentResponse?: PaymentResponse;
  pendingPaymentMethod: PaymentMethod;
  paymentInfo: PaymentInfoFormData;
  billingAddress: BillingAddress;
  manualPaymentParams?: ManualPaymentParams;
  isPayU: boolean;
  isYuno: boolean;
  isSubmitError: boolean;
  isPayPalRedirectionFlow: boolean;
  isUpsell?: boolean;
  contractAccepted: boolean;
  isAddressValid: boolean;
  sfdcToken?: string;
  contractId: string;
  documentNumber?: string;
  documentType?: string;
  cpfNumber?: string;
  isScreenLoading?: boolean;
  billingAgreements?: BillingAgreements;
  alternativePaymentGateway?: PaymentGateway;
}

export const useHandlePaymentSubmit = ({
  paymentResponse,
  isPayU,
  isYuno,
  isSubmitError,
  submitPaymentReset,
  submitPayU,
  pendingPaymentMethod,
  validateAddress,
  acceptContract,
  isPayPalRedirectionFlow,
  submitPayPal,
  submitYuno,
  paymentInfo,
  billingAddress,
  sfdcToken,
  contractId,
  documentNumber,
  documentType,
  isUpsell,
  manualPaymentParams,
  submitPayment,
  contractAccepted,
  isAddressValid,
  cpfNumber,
  isScreenLoading,
  billingAgreements,
  alternativePaymentGateway,
}: UseHandlePaymentSubmit) => {
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const setPendingPaymentId = useSetAtom(pendingPaymentIdAtom);
  const setPaymentInfo = useSetAtom(paymentInfoAtom);
  const setPendingPayment = useSetAtom(pendingPaymentAtom);
  const payPalToken = useAtomValue(payPalTokenAtom);
  const selectedPaymentMethodOnFile = useAtomValue(paymentMethodOnFileAtom);

  useEffect(() => {
    if (paymentResponse !== undefined && !isSubmitError) {
      setPendingPaymentId(paymentResponse);

      if (isPayU) {
        submitPayU();
      }
      if (isYuno) {
        submitYuno();
      }

      setPaymentInfo(null);
      submitPaymentReset();
    }
  }, [
    paymentResponse,
    setPendingPaymentId,
    isPayU,
    isYuno,
    submitPayU,
    isSubmitError,
    submitPaymentReset,
    submitYuno,
  ]);

  const onSubmitButtonPress = useCallback(() => {
    setPendingPayment(pendingPaymentMethod);
    validateAddress();

    if (acceptContract) {
      acceptContract();
    } else {
      setTriggerSubmit(true);
    }
  }, [
    setPendingPayment,
    acceptContract,
    pendingPaymentMethod,
    validateAddress,
  ]);

  const handleSubmit = useCallback(() => {
    if (!isAddressValid) {
      return;
    }
    const paymentParams: PaymentParams = getPaymentParams({
      paymentInfo,
      contractId,
      documentNumber,
      documentType,
      isUpsell,
      sfdcToken,
      billingAddress,
      manualPayment: manualPaymentParams,
      cpfNumber,
      usePaymentMethodOnFile:
        selectedPaymentMethodOnFile === PaymentMethodOnFile.EXISTING,
      billingAgreements,
      alternativePaymentGateway,
    });

    if (!payPalToken && isPayPalRedirectionFlow && billingAddress) {
      setPaymentInfo(paymentParams);
      submitPayPal();
      return;
    }

    submitPayment(paymentParams);
    setTriggerSubmit(false);
  }, [
    isAddressValid,
    payPalToken,
    billingAddress,
    isPayPalRedirectionFlow,
    paymentInfo,
    contractId,
    documentNumber,
    documentType,
    isUpsell,
    sfdcToken,
    cpfNumber,
    selectedPaymentMethodOnFile,
    billingAgreements,
    submitPayment,
    submitPayPal,
    alternativePaymentGateway,
    setPaymentInfo,
  ]);

  useEffect(() => {
    if (isScreenLoading) {
      return;
    }

    if (payPalToken && !!billingAgreements) {
      handleSubmit();
      return;
    }

    if ((contractAccepted || triggerSubmit) && isAddressValid) {
      handleSubmit();
    }
  }, [
    handleSubmit,
    isScreenLoading,
    payPalToken,
    billingAgreements,
    contractAccepted,
    triggerSubmit,
    isAddressValid,
    billingAddress,
  ]);

  return { onSubmitButtonPress };
};
