export enum PaymentMethod {
  CREDIT_CARD = 'CreditCard',
  PAY_PAL = 'PayPal',
  ALTERNATIVE = 'Alternative',
  MANUAL = 'Manual',
}

export enum PaymentMethodOnFile {
  EXISTING = 'existingPaymentMethod',
  NEW = 'newPaymentMethod',
}
