import type {
  AdditionalPaymentInfoProps,
  ContractInfoProps,
  PaymentInfoProps,
  PersonalInfoProps,
  ManualPaymentInfoProps,
} from '@openeducation/pp-rn-shared-types';

import type { IsSubmitEnabledParams } from '../../hooks/use-is-submit-enabled';

interface GetIsSubmitEnabledParams {
  personalInfoProps: PersonalInfoProps;
  paymentInfoProps: Omit<PaymentInfoProps, 'renderCardPreview'>;
  additionalPaymentInfoProps?: AdditionalPaymentInfoProps;
  contractInfoProps?: ContractInfoProps;
  manualPaymentInfoProps?: ManualPaymentInfoProps;
}

export const getIsSubmitEnabledParams = ({
  personalInfoProps,
  paymentInfoProps,
  additionalPaymentInfoProps,
  contractInfoProps,
  manualPaymentInfoProps,
}: GetIsSubmitEnabledParams): IsSubmitEnabledParams => {
  return {
    personalInfo: {
      isCpfEnabled: !!personalInfoProps.fields?.isCpfEnabled,
      isCpfValid: !!personalInfoProps.validation?.isCpfValid,
    },
    paymentInfo: {
      fields: paymentInfoProps.fields,
      validation: paymentInfoProps.validation,
      formData: paymentInfoProps.formData,
    },
    ...(additionalPaymentInfoProps
      ? {
          additionalPaymentInfo: {
            fields: additionalPaymentInfoProps.fields,
            validation: additionalPaymentInfoProps.validation,
            formData: additionalPaymentInfoProps.formData,
            isCitySelectionEnabled:
              additionalPaymentInfoProps.selectData.city.length > 0,
          },
        }
      : {}),
    ...(manualPaymentInfoProps
      ? {
          manualPaymentInfo: {
            fields: manualPaymentInfoProps.fields,
            validation: manualPaymentInfoProps.validation,
            formData: manualPaymentInfoProps.formData,
          },
        }
      : {}),
    ...(contractInfoProps
      ? {
          contractInfo: {
            validation: contractInfoProps.validation,
            checkedTerms: contractInfoProps.terms.checkedTerms,
            additionalTerms: contractInfoProps.terms.additionalTerms,
            freeTrial: contractInfoProps.freeTrial,
          },
        }
      : {}),
  };
};
