import {
  errorModalAtom,
  pmCountryAtom,
  selectedPaymentMethodAtom,
  payPalTokenAtom,
} from '@openeducation/pp-rn-jotai-atoms';
import {
  AccountInfo,
  Organization,
  PaymentMethod,
  PaymentMethodStatus,
} from '@openeducation/pp-rn-shared-types';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';

import {
  useGetAccountInfo,
  useGetAccountStatusInfo,
  useGetAdditionalPaymentInfo,
  useGetAlternativePaymentInfo,
  useGetBillingAgreements,
  useGetCommonPaymentInfo,
  useGetCountries,
  useGetCountryByCode,
  useGetCountryStates,
  useGetCreditCardPaymentInfo,
  useGetPersonalDetails,
  useGetWebCheckoutFormData,
} from '../../../hooks';
import { getOrganizationPhoneKey } from '../../helpers';

interface UseGetPaymentMethodScreenData {
  accountId: string;
}

export const useGetPaymentMethodScreenData = ({
  accountId,
}: UseGetPaymentMethodScreenData) => {
  const setErrorModal = useSetAtom(errorModalAtom);
  const pmCountry = useAtomValue(pmCountryAtom);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useAtom(
    selectedPaymentMethodAtom
  );
  const payPalToken = useAtomValue(payPalTokenAtom);

  const {
    data: accountInfo = {} as AccountInfo,
    isLoading: isAccountInfoLoading,
    isError: isInvalidAccount,
  } = useGetAccountInfo({ accountId });

  const isPaymentInProcess =
    accountInfo?.status === PaymentMethodStatus.PAYMENT_IN_PROCESS;
  const enableAdditionalRequests =
    !isAccountInfoLoading && !isInvalidAccount && !isPaymentInProcess;

  const {
    data: statusInfo,
    isLoading: isStatusInfoLoading,
    apiError: statusInfoError,
  } = useGetAccountStatusInfo({ accountId }, enableAdditionalRequests);

  const {
    countryISOCode: countryISO,
    currencyISOCode,
    organization,
    hasOpenBalance,
  } = accountInfo;
  const countryISOCode = (countryISO || '').toLowerCase();

  useEffect(() => {
    if (statusInfoError) {
      setErrorModal({
        visible: true,
        errorKey: statusInfoError.message,
        retryEnabled: false,
      });
    }
  }, [statusInfoError, setErrorModal]);

  const { data: allCountries = [], isLoading: isAllCountriesLoading } =
    useGetCountries(enableAdditionalRequests);

  const { data: country, isLoading: isCountryLoading } = useGetCountryByCode(
    {
      countryISOCode,
    },
    enableAdditionalRequests
  );

  const { data: personalDetails, isLoading: isPersonalDetailsLoading } =
    useGetPersonalDetails(
      {
        countryISOCode,
      },
      enableAdditionalRequests
    );

  const { data: commonPaymentInfo, isLoading: isCommonPaymentInfoLoading } =
    useGetCommonPaymentInfo(
      {
        accountId,
        country: countryISOCode,
        currency: currencyISOCode,
        organization,
        hasOpenBalance,
      },
      enableAdditionalRequests
    );

  const paymentMethods = commonPaymentInfo?.paymentMethods || [];
  const firstPaymentMethod = paymentMethods[0];
  useEffect(() => {
    if (!selectedPaymentMethod && firstPaymentMethod) {
      setSelectedPaymentMethod(firstPaymentMethod);
    }
  }, [firstPaymentMethod, selectedPaymentMethod, setSelectedPaymentMethod]);

  const { data: countryStates, isLoading: isCountryStatesLoading } =
    useGetCountryStates({ countryId: country?.countryId });

  const {
    data: creditCardPaymentInfo,
    isLoading: isCreditCardPaymentInfoLoading,
  } = useGetCreditCardPaymentInfo(
    {
      countryISOCode,
      currency: currencyISOCode,
      pmCountry: pmCountry || countryISOCode,
    },
    selectedPaymentMethod === PaymentMethod.CREDIT_CARD
  );

  const {
    data: alternativePaymentInfo,
    isLoading: isAlternativePaymentInfoLoading,
  } = useGetAlternativePaymentInfo(
    {
      currency: currencyISOCode,
      organization,
      accountId,
    },
    selectedPaymentMethod === PaymentMethod.ALTERNATIVE
  );

  const {
    data: additionalPaymentInfo,
    isLoading: isAdditionalPaymentInfoLoading,
  } = useGetAdditionalPaymentInfo({
    countryISOCode,
    currency: currencyISOCode,
    organization,
    paymentMethod: selectedPaymentMethod as PaymentMethod,
    pmCountry: pmCountry || countryISOCode,
  });

  const { data: webCheckoutFormData, isLoading: isWebCheckoutFormDataLoading } =
    useGetWebCheckoutFormData(
      { accountId },
      paymentMethods.includes(PaymentMethod.ALTERNATIVE)
    );

  const { data: billingAgreements, isLoading: isBillingAgreementsLoading } =
    useGetBillingAgreements({
      payPalToken,
      accountId,
    });

  const isLoadingArray = [
    isAccountInfoLoading,
    isAllCountriesLoading,
    isCountryLoading,
    isPersonalDetailsLoading,
    isCommonPaymentInfoLoading,
    isStatusInfoLoading,
    isCountryStatesLoading,
    isWebCheckoutFormDataLoading,
    isCreditCardPaymentInfoLoading,
    isAlternativePaymentInfoLoading,
    isAdditionalPaymentInfoLoading,
    isBillingAgreementsLoading,
  ];

  const data = {
    accountInfo,
    statusInfo,
    allCountries,
    country,
    personalDetails,
    commonPaymentInfo,
    countryStates,
    webCheckoutFormData,
    creditCardPaymentInfo,
    alternativePaymentInfo,
    additionalPaymentInfo,
    billingAgreements,
  };

  const contactPhone =
    (country &&
      country.phone[
        getOrganizationPhoneKey(organization || Organization.OPEN_ENGLISH)
      ]) ||
    '';

  return {
    isScreenLoading: isLoadingArray.includes(true),
    data,
    contactPhone,
    organization,
    countryISOCode,
    isInvalidAccount,
  };
};
