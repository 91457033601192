import { pendingPaymentAtom } from '@openeducation/pp-rn-jotai-atoms';
import type {
  PaymentStatusModalProps,
  PaymentGateway,
} from '@openeducation/pp-rn-shared-types';
import { useAtom } from 'jotai';

interface UseAccountPaymentStatusModalProps {
  alternativePaymentGateway?: PaymentGateway | null;
}
export const useAccountPaymentStatusModalProps = ({
  alternativePaymentGateway,
}: UseAccountPaymentStatusModalProps) => {
  const [pendingPayment, setPendingPayment] = useAtom(pendingPaymentAtom);

  const handleClose = () => {
    setPendingPayment(null);
  };

  const paymentStatusModalProps: PaymentStatusModalProps = {
    alternativePaymentGateway,
    visible: pendingPayment !== null,
    pendingPayment,
    onClose: handleClose,
  };

  return { paymentStatusModalProps };
};
