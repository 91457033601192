import { FontAwesome5 } from '@expo/vector-icons';
import { useStyledTheme, styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';

import { PPText } from '../../atoms';

export interface InfoMessageProps {
  children: string;
  iconName: string;
}

export const InfoMessage = ({ children, iconName }: InfoMessageProps) => {
  const { colors } = useStyledTheme();
  return (
    <Container>
      <FontAwesome5 size={100} color={colors.text} name={iconName} />
      <BodyText>{children}</BodyText>
    </Container>
  );
};

const Container = styled.View`
  padding: 50px;
  justify-content: center;
  align-items: center;
`;

const BodyText = styled(PPText).attrs({
  fontSize: 20,
  fontType: 'medium',
})`
  margin-top: ${({ theme }) => theme.spacing.medium}px;
  text-align: center;
`;

export default memo(InfoMessage);
