import type { PaymentInfoProps } from '@openeducation/pp-rn-shared-types';
import { styled } from '@openeducation/pp-rn-themes';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  PaymentInfoCountry,
  PaymentInfoPaymentType,
  PaymentInfoCardType,
  PaymentInfoCardNumber,
  PaymentInfoHolderName,
  PaymentInfoCvv,
  PaymentInfoInstallments,
  PaymentInfoExpirationDate,
  PaymentInfoEmail,
} from './fields';
import PaymentMethodOnFileSelector from './payment-method-on-file-selector';
import { usePaymentInfo } from './use-payment-info';
import { FormSection } from '../../templates';

export const PaymentInfo = ({
  renderCardPreview,
  formData,
  onFieldChange,
  selectData,
  fields,
  validation,
  paymentMethodOnFile,
  paypalEmail,
}: PaymentInfoProps) => {
  const { t } = useTranslation();
  const {
    isCountryEnabled,
    isCountryFixed,
    isCardTypeEnabled,
    isCardNumberEnabled,
    isExpirationDateEnabled,
    isHolderNameEnabled,
    isCvvEnabled,
    isInstallmentsEnabled,
    isExistingPaymentMethod,
    isPayPalEmailEnabled,
    isDraftCreditCardNumber,
  } = fields;
  const {
    isEnabled: isPaymentMethodOnFileEnabled,
    ...paymentMethodOnFileProps
  } = paymentMethodOnFile;

  const {
    cardTypeError,
    cardNumberError,
    expirationDateError,
    holderNameError,
    handleFocus,
    handleBlur,
    focusedCardField,
    cardExpiryDate,
  } = usePaymentInfo({ formData, validation });

  const commonInputProps = {
    onFocus: handleFocus,
    onBlur: handleBlur,
    onValueChange: onFieldChange,
  };

  return (
    <FormSection title={t('title.payment_info')}>
      {!isExistingPaymentMethod && isCountryEnabled && (
        <PaymentInfoCountry
          selectedValue={formData.country}
          data={selectData.country}
          disabled={isCountryFixed}
          {...commonInputProps}
        />
      )}
      <PaymentInfoPaymentType
        selectedValue={formData.paymentMethod}
        data={selectData.paymentMethod}
        {...commonInputProps}
      />

      {isPaymentMethodOnFileEnabled && (
        <PaymentMethodOnFileSelector {...paymentMethodOnFileProps} />
      )}
      {isCardTypeEnabled && !isExistingPaymentMethod && (
        <CardWrapper>
          {renderCardPreview &&
            renderCardPreview(
              formData.cardNumber,
              cardExpiryDate,
              formData.holderName,
              formData.cvv,
              focusedCardField
            )}
        </CardWrapper>
      )}
      {isCardTypeEnabled && (
        <PaymentInfoCardType
          error={cardTypeError}
          selectedValue={formData.cardType}
          data={selectData.cardType}
          disabled={isExistingPaymentMethod}
          {...commonInputProps}
        />
      )}
      {isCardNumberEnabled && (
        <PaymentInfoCardNumber
          error={cardNumberError}
          value={formData.cardNumber}
          disabled={isExistingPaymentMethod || isDraftCreditCardNumber}
          {...commonInputProps}
        />
      )}
      {isHolderNameEnabled && (
        <PaymentInfoHolderName
          error={holderNameError}
          value={formData.holderName}
          disabled={isExistingPaymentMethod}
          {...commonInputProps}
        />
      )}
      {isExpirationDateEnabled && (
        <PaymentInfoExpirationDate
          error={expirationDateError}
          expirationMonthValue={formData.expirationMonth}
          expirationMonthData={selectData.expirationMonth}
          expirationYearValue={formData.expirationYear}
          expirationYearData={selectData.expirationYear}
          {...commonInputProps}
        />
      )}
      {!isExistingPaymentMethod && isCvvEnabled && (
        <PaymentInfoCvv value={formData.cvv} {...commonInputProps} />
      )}
      {isInstallmentsEnabled && (
        <PaymentInfoInstallments
          selectedValue={formData.installments}
          data={selectData.installments}
          {...commonInputProps}
        />
      )}
      {isPayPalEmailEnabled && !!paypalEmail && (
        <PaymentInfoEmail value={paypalEmail} />
      )}
    </FormSection>
  );
};

export const CardWrapper = styled.View`
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing.primary}px;
`;

export default PaymentInfo;
