import {
  getTranslatedDay,
  getTranslatedMonth,
} from '@openeducation/pp-js-utils';
import { Language } from '@openeducation/pp-rn-shared-types';

// NOTE: week days must start with Sunday (in this case starting from Jan 2 2022)
const weekDays = Array.from({ length: 7 }, (_, i) => [2022, 1, i + 2]);

const months = Array.from({ length: 12 }, (_, i) => [2022, i, 2]);

const getMonthNames = (language: Language, short?: boolean) =>
  months.map(([year, month, day]) =>
    getTranslatedMonth(new Date(year, month, day), language, short)
  );

const getDayNames = (language: Language, short?: boolean) =>
  weekDays.map(([year, month, day]) =>
    getTranslatedDay(
      new Date(year, month - 1, day),
      language,
      short ? 'upper' : 'capitalize',
      short ? 'eee' : 'eeee'
    )
  );

type CalendarLocaleType = {
  monthNames: string[];
  monthNamesShort: string[];
  dayNames: string[];
  dayNamesShort: string[];
  today: string;
};

const getCalendarLocales = (language: Language): CalendarLocaleType => ({
  monthNames: getMonthNames(language),
  monthNamesShort: getMonthNames(language, true),
  dayNames: getDayNames(language),
  dayNamesShort: getDayNames(language, true),
  today: 'Today', // NOTE: curently not used
});

export const CalendarLocales = Object.values(Language).reduce(
  (prev, lang) => {
    prev[lang] = getCalendarLocales(lang);
    return prev;
  },
  {} as {
    [key in Language]: CalendarLocaleType;
  }
);
