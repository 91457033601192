export interface BorderType {
  radius: {
    primary: number;
    medium: number;
  };
  width: {
    primary: number;
  };
}

export const border: BorderType = {
  radius: {
    primary: 4,
    medium: 8,
  },
  width: {
    primary: 1,
  },
};
