import { formatApplovinEventData } from '@openeducation/pp-js-utils';
import type {
  ApplovinBeginCheckoutEvent,
  ApplovinPurchaseEvent,
  GTMEventData,
  InvoiceCardProps,
} from '@openeducation/pp-rn-shared-types';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export function useGoogleTagManager() {
  const pushEvent = (eventData: GTMEventData) => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(eventData);
    }
  };

  const pushEventCheckoutStarted = (
    leadServiceId: string,
    invoiceCardProps: InvoiceCardProps
  ) => {
    const applovinAxonBeginCheckout: ApplovinBeginCheckoutEvent =
      formatApplovinEventData(invoiceCardProps);
    pushEvent({
      event: 'begin_checkout',
      leadServiceId,
      applovinAxonBeginCheckout,
    });
  };

  const pushEventPurchase = (
    leadServiceId: string,
    invoiceCardProps: InvoiceCardProps,
    transactionId: string
  ) => {
    const eventData = formatApplovinEventData(invoiceCardProps);
    const applovinAxonPurchase: ApplovinPurchaseEvent = {
      ...eventData,
      transaction_id: transactionId,
      user_id: leadServiceId,
    };
    pushEvent({
      event: 'purchase',
      leadServiceId,
      applovinAxonPurchase,
    });
  };

  return {
    pushEvent,
    pushEventCheckoutStarted,
    pushEventPurchase,
  };
}
