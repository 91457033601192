import {
  obfuscateCardNumber,
  dateToStandardString,
} from '@openeducation/pp-js-utils';
import type {
  Contract,
  ContractParams,
} from '@openeducation/pp-rn-shared-types';
import { PaymentMethod } from '@openeducation/pp-rn-shared-types';

export const getContractParams = ({
  tinyPayloads,
  company,
  contractHolderName,
  customerEmailAddress,
  pmCountry,
  creditCardType,
  creditCardNumber,
  paymentMethod,
  preferredLanguage,
  nextInvoice,
}: ContractParams) => {
  const ratePlan = tinyPayloads?.ratePlan;
  const isCreditCard = paymentMethod === PaymentMethod.CREDIT_CARD;

  const nextInvoiceDate = nextInvoice
    ? dateToStandardString(new Date(nextInvoice))
    : '';

  if (
    !tinyPayloads ||
    !ratePlan ||
    !company ||
    !contractHolderName ||
    !customerEmailAddress ||
    !pmCountry ||
    (isCreditCard && (!creditCardNumber || !creditCardType)) ||
    !paymentMethod
  ) {
    return { contractParams: null };
  }

  const contractParams: Contract = {
    // TinyPayloads
    autoRenew: tinyPayloads.autoRenew,
    contractType: tinyPayloads.contractType,
    initialInstallmentFeeWithDiscount:
      tinyPayloads.initialInstallmentFeeWithDiscount,
    initialTerm: tinyPayloads.initialTerm,
    invoiceSplitItems: tinyPayloads.invoiceSplitItems,
    isoCurrencyCode: tinyPayloads.currencyISOCode,
    isoLanguageCode: preferredLanguage || tinyPayloads.language,
    oneTimeFee: tinyPayloads.oneTimeFee,
    oneTimeFeeWithDiscount: tinyPayloads.oneTimeFeeWithDiscount,
    organization: tinyPayloads.organization,
    paymentMethod,
    subscriptionName: tinyPayloads.subscriptionName,
    totalAmount: tinyPayloads.totalAmount,

    // RatePlan
    billingPeriod: ratePlan.billingPeriod,
    companyAddress: company.address,
    companyName: company.name,
    privateClassesCount: ratePlan.privateClassesCount,
    privateClassesType: ratePlan.privateClassesType,
    productQuantity: ratePlan.quantity,
    productName: ratePlan.name,
    ratePlanName: ratePlan.name,
    ratePlans: [ratePlan],

    // Other fields
    contractHolderName,
    customerEmailAddress,
    isoCountryCode: pmCountry,
    ...(creditCardType ? { creditCardType } : {}),
    ...(creditCardNumber
      ? { obfuscatedCreditCardNumber: obfuscateCardNumber(creditCardNumber) }
      : {}),
    nextInvoiceDate,
  };

  return { contractParams };
};
