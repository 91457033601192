import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { PPText } from '../../atoms';
import { PhoneButton } from '../../molecules';

export interface AccountFooterInfoProps {
  contactPhone: string;
  isPendingPayment: boolean;
}

export const AccountFooterInfo = ({
  contactPhone,
  isPendingPayment,
}: AccountFooterInfoProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      {!!isPendingPayment && <BodyText>{t('alert.reminder_to_pay')}</BodyText>}
      <BodyText>{t('alert.questions')}</BodyText>
      <PhoneButton contactPhone={contactPhone} />
    </Container>
  );
};

const Container = styled.View`
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.large}px;
`;

export const BodyText = styled(PPText)`
  margin-bottom: ${({ theme }) => theme.spacing.primary}px;
  text-align: center;
`;

export default memo(AccountFooterInfo);
