import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import type { StyleProp, ViewStyle } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

export type LoadingProps = {
  isFullPage?: boolean;
  style?: StyleProp<ViewStyle>;
} & React.ComponentProps<typeof ActivityIndicator>;

export const Loading = ({ isFullPage, style, ...props }: LoadingProps) => {
  if (isFullPage) {
    return (
      <Container style={style}>
        <ActivityIndicator {...props} />
      </Container>
    );
  }
  return <ActivityIndicator {...props} />;
};

const Container = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export default memo(Loading);
