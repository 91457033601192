import { PPApiService } from '@openeducation/pp-rn-services';
import type {
  PayPalTokenParams,
  ApiError,
} from '@openeducation/pp-rn-shared-types';
import type { AxiosError } from 'axios';
import type { PayPalToken } from 'libs/pp-rn-shared-types/lib/typescript/src';
import { useMutation } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';

export const useCreatePayPalToken = (params: PayPalTokenParams) => {
  const { environment } = useEnvironmentContext();
  const { agreementDescription, countryIsoCode, cancelUrl, returnUrl } = params;

  const requiredParams = [
    agreementDescription,
    countryIsoCode,
    cancelUrl,
    returnUrl,
  ];

  const someParamsAreEmpty = requiredParams.some((item) => !item);

  const { mutate, data, error, isError, isLoading, reset } = useMutation<
    PayPalToken,
    AxiosError<any>
  >(() => {
    if (someParamsAreEmpty) {
      throw new Error('Missing PayPal token params');
    }
    return PPApiService.createPayPalToken(environment, params);
  });

  const apiError: ApiError | undefined = error?.response?.data;

  return {
    reset,
    createPayPalToken: mutate,
    data,
    apiError,
    isError,
    isLoading,
  };
};
