import type {
  ContractInfoProps,
  TermsOfPurchaseItem,
} from '@openeducation/pp-rn-shared-types';

export const getAdditionalTermsArray = (termsData: TermsOfPurchaseItem) =>
  (Object.entries(termsData).map(([name, label]) => ({
    name,
    label,
  })) || []) as ContractInfoProps['terms']['additionalTerms'];
