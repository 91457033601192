import { styled } from '@openeducation/pp-rn-themes';
import React, { memo, useRef } from 'react';
import { useHover } from 'react-native-web-hooks';

import { PPText } from '../../atoms';

interface LanguagePickerDropdownItemProps {
  text: string;
  onPress: () => void;
}

const LanguagePickerDropdownItem = ({
  text,
  onPress,
}: LanguagePickerDropdownItemProps) => {
  const itemRef = useRef(null);
  const isHovered = useHover(itemRef);

  return (
    <Container ref={itemRef} isHovered={isHovered} onPress={onPress}>
      <PPText>{text}</PPText>
    </Container>
  );
};

type ContainerType = {
  isHovered?: boolean;
};

const Container = styled.TouchableOpacity<ContainerType>`
  background-color: ${({ theme, isHovered }) =>
    isHovered ? theme.colors.dark0 : theme.colors.light};
  padding-horizontal: ${({ theme }) => theme.spacing.primary}px;
  padding-vertical: ${({ theme }) => theme.spacing.tiny}px;
`;

export default memo(LanguagePickerDropdownItem);
