import { PPProductCatalogService } from '@openeducation/pp-rn-services';
import type { ContractParams } from '@openeducation/pp-rn-shared-types';
import { useMutation } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';

interface UseAcceptContractInput {
  contract: ContractParams | null;
}

export const useAcceptContract = ({ contract }: UseAcceptContractInput) => {
  const { environment } = useEnvironmentContext();

  const { mutate, data, reset, isError, isLoading } = useMutation<string>(
    () => {
      if (!contract) {
        throw new Error('No contract params provided');
      }
      return PPProductCatalogService.acceptContract(environment, contract);
    }
  );

  return {
    acceptContractReset: reset,
    acceptContract: mutate,
    data,
    isError,
    isLoading,
  };
};
