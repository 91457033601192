import { PPPaymentInfoService } from '@openeducation/pp-rn-services';
import type {
  Currency,
  ManualPaymentBanks,
  PaymentGateway,
} from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetManualPaymentBanksProps {
  paymentGateway: PaymentGateway;
  currency?: Currency;
}
export const useGetManualPaymentBanks = (
  { paymentGateway, currency }: UseGetManualPaymentBanksProps,
  enabled = true
) => {
  const { environment: env } = useEnvironmentContext();
  const {
    data: banks,
    isError,
    isLoading,
  } = useQuery<string[]>(
    [QueryKey.GetBanks, paymentGateway],
    () =>
      PPPaymentInfoService.getManualPaymentBanks({
        currency,
        env,
        paymentGateway,
      }),
    { enabled: enabled && !!paymentGateway && !!currency }
  );

  return {
    data: {
      banks: banks || [],
    } as ManualPaymentBanks,
    isError,
    isLoading,
  };
};
