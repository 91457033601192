import type { Organization, Language } from '@openeducation/pp-rn-shared-types';
import { styled, useDimension, css } from '@openeducation/pp-rn-themes';
import React, { useState } from 'react';
import type { StyleProp, ViewStyle } from 'react-native';
import { IconButton } from 'react-native-paper';

import { Logo } from '../../atoms';
import { LanguagePicker, PhoneButton, HeaderFinanced } from '../../molecules';

export interface ScreenHeaderProps {
  languages: Language[];
  selectedLanguage: Language;
  onLanguageSelect: (lang: Language) => void;
  isLoading?: boolean;
  contactPhone?: string;
  organization?: Organization;
  style?: StyleProp<ViewStyle>;
  isFinanced?: boolean;
}

export const SCREEN_HEADER_HEIGHT = 60;

export const ScreenHeader = ({
  style,
  contactPhone,
  organization,
  languages,
  selectedLanguage,
  onLanguageSelect,
  isLoading,
  isFinanced = false,
}: ScreenHeaderProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isMediumSize, isLargeScreen } = useDimension();

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const renderContactPhone = () => {
    if (isLoading || !contactPhone) {
      return null;
    }
    return (
      <StyledPhoneButton
        isMediumSize={isMediumSize}
        contactPhone={contactPhone}
      />
    );
  };

  const renderLanguagePicker = () => (
    <LanguagePicker
      languages={languages}
      selectedLanguage={selectedLanguage}
      onLanguageSelect={onLanguageSelect}
    />
  );

  const renderMobileMenu = () => {
    if (!isMediumSize || !isMenuOpen) {
      return null;
    }

    return (
      <MobileMenu isLargeScreen={isLargeScreen}>
        {renderContactPhone()}
        {renderLanguagePicker()}
      </MobileMenu>
    );
  };

  return (
    <>
      <Container isMediumSize={isMediumSize} style={style}>
        <Content isLargeScreen={isLargeScreen} isMediumSize={isMediumSize}>
          <LogoWrapper>
            {!!organization && (
              <Logo
                organization={organization.replaceAll(' ', '') as Organization}
              />
            )}
          </LogoWrapper>
          {!isMediumSize && renderContactPhone()}
          {!isMediumSize && renderLanguagePicker()}
          {isMediumSize && (
            <MenuButton
              icon={isMenuOpen ? 'close' : 'menu'}
              size={30}
              onPress={toggleMenu}
            />
          )}
        </Content>
        {renderMobileMenu()}
      </Container>
      {!isLoading && isFinanced && <HeaderFinanced />}
    </>
  );
};

type ContainerType = {
  isMediumSize: boolean;
};

const Container = styled.View<ContainerType>`
  width: 100%;
  min-height: ${SCREEN_HEADER_HEIGHT}px;
  background-color: ${({ theme }) => theme.colors.text};
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

type ContentType = {
  isLargeScreen: boolean;
  isMediumSize?: boolean;
};

const contentStyle = css<ContentType>`
  width: ${({ isLargeScreen }) => (isLargeScreen ? 90 : 100)}%;
  align-items: center;
  align-self: center;
  min-height: ${SCREEN_HEADER_HEIGHT}px;
`;

const Content = styled.View<ContentType>`
  ${contentStyle};
  padding-horizontal: ${({ isLargeScreen, theme }) =>
    isLargeScreen ? `10%` : `${theme.spacing.primary}px`};
  flex-direction: row;
  ${({ isMediumSize }) => !isMediumSize && 'height: 100%'}
`;

const LogoWrapper = styled.View`
  flex: 1;
`;

type StyledPhoneButtonType = {
  isMediumSize?: boolean;
};

const StyledPhoneButton = styled(PhoneButton).attrs(({ theme }) => ({
  color: theme.colors.light,
}))<StyledPhoneButtonType>`
  margin-right: ${({ theme, isMediumSize }) =>
    isMediumSize ? 0 : theme.spacing.primary}px;
  margin-bottom: ${({ theme, isMediumSize }) =>
    isMediumSize ? theme.spacing.primary : 0}px;
`;

const MenuButton = styled(IconButton).attrs(({ theme }) => ({
  iconColor: theme.colors.light,
}))`
  width: undefined;
`;

const MobileMenu = styled.View<ContentType>`
  ${contentStyle};
  padding-horizontal: ${({ isLargeScreen, theme }) =>
    isLargeScreen ? `10%` : `${theme.spacing.medium}px`};
  padding-bottom: ${({ theme }) => theme.spacing.primary}px;
  padding-top: ${({ theme }) => theme.spacing.tiny}px;
  align-items: flex-end;
`;

export default ScreenHeader;
