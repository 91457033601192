import { PPPaymentInfoService } from '@openeducation/pp-rn-services';
import type {
  AddressValidation,
  BillingAddress,
  ApiError,
} from '@openeducation/pp-rn-shared-types';
import type { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

export const useIsAddressValid = (params: BillingAddress) => {
  const { environment } = useEnvironmentContext();

  const { data, refetch, isError, error, isLoading, isRefetching } = useQuery<
    AddressValidation,
    AxiosError<any>
  >(
    [QueryKey.GetCommonPaymentInfo, ...Object.values(params)],
    () => PPPaymentInfoService.getAddressValidation(environment, params),
    { enabled: false }
  );

  const apiError: ApiError | undefined = error?.response?.data;

  return {
    validateAddress: refetch,
    errorMessage: apiError?.message || data?.errorMessage || '',
    validatedAddress: data?.validatedAddress,
    status: data?.status,
    isError,
    isLoading,
    isRefetching,
  };
};
