import { errorModalAtom } from '@openeducation/pp-rn-jotai-atoms';
import { PPApiService } from '@openeducation/pp-rn-services';
import type {
  PaymentParams,
  PaymentResponse,
  ApiError,
  SubmitPaymentReturn,
} from '@openeducation/pp-rn-shared-types';
import type { AxiosError } from 'axios';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useMutation } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';

interface UseSubmitPaymentInput {
  invoiceId: string;
  accountId: string;
}

export const useSubmitInvoicePayment = ({
  invoiceId,
  accountId,
}: UseSubmitPaymentInput): SubmitPaymentReturn => {
  const { environment } = useEnvironmentContext();
  const setErrorModal = useSetAtom(errorModalAtom);

  const { mutate, reset, data, isError, error, isLoading } = useMutation<
    PaymentResponse,
    AxiosError<any>,
    PaymentParams
  >((params: PaymentParams) =>
    PPApiService.submitInvoicePayment(environment, accountId, invoiceId, params)
  );

  const apiError: ApiError | undefined = error?.response?.data;

  useEffect(() => {
    if (apiError) {
      setErrorModal({
        visible: true,
        errorKey: apiError?.message,
        retryEnabled: true,
      });
    }
  }, [setErrorModal, apiError]);

  return {
    submitPaymentReset: reset,
    submitPayment: mutate,
    data,
    apiError,
    isError,
    isLoading,
  };
};
