export const fixMd = (str?: string) => {
  if (!str) return str;

  let fixedStr = str;

  const regex = /#(?:[^#]*#)*\s/;
  const hasWhitespace = regex.test(str);

  if (!hasWhitespace) {
    // add a whitespace after headings - ## heading (if it doesn't exist)
    fixedStr = fixedStr.replace(/^(#{1,4})(?=\S)/gm, '$1 ');
  }

  return fixedStr
    .replace(/>\s+</g, '><') // remove \n in html code
    .replace(/---/g, '\n---'); // add \n before underline sign;
};
