import type {
  Organization,
  PPPageTemplateProps,
} from '@openeducation/pp-rn-shared-types';

interface UsePageTemplateProps {
  isScreenLoading: boolean;
  contactPhone?: string;
  organization?: Organization;
  isB2B?: boolean;
  isFinanced?: boolean;
}

export const usePageTemplateProps = ({
  isScreenLoading,
  contactPhone,
  organization,
  isB2B = false,
  isFinanced = false,
}: UsePageTemplateProps) => {
  const pageTemplateProps: Pick<
    PPPageTemplateProps,
    'isLoading' | 'headerProps'
  > = {
    isLoading: isScreenLoading,
    headerProps: {
      contactPhone: isB2B ? '' : contactPhone,
      organization,
      isFinanced,
    },
  };

  return { pageTemplateProps };
};
