import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import { Linking } from 'react-native';

import { PPText } from '../../atoms';

export interface ScreenFooterProps {
  version: string;
  currentDate: Date;
}

export const SCREEN_FOOTER_HEIGHT = 25;

export const ScreenFooter = ({ version, currentDate }: ScreenFooterProps) => {
  const year = currentDate.getFullYear();

  const goToOEPage = () => {
    Linking.openURL('http://www.openenglish.com');
  };

  return (
    <Container>
      <BodyText>{version}</BodyText>
      <BodyText onPress={goToOEPage}>© {year} Open English</BodyText>
    </Container>
  );
};

const Container = styled.View`
  height: ${SCREEN_FOOTER_HEIGHT}px;
  padding-horizontal: ${({ theme }) => theme.spacing.medium}px;
  background-color: ${({ theme }) => theme.colors.dark2};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
`;

const BodyText = styled(PPText).attrs(({ theme }) => ({
  color: theme.colors.light,
}))`
  text-align: center;
`;

export default memo(ScreenFooter);
