import {
  contractIdAtom,
  payPalTokenAtom,
  selectedPaymentMethodAtom,
} from '@openeducation/pp-rn-jotai-atoms';
import { getUrlParams } from '@openeducation/pp-rn-services';
import { PaymentMethod } from '@openeducation/pp-rn-shared-types';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { useCheckoutRedirection } from '../hooks';

const SuccessScreen = () => {
  const {
    token: payPalToken,
    contractId,
    accountId,
    sfdcToken,
  } = getUrlParams();
  const setPayPalToken = useSetAtom(payPalTokenAtom);
  const setContractId = useSetAtom(contractIdAtom);
  const setSelectedPaymentMethod = useSetAtom(selectedPaymentMethodAtom);
  const { openCheckoutAfterRedirection } = useCheckoutRedirection({
    accountId,
    sfdcToken,
  });

  useEffect(() => {
    if (payPalToken) {
      setPayPalToken(payPalToken);
      setSelectedPaymentMethod(PaymentMethod.PAY_PAL);
    }
    contractId && setContractId(contractId);
    openCheckoutAfterRedirection();
  }, [
    payPalToken,
    setPayPalToken,
    contractId,
    setContractId,
    setSelectedPaymentMethod,
    openCheckoutAfterRedirection,
  ]);

  return null;
};

export default SuccessScreen;
