export enum PaymentGateway {
  FLYWIRE = 'Flywire',
  VENMO = 'Venmo',
  ZELLE = 'Zelle',
  YUNO = 'Yuno',
  PAYU = 'PayU',
  BANKTRANSFER = 'BankTransfer',
  BANKDEPOSIT = 'BankDeposit',
  PAGOMOVIL = 'PagoMovil',
}
