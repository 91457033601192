import { pmCountryAtom } from '@openeducation/pp-rn-jotai-atoms';
import {
  BillingFrequency,
  type CreditCard,
  type Currency,
  type FreeTrialsPaymentInfo,
  type ManualPaymentBanks,
} from '@openeducation/pp-rn-shared-types';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo } from 'react';

import { useContractInfoProps } from './use-contract-info-props';
import { useGetCheckoutScreenData } from './use-get-checkout-screen-data';
import { useInvoiceCardProps } from './use-invoice-card-props';
import { useManualPaymentInfoProps } from './use-manual-payment-info-props';
import { useNextInvoice } from './use-next-invoice';
import { usePaymentSubmitProps } from './use-payment-submit-props';
import { useHandleAddressValidation } from './use-payment-submit-props/use-handle-address-validation';
import type { SubmitPayPalParams } from './use-submit-pay-pal';
import { useGoogleTagManager, useSubmitPayment as useSubmitPaymentCall } from '../../../hooks';
import {
  getBillingAddress,
  getIsSubmitEnabledParams,
  getSubmitPayUParams,
  getManualPaymentParams,
} from '../../helpers';
import { useAdditionalPaymentInfoProps } from '../use-additional-payment-info-props';
import { useErrorModalProps } from '../use-error-modal-props';
import { useIsSubmitEnabled } from '../use-is-submit-enabled';
import { usePageTemplateProps } from '../use-page-template-props';
import { usePaymentInfoProps } from '../use-payment-info-props';
import { usePaymentStatusModalProps } from '../use-payment-status-modal-props';
import { usePersonalInfoProps } from '../use-personal-info-props';
import type { SubmitYunoParams } from '../use-submit-yuno';

export const useCheckoutScreen = (sfdcToken: string, baseUrl: string) => {
  const {
    isScreenLoading,
    data,
    contactPhone,
    organization,
    isUpsell,
    countryISOCode,
  } = useGetCheckoutScreenData({ sfdcToken });
  const {
    tinyPayloads,
    personalDetails,
    commonPaymentInfo,
    allCountries,
    creditCardPaymentInfo,
    alternativePaymentInfo,
    additionalPaymentInfo,
    buyer,
    company,
    subscription,
    webCheckoutFormData,
    countryStates,
    freeTrialsInfo = {} as FreeTrialsPaymentInfo,
    manualPaymentInfo,
    billingAgreements,
    creditCardNumber,
    yunoPaymentStatus,
    manualPaymentBanks = {} as ManualPaymentBanks,
    nextInvoice,
  } = data;

  const paymentFlowType = tinyPayloads?.paymentFlowType;
  const customerEmailAddress =
    buyer?.emailAddress || subscription?.emailAddress || '';
  const productDescription = tinyPayloads?.ratePlan?.name || '';
  const pmCountry = useAtomValue(pmCountryAtom);
  const { hasFreeTrial, trialEndDateEpoch } = freeTrialsInfo;
  const { pushEventCheckoutStarted } = useGoogleTagManager();

  const isFinanced = tinyPayloads?.billingFrequency === BillingFrequency.FINANCED;

  const { pageTemplateProps } = usePageTemplateProps({
    isScreenLoading,
    contactPhone,
    organization,
    isB2B: !!tinyPayloads?.isB2B,
    isFinanced,
  });

  const { nextInvoiceProps } = useNextInvoice(nextInvoice);

  const { invoiceCardProps } = useInvoiceCardProps({
    tinyPayloads,
    freeTrialsInfo,
  });

  const fullName = useMemo(() => {
    if (buyer) {
      return `${buyer.firstName} ${buyer.lastName}`;
    }
    if (subscription) {
      return `${subscription.firstName} ${subscription.lastName}`;
    }
    return '';
  }, [buyer, subscription]);

  const { personalInfoProps } = usePersonalInfoProps({
    fullName,
    email: buyer?.emailAddress || subscription?.emailAddress || '',
    personalDetails,
    countryISOCode,
    isUpsell,
  });

  const { paymentInfoProps } = usePaymentInfoProps({
    countryISOCode,
    commonPaymentInfo,
    allCountries,
    creditCardPaymentInfo,
    tinyPayloads,
    subscription,
    creditCardNumber,
    splitInvoice: invoiceCardProps?.isFirstPayment
  });

  const { additionalPaymentInfoProps } = useAdditionalPaymentInfoProps({
    additionalPaymentInfo,
    isExistingPaymentMethod: paymentInfoProps.fields.isExistingPaymentMethod,
    countryStates,
  });

  const { contractInfoProps, contractParams } = useContractInfoProps({
    customerEmailAddress,
    creditCardNumber: paymentInfoProps.validation.isCardNumberValid
      ? paymentInfoProps.formData.cardNumber
      : '',
    creditCardType: paymentInfoProps.validation.isCardTypeValid
      ? paymentInfoProps.formData.cardType
      : ('' as CreditCard),
    tinyPayloads,
    company,
    freeTrial: {
      hasFreeTrial,
      trialEndDateEpoch,
    },
    nextInvoice
  });

  const billingAddress = getBillingAddress({
    additionalPaymentInfo: additionalPaymentInfoProps.formData,
    pmCountry,
  });

  const { manualPaymentInfoProps } = useManualPaymentInfoProps({
    manualPaymentInfo,
    manualPaymentBanks,
  });

  const manualPaymentParams = getManualPaymentParams(
    manualPaymentInfoProps.formData
  );

  const isSubmitEnabledParams = getIsSubmitEnabledParams({
    personalInfoProps,
    paymentInfoProps,
    additionalPaymentInfoProps,
    contractInfoProps,
    manualPaymentInfoProps,
  });
  const { isSubmitEnabled } = useIsSubmitEnabled(isSubmitEnabledParams);

  const submitPayPalParams: SubmitPayPalParams = {
    baseUrl,
    agreementDescription: productDescription,
    pmCountry,
    sfdcToken,
    zuoraAccountId: null,
  };

  const submitPayUParams = getSubmitPayUParams({
    baseUrl,
    webCheckoutFormData,
    buyerEmail: customerEmailAddress,
    productDescription,
    amount: invoiceCardProps.total,
    extra1: organization as string,
    currency: tinyPayloads?.currencyISOCode as Currency,
  });

  const submitYunoParams: SubmitYunoParams = {
    baseUrl,
    pmCountry,
    sfdcToken,
    documentNumber:
      personalInfoProps.formData.cpf ||
      additionalPaymentInfoProps.formData.documentNumber,
  };

  const submitPaymentReturn = useSubmitPaymentCall({
    paymentFlowType,
  });

  const { addressValidationModalProps } = useHandleAddressValidation({
    billingAddress,
  });

  const { paymentSubmitProps } = usePaymentSubmitProps({
    alternativePaymentInfo,
    paymentInfo: paymentInfoProps.formData,
    documentNumber: additionalPaymentInfoProps.formData.documentNumber,
    documentType: additionalPaymentInfoProps.formData.documentType,
    cpfNumber: personalInfoProps.formData.cpf,
    isUpsell,
    contractParams,
    sfdcToken,
    isSubmitEnabled,
    submitPayPalParams,
    submitPayUParams,
    submitYunoParams,
    manualPaymentParams,
    submitPaymentReturn,
    isScreenLoading,
    billingAgreements,
    billingAddress: addressValidationModalProps.address,
    validateAddress: addressValidationModalProps.validateAddress,
    isAddressValid: addressValidationModalProps.isAddressValid,
  });

  const { errorModalProps } = useErrorModalProps({
    contactPhone,
    isB2B: !!tinyPayloads?.isB2B,
  });

  const { paymentStatusModalProps } = usePaymentStatusModalProps({
    paymentFlowType,
    alternativePaymentGateway: alternativePaymentInfo?.paymentGateway,
    leadServiceId: data.buyer?.leadServiceId,
    invoiceCardProps,
  });

  useEffect(() => {
    const leadServiceId = data.buyer?.leadServiceId;
    if (!leadServiceId) {
      return;
    }
    pushEventCheckoutStarted(leadServiceId, invoiceCardProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.buyer?.leadServiceId]);

  return {
    pageTemplateProps,
    invoiceCardProps,
    personalInfoProps,
    paymentInfoProps,
    additionalPaymentInfoProps,
    contractInfoProps,
    paymentSubmitProps,
    contactPhone,
    errorModalProps,
    paymentStatusModalProps,
    manualPaymentInfoProps,
    addressValidationModalProps,
    yunoPaymentStatus,
    nextInvoiceProps
  };
};
