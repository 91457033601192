import { dateToStandardString } from '@openeducation/pp-js-utils';
import type { ContractInfoProps } from '@openeducation/pp-rn-shared-types';
import { styled } from '@openeducation/pp-rn-themes';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInput, PPText, Loading, Checkbox } from '../../atoms';
import { InputRow } from '../../molecules';
import ContractPreview from '../contract-preview/contract-preview';

export const ContractInfo = ({
  onFieldChange,
  formData,
  validation,
  contract,
  setIsContractHolderFocused,
  terms,
  freeTrial,
}: ContractInfoProps) => {
  const { t } = useTranslation();
  const { contractHolderName } = formData;
  const { isContractHolderNameValid } = validation;
  const { toggleTerm, checkedTerms, additionalTerms } = terms;
  const { hasFreeTrial, trialEndDateEpoch } = freeTrial;

  const trialEndDate = trialEndDateEpoch
    ? dateToStandardString(new Date(trialEndDateEpoch * 1000))
    : '';

  const contractHolderNameError = useMemo(() => {
    if (!contractHolderName) {
      return t('input.contract_holder_name.error.required');
    }
    return isContractHolderNameValid
      ? ''
      : t('input.contract_holder_name.error.valid');
  }, [contractHolderName, t, isContractHolderNameValid]);

  const isChecked = (name: string) =>
    !!checkedTerms.find((checkedName) => checkedName === name);

  const renderContract = () => {
    if (contract.isLoading) {
      return (
        <ContractLoader>
          <Loading />
        </ContractLoader>
      );
    }

    if (!contract.data) {
      return null;
    }

    return <ContractPreview>{contract.data}</ContractPreview>;
  };

  const renderCheckboxes = () => {
    if (!contract.data) {
      return null;
    }

    return (
      <Checkboxes>
        <Checkbox
          isChecked={isChecked('tosAgreement')}
          name="tosAgreement"
          label={t('input.tos.label')}
          onPress={toggleTerm}
        />
        {hasFreeTrial && (
          <Checkbox
            isChecked={isChecked('freeTrialAgreement')}
            name="freeTrialAgreement"
            label={t('input.freeTrial.label', { trialEndDate })}
            onPress={toggleTerm}
          />
        )}
        {additionalTerms.map(
          ({ name, label }: { name: string; label: string }) => (
            <Checkbox
              key={name}
              isChecked={isChecked(name)}
              name={name}
              label={label}
              onPress={toggleTerm}
            />
          )
        )}
      </Checkboxes>
    );
  };

  return (
    <>
      <Title>{t('title.contract')}</Title>
      {renderContract()}
      <InputRow
        label={t('input.contract_holder_name.label')}
        error={contractHolderNameError}
      >
        <TextInput
          onValueChange={onFieldChange}
          name="contractHolderName"
          value={contractHolderName}
          onFocus={() => setIsContractHolderFocused(true)}
          onBlur={() => setIsContractHolderFocused(false)}
          placeholder={t('input.contract_holder_name.label') as string}
        />
      </InputRow>
      {renderCheckboxes()}
    </>
  );
};

const ContractLoader = styled.View`
  padding-bottom: ${({ theme }) => theme.spacing.primary}px;
`;

export const Title = styled(PPText).attrs({
  fontSize: 18,
  fontType: 'semiBold',
})`
  margin-top: ${({ theme }) => theme.spacing.primary}px;
  margin-bottom: ${({ theme }) => theme.spacing.primarySmall}px;
`;

const Checkboxes = styled.View`
  margin-bottom: ${({ theme }) => theme.spacing.primary}px;
`;

export default ContractInfo;
