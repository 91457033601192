export interface ColorsType {
  primary: string;
  accent: string;
  border: string;
  light: string;
  text: string;
  black: string;
  dark0: string;
  dark1: string;
  dark2: string;
  dark3: string;
  disabled: string;
  error: string;
  warning: string;
  info: '#17a2b8';
  success: string;
}

export const colors: ColorsType = {
  primary: '#0078FF',
  accent: '#ed622b',
  border: '#dcdcdc',
  light: '#ffffff',
  dark0: '#eeeeee',
  black: '#000000',
  text: '#222222',
  dark1: '#444444',
  dark2: '#666666',
  dark3: '#999999',
  disabled: 'rgba(0,0,0,0.5)',
  error: '#a94442',
  warning: '#ffc107',
  info: '#17a2b8',
  success: '#7EE600',
};
