import { PPPaymentInfoService } from '@openeducation/pp-rn-services';
import type {
  CreditCardPaymentInfo,
  Currency,
} from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetCreditCardPaymentInfoInput {
  countryISOCode?: string;
  currency?: Currency;
  pmCountry?: string;
  isB2B?: boolean;
}

export const useGetCreditCardPaymentInfo = (
  {
    countryISOCode = '',
    currency = '' as Currency,
    pmCountry = '',
    isB2B = false,
  }: UseGetCreditCardPaymentInfoInput,
  enabled: boolean = true
) => {
  const { environment } = useEnvironmentContext();

  const { data, isError, isLoading } = useQuery<CreditCardPaymentInfo>(
    [QueryKey.GetPersonalDetails, countryISOCode, currency, pmCountry],
    () =>
      PPPaymentInfoService.getCreditCardPaymentInfo({
        env: environment,
        countryISOCode,
        currency,
        pmCountry,
        isB2B,
      }),
    { enabled: enabled && !!countryISOCode && !!currency && !!pmCountry }
  );

  return {
    data,
    isError,
    isLoading,
  };
};
