import { formatLocale } from '@openeducation/pp-js-utils';

import { usePreferredLanguage } from '../use-preferred-language';

export const useNextInvoice = (nextInvoice?: string) => {
  const { preferredLanguage } = usePreferredLanguage();
  if (!nextInvoice) {
    return {
      nextInvoiceProps: {},
    };
  }
  const nextInvoiceDate = nextInvoice
    ? formatLocale(
        new Date(`${nextInvoice} 00:00:00`),
        'MMMM dd, yyyy',
        preferredLanguage
      )
    : '';

  return {
    nextInvoiceProps: {
      nextInvoiceDate,
    },
  };
};
