import { PPPaymentInfoService } from '@openeducation/pp-rn-services';
import type {
  Installment,
  CreditCard,
  Currency,
  Organization,
} from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetInstallmentsInput {
  cardType?: CreditCard;
  amount?: number;
  billingFrequency?: string;
  currency?: Currency;
  initialTerm?: number;
  organization?: Organization;
  cardBinNumber?: string;
  splitInvoice?: boolean;
}

export const useGetInstallments = ({
  cardType = '' as CreditCard,
  amount = 0,
  currency = '' as Currency,
  organization = '' as Organization,
  billingFrequency = '',
  initialTerm = 0,
  cardBinNumber = '',
  splitInvoice = false,
}: UseGetInstallmentsInput) => {
  const { environment } = useEnvironmentContext();

  const inputParams = [
    cardType,
    amount,
    billingFrequency,
    currency,
    initialTerm,
    organization,
    cardBinNumber,
  ];

  const someParamsAreEmpty = inputParams.some((item) => !item);
  const isCardBinOk = cardBinNumber ? cardBinNumber.length === 6 : true;

  const { data, isError, isLoading } = useQuery<Installment[]>(
    [QueryKey.GetInstallments, ...inputParams],
    () =>
      PPPaymentInfoService.getInstallments({
        env: environment,
        cardType,
        amount,
        billingFrequency,
        currency,
        initialTerm,
        organization,
        cardBinNumber,
        splitInvoice,
      }),
    { enabled: isCardBinOk && !someParamsAreEmpty }
  );

  return {
    data,
    isError,
    isLoading,
  };
};
