import { PPApiService } from '@openeducation/pp-rn-services';
import type { InvoiceInfo } from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetInvoiceInfoParams {
  accountId: string;
  invoiceId: string;
}

export const useGetInvoiceData = ({
  accountId,
  invoiceId,
}: UseGetInvoiceInfoParams) => {
  const { environment } = useEnvironmentContext();

  const { data, isError, isLoading } = useQuery<InvoiceInfo>(
    [QueryKey.GetAccountInfo, accountId, invoiceId],
    () => PPApiService.getInvoiceData(environment, accountId, invoiceId),
    { enabled: !!accountId || !!invoiceId, retry: false }
  );

  const pdfLink = PPApiService.getInvoicePdfLink(
    environment,
    accountId,
    invoiceId
  );

  return {
    data,
    isError,
    isLoading,
    pdfLink,
  };
};
