import {
  mapInstallmentsSelectInputData,
  getSelectedInstallment,
} from '@openeducation/pp-js-utils';
import { selectedInstallmentAtom } from '@openeducation/pp-rn-jotai-atoms';
import type {
  CreditCard,
  Currency,
  Organization,
  Installment,
} from '@openeducation/pp-rn-shared-types';
import { useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetInstallments } from '../../../hooks';

interface UseInstallments {
  installmentsFormData: string;
  handleFieldChange: (value: string, name?: string) => void;
  cardType?: CreditCard;
  amount?: number;
  billingFrequency?: string;
  currency?: Currency;
  initialTerm?: number;
  organization?: Organization;
  cardBinNumber: string;
  splitInvoice?: boolean;
}

export const useInstallmentsInput = ({
  installmentsFormData,
  cardType,
  handleFieldChange,
  amount,
  billingFrequency,
  currency,
  initialTerm,
  organization,
  cardBinNumber,
  splitInvoice,
}: UseInstallments) => {
  const setSelectedInstallmentAtom = useSetAtom(selectedInstallmentAtom);
  const { t } = useTranslation();
  const { data: installmentsData = [] as Installment[] } = useGetInstallments({
    cardType,
    amount,
    billingFrequency,
    currency,
    initialTerm,
    organization,
    cardBinNumber,
    splitInvoice,
  });
  const installmentsInputData = useMemo(() => {
    if (!currency || installmentsData.length === 0) {
      return [];
    }
    return mapInstallmentsSelectInputData(
      installmentsData,
      currency,
      t('ppui.installments.total')
    );
  }, [installmentsData, currency, t]);

  const selectedInstallment = useMemo(
    () => getSelectedInstallment(installmentsData, installmentsFormData),
    [installmentsData, installmentsFormData]
  );

  useEffect(() => {
    if (selectedInstallment) {
      setSelectedInstallmentAtom(selectedInstallment);
    }
  }, [selectedInstallment, setSelectedInstallmentAtom]);

  useEffect(() => {
    if (installmentsFormData !== '' && installmentsInputData?.length === 0) {
      handleFieldChange('', 'installments');
    }
  }, [installmentsInputData, handleFieldChange, installmentsFormData]);
  return {
    installmentsInputData,
  };
};
