import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import type { StyleProp, ViewStyle } from 'react-native';
import { Checkbox as PaperCheckbox } from 'react-native-paper';
import RenderHTML from 'react-native-render-html';

export interface CheckboxProps {
  isChecked: boolean;
  label: string;
  name?: string;
  onPress: (name?: string) => void;
  style?: StyleProp<ViewStyle>;
}

export const Checkbox = ({
  isChecked,
  label,
  onPress,
  style,
  name,
}: CheckboxProps) => {
  const handleCheck = () => onPress(name);

  return (
    <Container onPress={handleCheck} activeOpacity={1}>
      <CheckboxItem
        onPress={handleCheck}
        label=""
        labelVariant="bodyMedium"
        status={isChecked ? 'checked' : 'unchecked'}
        style={style}
      />
      <TextWrapper>
        <RenderHTML source={{ html: label }} />
      </TextWrapper>
    </Container>
  );
};

const Container = styled.TouchableOpacity`
  flex-direction: row;
`;

const CheckboxItem = styled(PaperCheckbox.Item).attrs(({ theme }) => ({
  labelStyle: {
    color: theme.colors.text,
  },
}))`
  flex-direction: row-reverse;
  margin-left: ${({ theme }) => -theme.spacing.tiny}px;
  padding: 0;
`;

const TextWrapper = styled.View`
  flex: 1;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing.small}px;
`;

export default memo(Checkbox);
