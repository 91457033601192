import { selectedInstallmentAtom } from '@openeducation/pp-rn-jotai-atoms';
import type {
  InvoiceCardProps,
  TinyPayloads,
  Currency,
  FreeTrialsPaymentInfo,
} from '@openeducation/pp-rn-shared-types';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';

interface UseInvoiceCardProps {
  tinyPayloads?: TinyPayloads;
  freeTrialsInfo?: FreeTrialsPaymentInfo;
}

export const useInvoiceCardProps = ({
  tinyPayloads,
  freeTrialsInfo,
}: UseInvoiceCardProps) => {
  const currency = tinyPayloads?.currencyISOCode || ('' as Currency);
  const ratePlan = tinyPayloads?.ratePlan;
  const installment = useAtomValue(selectedInstallmentAtom);
  const isFirstPayment = !!(
    tinyPayloads?.invoiceSplitItems &&
    tinyPayloads?.invoiceSplitItems.length > 0
  );

  const total = useMemo(() => {
    const firstSplitItemAmount =
      (tinyPayloads?.invoiceSplitItems &&
        tinyPayloads?.invoiceSplitItems[0]?.amount) ||
      0;

    const totalAmount = isFirstPayment
      ? firstSplitItemAmount
      : tinyPayloads?.totalAmount || 0;
    if (installment?.financialCostAmount) {
      return totalAmount + installment?.financialCostAmount;
    }
    return totalAmount;
  }, [isFirstPayment, tinyPayloads, installment]);

  const installments = useMemo(() => {
    if (!installment || installment?.installments <= 1) {
      return;
    }
    return {
      numberOfInstallments: installment.installments,
      installmentAmount: installment.installmentAmount,
      financialCostAmount: installment.financialCostAmount,
    };
  }, [installment]);

  const invoiceCardProps: InvoiceCardProps = {
    id: ratePlan?.id || '',
    name: ratePlan?.name || '',
    quantity: ratePlan?.quantity || 0,
    price: tinyPayloads?.totalBasePrice || 0,
    discount: tinyPayloads?.totalDiscountAmount || 0,
    total,
    isFirstPayment,
    currency,
    installments,
    freeTrialsInfo,
  };

  return { invoiceCardProps };
};
