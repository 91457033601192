import { PPPageTemplate, InfoMessage } from '@openeducation/pp-rn-components';
import { useDefaultContactPhone } from '@openeducation/pp-rn-providers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { footerProps } from '../helpers';

const ErrorScreen = () => {
  const { t } = useTranslation();
  const { contactPhone } = useDefaultContactPhone();

  return (
    <PPPageTemplate headerProps={{ contactPhone }} footerProps={footerProps}>
      <InfoMessage iconName="times-circle">
        {t('polling.text.retry')}
      </InfoMessage>
    </PPPageTemplate>
  );
};

export default ErrorScreen;
