import type {
  Environment,
  TinyPayloads,
  Buyer,
  Company,
  Subscription,
  PaymentParams,
  PaymentResponse,
  PaymentStatus,
  PayPalTokenParams,
  PayPalToken,
  WebCheckoutFormData,
  BuyerParams,
  CardNumberParams,
  AccountStatusInfo,
  WebCheckoutFormDataParams,
  AccountInfo,
  BillingAgreementsParams,
  BillingAgreements,
  YunoPaymentLinkParams,
  YunoPaymentStatusParams,
  YunoPaymentLink,
  ExternalGatewayPaymentStatus,
  InvoiceInfo,
} from '@openeducation/pp-rn-shared-types';

import PPApiDao from '../dao/pp-api.dao';

export abstract class PPApiService {
  static async getTinyPayloads(
    env: Environment,
    sfdcToken: string,
    expired: boolean
  ): Promise<TinyPayloads> {
    const data = await PPApiDao.getTinyPayloads(env, sfdcToken, expired);
    return data;
  }

  static async getBuyer(env: Environment, params: BuyerParams): Promise<Buyer> {
    const data = await PPApiDao.getBuyer(env, params);
    return data;
  }
  static async getCardNumber(
    env: Environment,
    params: CardNumberParams
  ): Promise<string> {
    if (!params.creditCardToken) {
      return '';
    }
    const data = await PPApiDao.getCardNumber(env, params);
    return data;
  }

  static async getCompanyInfo(
    env: Environment,
    countryISOCode: string,
    sfdcToken: string
  ): Promise<Company> {
    const data = await PPApiDao.getCompanyInfo(env, countryISOCode, sfdcToken);
    return data;
  }

  static async getSubscription(
    env: Environment,
    subscriptionName: string,
    sfdcToken: string
  ): Promise<Subscription> {
    const data = await PPApiDao.getSubscription(
      env,
      subscriptionName,
      sfdcToken
    );
    return data;
  }

  static async submitFirstPayment(
    env: Environment,
    params: PaymentParams
  ): Promise<PaymentResponse> {
    const data = await PPApiDao.submitFirstPayment(env, params);
    return data;
  }

  static async getFirstPaymentStatus(
    env: Environment,
    paymentId: number
  ): Promise<PaymentStatus> {
    const data = await PPApiDao.getFirstPaymentStatus(env, paymentId);
    return data;
  }

  static async submitUpsellPayment(
    env: Environment,
    params: PaymentParams
  ): Promise<PaymentResponse> {
    const data = await PPApiDao.submitUpsellPayment(env, params);
    return data;
  }

  static async getUpsellPaymentStatus(
    env: Environment,
    paymentId: number
  ): Promise<PaymentStatus> {
    const data = await PPApiDao.getUpsellPaymentStatus(env, paymentId);
    return data;
  }

  static async createPayPalToken(
    env: Environment,
    params: PayPalTokenParams
  ): Promise<PayPalToken> {
    const data = await PPApiDao.createPayPalToken(env, params);
    return data;
  }

  static async getWebCheckoutFormData(
    env: Environment,
    params: WebCheckoutFormDataParams
  ): Promise<WebCheckoutFormData> {
    const data = await PPApiDao.getWebCheckoutFormData(env, params);
    return data;
  }

  static async getYunoPaymentLink(
    env: Environment,
    params: YunoPaymentLinkParams
  ): Promise<YunoPaymentLink> {
    const data = await PPApiDao.getYunoPaymentLink(env, params);
    return data;
  }

  static async getYunoPaymentStatus(
    env: Environment,
    params: YunoPaymentStatusParams
  ): Promise<ExternalGatewayPaymentStatus> {
    const data = await PPApiDao.getYunoPaymentStatus(env, params);
    return data;
  }

  static async getAccountStatus(
    env: Environment,
    accountId: string
  ): Promise<AccountStatusInfo> {
    const data = await PPApiDao.getAccountStatus(env, accountId);
    return data;
  }

  static async getAccountInfo(
    env: Environment,
    accountId: string
  ): Promise<AccountInfo> {
    const data = await PPApiDao.getAccountInfo(env, accountId);
    return data;
  }
  static async getNextInvoiceDate(
    env: Environment,
    subscriptionName: string
  ): Promise<string> {
    const data = await PPApiDao.getNextInvoiceDate(env, subscriptionName);
    return data;
  }

  static async getBillingAgreements(
    env: Environment,
    payPalToken: string,
    params: BillingAgreementsParams
  ): Promise<BillingAgreements> {
    const data = await PPApiDao.getBillingAgreements(env, payPalToken, params);
    return data;
  }

  static async updatePaymentMethod(
    env: Environment,
    accountId: string,
    params: PaymentParams
  ): Promise<number> {
    const data = await PPApiDao.updatePaymentMethod(env, accountId, params);
    return data;
  }
  static async getInvoiceData(
    env: Environment,
    accountId: string,
    invoiceId: string
  ): Promise<InvoiceInfo> {
    const data = await PPApiDao.getInvoiceData(env, accountId, invoiceId);
    return data;
  }
  static getInvoicePdfLink(
    env: Environment,
    accountId: string,
    invoiceId: string
  ): string {
    const data = PPApiDao.getInvoicePdfLink(env, accountId, invoiceId);
    return data;
  }

  static async submitInvoicePayment(
    env: Environment,
    accountId: string,
    invoiceId: string,
    params: PaymentParams
  ): Promise<PaymentResponse> {
    const data = await PPApiDao.submitInvoicePayment(
      env,
      accountId,
      invoiceId,
      params
    );
    return data;
  }
}
