import { PPProductCatalogService } from '@openeducation/pp-rn-services';
import type { ContractParams } from '@openeducation/pp-rn-shared-types';
import { useMutation } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { fixMd } from '../../pp-ui/helpers';

interface UseCreateContractInput {
  contract: ContractParams | null;
}

export const useCreateContract = ({ contract }: UseCreateContractInput) => {
  const { environment } = useEnvironmentContext();

  const { mutate, data, isError, isLoading } = useMutation(() => {
    if (!contract) {
      throw new Error('No contract params provided');
    }
    return PPProductCatalogService.createContract(environment, contract);
  });

  return {
    createContract: mutate,
    data: fixMd(data),
    isError,
    isLoading,
  };
};
