export enum Currency {
  ARS = 'ARS',
  BOB = 'BOB',
  BRL = 'BRL',
  CLP = 'CLP',
  COP = 'COP',
  CRC = 'CRC',
  DOP = 'DOP',
  EUR = 'EUR',
  GTQ = 'GTQ',
  HNL = 'HNL',
  MXN = 'MXN',
  NIO = 'NIO',
  PEN = 'PEN',
  PYG = 'PYG',
  TRY = 'TRY',
  USD = 'USD',
  UYU = 'UYU',
}
