export interface SpacingType {
  tiny: number;
  extraSmall: number;
  small: number;
  primarySmall: number;
  primary: number;
  medium: number;
  large: number;
  huge: number;
}

export const spacing: SpacingType = {
  tiny: 4,
  extraSmall: 6,
  small: 8,
  primarySmall: 12,
  primary: 16,
  medium: 24,
  large: 32,
  huge: 60,
};
