import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';

import { PPText } from '../../atoms';

interface InvoiceInfoTableCellProps {
  children: string;
  isHeading?: boolean;
}

const InvoiceInfoTableCell = ({
  children,
  isHeading,
}: InvoiceInfoTableCellProps) => {
  return (
    <Cell>
      <PPText fontType={isHeading ? 'semiBold' : 'regular'}>{children}</PPText>
    </Cell>
  );
};

const Cell = styled.View`
  flex: 1;
  padding: ${({ theme }) => theme.spacing.small}px;
`;

export default memo(InvoiceInfoTableCell);
