import { Loading } from '@openeducation/pp-rn-components';
import {
  EnvironmentProvider,
  LocalizationProvider,
  ReactQueryInitProvider,
  usePreferredLanguage,
} from '@openeducation/pp-rn-providers';
import type { Environment } from '@openeducation/pp-rn-shared-types';
import { defaultTheme, ThemeProvider } from '@openeducation/pp-rn-themes';
import React, { ReactNode } from 'react';
import { QueryClient } from 'react-query';

interface AppProvidersProps {
  env: Environment;
  children: ReactNode;
}

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

export const AppProviders = ({ children, env }: AppProvidersProps) => {
  const { preferredLanguage } = usePreferredLanguage();

  return (
    <EnvironmentProvider env={env}>
      <ReactQueryInitProvider queryClient={queryClient}>
        <LocalizationProvider
          environment={env}
          loadingComponent={<Loading isFullPage />}
          language={preferredLanguage}
        >
          <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
        </LocalizationProvider>
      </ReactQueryInitProvider>
    </EnvironmentProvider>
  );
};
