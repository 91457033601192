import { FontAwesome5 } from '@expo/vector-icons';
import { styled, useStyledTheme } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import { StyleProp, ViewStyle, Linking } from 'react-native';

import { PPText } from '../../atoms';

export interface PhoneButtonProps {
  contactPhone: string;
  color?: string;
  style?: StyleProp<ViewStyle>;
}

export const PhoneButton = ({
  contactPhone,
  color,
  style,
}: PhoneButtonProps) => {
  const { colors } = useStyledTheme();

  const contentColor = color || colors.text;

  return (
    <Container
      onPress={() => Linking.openURL(`tel:${contactPhone}`)}
      style={style}
    >
      <FontAwesome5 name="phone" size={16} color={contentColor} />
      <PhoneText color={contentColor} fontSize={21}>
        {contactPhone}
      </PhoneText>
    </Container>
  );
};

const Container = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`;

const PhoneText = styled(PPText)`
  margin-left: ${({ theme }) => theme.spacing.extraSmall}px;
`;

export default memo(PhoneButton);
