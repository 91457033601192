import { PaymentMethodOnFile } from '@openeducation/pp-rn-shared-types';
import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Radio } from '../../atoms';

interface PaymentMethodOnFileSelectorProps {
  value?: PaymentMethodOnFile | null;
  onChange: (value: PaymentMethodOnFile) => void;
}

const PaymentMethodOnFileSelector = ({
  value,
  onChange,
}: PaymentMethodOnFileSelectorProps) => {
  const { t } = useTranslation();

  const handleRadioPress = (name?: string) => {
    onChange(name as PaymentMethodOnFile);
  };

  return (
    <Container>
      <Radio
        isChecked={value === PaymentMethodOnFile.EXISTING}
        name={PaymentMethodOnFile.EXISTING}
        label={t('radio.payment_method.existing')}
        onPress={handleRadioPress}
      />
      <Radio
        isChecked={value === PaymentMethodOnFile.NEW}
        name={PaymentMethodOnFile.NEW}
        label={t('radio.payment_method.new')}
        onPress={handleRadioPress}
      />
    </Container>
  );
};

const Container = styled.View`
  margin-bottom: ${({ theme }) => theme.spacing.primary}px;
`;

export default memo(PaymentMethodOnFileSelector);
