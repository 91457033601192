import { pendingPaymentAtom } from '@openeducation/pp-rn-jotai-atoms';
import type {
  BillingAddress,
  VisibleModalAddressValidation,
  AddressValidationModalProps,
} from '@openeducation/pp-rn-shared-types';
import { useSetAtom } from 'jotai';
import { useState, useEffect } from 'react';

import { useIsAddressValid } from '../../../../hooks';
import { shouldValidateAddress } from '../../../helpers/should-validate-address';

interface UseHandleAddressValidationProps {
  billingAddress: BillingAddress;
}

export const useHandleAddressValidation = ({
  billingAddress,
}: UseHandleAddressValidationProps) => {
  const setPendingPayment = useSetAtom(pendingPaymentAtom);
  const { validateAddress, status, validatedAddress, isRefetching } =
    useIsAddressValid(billingAddress);
  const isAddressValidationEnabled = shouldValidateAddress(billingAddress);

  const [address, setAddress] = useState<BillingAddress>(billingAddress);
  const [isValidated, setIsValidated] = useState(false);
  const [modalVisible, setModalVisible] =
    useState<VisibleModalAddressValidation | null>(null);

  useEffect(() => {
    if (
      !address ||
      (address?.country === '' && billingAddress.country !== '')
    ) {
      setAddress(billingAddress);
    }
  }, [address, billingAddress]);

  useEffect(() => {
    if (!isRefetching) {
      if (status === 'OK') {
        setModalVisible(null);
        setIsValidated(true);
      } else {
        setModalVisible(status as VisibleModalAddressValidation);
      }
    }
  }, [status, isRefetching]);

  useEffect(() => {
    if (modalVisible) {
      setIsValidated(false);
    }
  }, [modalVisible]);

  const onClose = () => {
    setModalVisible(null);
  };
  const cancel = () => {
    setPendingPayment(null);
    setIsValidated(false);
    onClose();
  };

  const accept = () => {
    if (validatedAddress) {
      setAddress(validatedAddress);
    }
    setIsValidated(true);
    onClose();
  };
  const proceed = () => {
    setAddress(billingAddress);
    setIsValidated(true);
    onClose();
  };
  const handleValidation = () => {
    setAddress(billingAddress);
    if (isAddressValidationEnabled) {
      validateAddress();
    } else {
      setIsValidated(true);
    }
  };

  const addressValidationModalProps: AddressValidationModalProps = {
    isAddressValid: isValidated,
    validateAddress: handleValidation,
    address,
    validatedAddress,
    modalVisible,
    proceed,
    accept,
    cancel,
  };

  return { addressValidationModalProps };
};
