import {
  paymentMethodOnFileAtom,
  selectedPaymentMethodAtom,
} from '@openeducation/pp-rn-jotai-atoms';
import {
  PaymentInfoProps,
  PaymentMethodOnFile,
  PaymentInfoFormData,
  PaymentMethod,
  CreditCard,
  Subscription,
} from '@openeducation/pp-rn-shared-types';
import { useAtomValue, useAtom } from 'jotai';
import React, { useEffect, useCallback } from 'react';

interface UsePaymentMethodOnFileProps {
  isUpsell: boolean;
  subscription?: Subscription;
  setFormData: React.Dispatch<React.SetStateAction<PaymentInfoFormData>>;
}

export const usePaymentMethodOnFile = ({
  isUpsell,
  subscription,
  setFormData,
}: UsePaymentMethodOnFileProps) => {
  const [selectedPaymentMethodOnFile, setSelectedPaymentMethodOnFile] = useAtom(
    paymentMethodOnFileAtom
  );
  const selectedPaymentMethod = useAtomValue(selectedPaymentMethodAtom);
  const subscriptionPaymentMethod = subscription?.paymentMethod;
  const creditCard = subscription?.creditCard;

  const isEnabled =
    selectedPaymentMethod === subscriptionPaymentMethod &&
    isUpsell &&
    !!subscription;

  const setExistingPaymentMethodValues = useCallback(() => {
    setFormData((prev: PaymentInfoFormData) => ({
      ...prev,
      paymentMethod: (subscriptionPaymentMethod || '') as PaymentMethod,
      cardType: (creditCard?.type || '') as CreditCard,
      cardNumber: creditCard?.number || '',
      expirationMonth: '',
      expirationYear: '',
      holderName: creditCard?.holderName || '',
      cvv: '',
      installments: '',
    }));
  }, [setFormData, subscriptionPaymentMethod, creditCard]);

  const setNewPaymentMethodValues = useCallback(() => {
    setFormData((prev: PaymentInfoFormData) => ({
      ...prev,
      cardType: '' as CreditCard,
      cardNumber: '',
      expirationMonth: '',
      expirationYear: '',
      holderName: '',
      cvv: '',
      installments: '',
    }));
  }, [setFormData]);

  useEffect(() => {
    if (isEnabled) {
      setSelectedPaymentMethodOnFile(PaymentMethodOnFile.EXISTING);
    } else {
      setSelectedPaymentMethodOnFile(PaymentMethodOnFile.NEW);
      setNewPaymentMethodValues();
    }
  }, [isEnabled, setSelectedPaymentMethodOnFile, setNewPaymentMethodValues]);

  useEffect(() => {
    if (isEnabled) {
      if (selectedPaymentMethodOnFile === PaymentMethodOnFile.EXISTING) {
        setExistingPaymentMethodValues();
      }
      if (selectedPaymentMethodOnFile === PaymentMethodOnFile.NEW) {
        setNewPaymentMethodValues();
      }
    }
  }, [
    setExistingPaymentMethodValues,
    setNewPaymentMethodValues,
    isEnabled,
    selectedPaymentMethodOnFile,
  ]);

  const paymentMethodOnFile: PaymentInfoProps['paymentMethodOnFile'] = {
    isEnabled,
    value: selectedPaymentMethodOnFile,
    onChange: (value: PaymentMethodOnFile) =>
      setSelectedPaymentMethodOnFile(value),
  };

  return {
    paymentMethodOnFile,
    isExistingPaymentMethod:
      selectedPaymentMethodOnFile === PaymentMethodOnFile.EXISTING,
  };
};
