import { PPApiService } from '@openeducation/pp-rn-services';
import type {
  ExternalGatewayPaymentStatus,
  YunoPaymentStatusParams,
} from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

export const useGetYunoPaymentStatus = (
  { sfdcToken, accountId }: YunoPaymentStatusParams,
  enabled = true
) => {
  const { environment } = useEnvironmentContext();

  const { data, isError, isLoading, refetch } =
    useQuery<ExternalGatewayPaymentStatus>(
      [QueryKey.GetYunoPaymentStatus],
      () =>
        PPApiService.getYunoPaymentStatus(environment, {
          sfdcToken,
          accountId,
        }),
      { enabled: enabled && (!!sfdcToken || !!accountId) }
    );

  return {
    data,
    isError,
    isLoading,
    refetch,
  };
};
