import { atom } from 'jotai';

interface ErrorModalAtom {
  visible: boolean;
  errorKey?: string;
  retryEnabled?: boolean;
  onRetry?: () => void;
}

export const errorModalAtom = atom<ErrorModalAtom | null>(null);
