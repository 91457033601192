import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInput } from '../../../atoms';
import { InputRow } from '../../../molecules';

interface PaymentInfoEmailProps {
  value: string;
}

const PaymentInfoEmail = ({ value }: PaymentInfoEmailProps) => {
  const { t } = useTranslation();

  return (
    <InputRow label={t('input.email.label')}>
      <TextInput value={value} disabled />
    </InputRow>
  );
};

export default memo(PaymentInfoEmail);
