import { LocationService } from '@openeducation/pp-rn-services';
import type { CountryState } from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetCountryStatesInput {
  countryId?: number;
}

export const useGetCountryStates = (
  { countryId = 0 }: UseGetCountryStatesInput,
  enabled = true
) => {
  const { environment } = useEnvironmentContext();

  const {
    data = [],
    isError,
    isLoading,
  } = useQuery<CountryState[]>(
    [QueryKey.GetCountryStates, countryId],
    () => LocationService.getCountryStates(environment, countryId),
    { enabled: enabled && !!countryId }
  );

  return {
    data,
    isError,
    isLoading,
  };
};
