import React from 'react';

import { publicRoutes } from './public-routes';
import { Route, Routes } from './router';

const AppRoutes = () => {
  return (
    <Routes>
      {publicRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.component} />
      ))}
    </Routes>
  );
};

export default AppRoutes;
