import { PPApiService } from '@openeducation/pp-rn-services';
import type { TinyPayloads, ApiError } from '@openeducation/pp-rn-shared-types';
import type { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetTinyPayloadsInput {
  sfdcToken: string;
}

export const useGetTinyPayloads = ({ sfdcToken }: UseGetTinyPayloadsInput) => {
  const { environment } = useEnvironmentContext();
  const [expired, setExpired] = useState(false);

  const { data, isError, error, isLoading, refetch } = useQuery<
    TinyPayloads,
    AxiosError<ApiError>
  >(
    [QueryKey.GetTinyPayloads, sfdcToken],
    () => PPApiService.getTinyPayloads(environment, sfdcToken, expired),
    { enabled: !!sfdcToken, retry: false }
  );

  const apiError: ApiError | undefined = error?.response?.data;
  const expiredAccount = apiError?.code === 410;

  useEffect(() => {
    if (expiredAccount) {
      setExpired(true);
      refetch();
    }
  }, [setExpired, expiredAccount, refetch]);

  return {
    data,
    apiError,
    isError,
    isLoading,
  };
};
