import { useGetCountryByCode } from '../../hooks';

export const useDefaultContactPhone = () => {
  const { data: country } = useGetCountryByCode({
    countryISOCode: 'XX',
  });

  return {
    contactPhone: country?.phone?.oeContactPhone || '',
  };
};
