import type { AdditionalPaymentInfoProps } from '@openeducation/pp-rn-shared-types';

export interface ValidateAdditionalPaymentInfoParams {
  fields: AdditionalPaymentInfoProps['fields'];
  validation: AdditionalPaymentInfoProps['validation'];
  formData: AdditionalPaymentInfoProps['formData'];
  isCitySelectionEnabled: boolean;
}

export const MAX_FIELD_LENGTH = 200;

const checkAddressValue = (
  value: string,
  isEnabled: boolean,
  isAddressMandatory: boolean,
  maxLength: number = MAX_FIELD_LENGTH
) => {
  if (!isEnabled) {
    return true;
  }
  if (value.length > maxLength) {
    return false;
  }
  if (isAddressMandatory) {
    return !!value;
  }
  return true;
};

export const validateAdditionalPaymentInfo = ({
  fields,
  validation,
  formData,
  isCitySelectionEnabled,
}: ValidateAdditionalPaymentInfoParams) => {
  const {
    isDocumentTypeEnabled,
    isDocumentNumberEnabled,
    isAddressEnabled,
    isNumberEnabled,
    isComplementEnabled,
    isStateEnabled,
    isCityEnabled,
    isZipCodeEnabled,
  } = fields;
  const { documentType, address, number, complement, state, city, zipCode } =
    formData;

  const {
    isDocumentNumberValid,
    isDocumentNumberTooShort,
    isDocumentNumberTooLong,
    isAddressMandatory,
    isZipCodeTooShort,
    isZipCodeTooLong,
  } = validation;

  const isDocumentTypeOk = isDocumentTypeEnabled ? !!documentType : true;
  const isDocumentNumberOk = isDocumentNumberEnabled
    ? isDocumentNumberValid &&
      !isDocumentNumberTooShort &&
      !isDocumentNumberTooLong
    : true;
  const isAddressOk = checkAddressValue(
    address,
    isAddressEnabled,
    isAddressMandatory
  );
  const isNumberOk = checkAddressValue(
    number,
    isNumberEnabled,
    isAddressMandatory
  );
  const isComplementOk = isComplementEnabled
    ? complement.length < MAX_FIELD_LENGTH
    : true;
  const isStateOk = checkAddressValue(
    state,
    isStateEnabled,
    isAddressMandatory
  );
  const isCityOk = checkAddressValue(
    city,
    isCityEnabled,
    isAddressMandatory,
    isCitySelectionEnabled ? MAX_FIELD_LENGTH : 20
  );
  const checkIsZipCodeOk = () => {
    if (!isZipCodeEnabled) {
      return true;
    }
    if (isZipCodeTooShort || isZipCodeTooLong) {
      return false;
    }
    if (isAddressMandatory) {
      return !!zipCode;
    }
    return true;
  };

  return (
    isDocumentTypeOk &&
    isDocumentNumberOk &&
    isAddressOk &&
    isNumberOk &&
    isComplementOk &&
    isStateOk &&
    isCityOk &&
    checkIsZipCodeOk()
  );
};
