import { usePreferredLanguage } from '@openeducation/pp-rn-providers';
import {
  Language,
  PPPageTemplateProps,
  PP_UI_VALID_LANGUAGES,
} from '@openeducation/pp-rn-shared-types';
import {
  styled,
  useDimension,
  SizeBreakpoint,
} from '@openeducation/pp-rn-themes';
import React from 'react';

import Loading from '../../atoms/loading/loading';
import { SCREEN_FOOTER_HEIGHT, ScreenFooter } from '../../molecules';
import { SCREEN_HEADER_HEIGHT, ScreenHeader } from '../../organisms';

export const PPPageTemplate = ({
  style,
  children,
  isLoading,
  headerProps,
  footerProps,
}: PPPageTemplateProps) => {
  const { isMediumSize, isLargeScreen } = useDimension();
  const { preferredLanguage, setPreferredLanguage } = usePreferredLanguage();

  return (
    <PageWrapper>
      <ScreenHeader
        {...headerProps}
        languages={PP_UI_VALID_LANGUAGES}
        onLanguageSelect={(lang: Language) => setPreferredLanguage(lang)}
        selectedLanguage={preferredLanguage}
        isLoading={isLoading}
      />
      <Container isMediumSize={isMediumSize} style={style}>
        <ContentWrapper
          isLargeScreen={isLargeScreen}
          isMediumSize={isMediumSize}
        >
          <Content isLargeScreen={isLargeScreen}>
            {isLoading ? <Loading size="large" isFullPage /> : children}
          </Content>
        </ContentWrapper>
      </Container>
      <ScreenFooter {...footerProps} />
    </PageWrapper>
  );
};

const PageWrapper = styled.View`
  flex: 1;
  padding-top: ${SCREEN_HEADER_HEIGHT}px;
  padding-bottom: ${SCREEN_FOOTER_HEIGHT}px;
`;

type ContainerType = {
  isMediumSize: boolean;
};

const Container = styled.View<ContainerType>`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.light};
  padding-horizontal: ${({ isMediumSize, theme }) =>
    isMediumSize ? 0 : theme.spacing.primary}px;
  align-items: center;
`;

type ContentWrapperType = {
  isMediumSize: boolean;
  isLargeScreen: boolean;
};

const ContentWrapper = styled.View<ContentWrapperType>`
  width: 100%;
  flex-grow: 1;
  max-width: ${SizeBreakpoint.XXL}px;
  background-color: ${({ theme }) => theme.colors.dark0};
  padding-horizontal: ${({ theme, isMediumSize }) =>
    isMediumSize ? 0 : theme.spacing.huge}px;
  padding-top: ${({ isLargeScreen, theme }) =>
    isLargeScreen ? theme.spacing.large : theme.spacing.medium}px;
  padding-bottom: 100px;
`;

type ContentType = {
  isLargeScreen: boolean;
};

const Content = styled.View<ContentType>`
  width: ${({ isLargeScreen }) => (isLargeScreen ? 90 : 100)}%;
  height: 100%;
  padding-horizontal: ${({ isLargeScreen, theme }) =>
    isLargeScreen ? 0 : theme.spacing.primary}px;
  align-self: center;
`;

export default PPPageTemplate;
