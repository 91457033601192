import {
  pendingPaymentAtom,
  selectedPaymentMethodAtom,
  paymentMethodOnFileAtom,
  contractIdAtom,
} from '@openeducation/pp-rn-jotai-atoms';
import {
  PaymentInfoFormData,
  PaymentMethod,
  PaymentGateway,
  ContractParams,
  PaymentSubmitProps,
  PaymentMethodOnFile,
  ManualPaymentParams,
  SubmitPaymentReturn,
  BillingAddress,
  BillingAgreements,
  AlternativePaymentInfo,
} from '@openeducation/pp-rn-shared-types';
import { useAtomValue } from 'jotai';

import { useHandlePaymentErrors } from './use-handle-payment-errors';
import { useHandlePaymentSubmit } from './use-handle-payment-submit';
import { useAcceptContract } from '../../../../hooks';
import { getPendingPaymentMethod } from '../../../helpers';
import { useSubmitPayU, SubmitPayUParams } from '../../use-submit-payu';
import { useSubmitYuno, SubmitYunoParams } from '../../use-submit-yuno';
import { SubmitPayPalParams, useSubmitPayPal } from '../use-submit-pay-pal';

interface UseSubmitPayment {
  paymentInfo: PaymentInfoFormData;
  billingAddress: BillingAddress;
  sfdcToken?: string;
  isSubmitEnabled: boolean;
  documentNumber?: string;
  documentType?: string;
  isUpsell?: boolean;
  contractParams: ContractParams | null;
  submitPayPalParams?: SubmitPayPalParams;
  submitPayUParams?: SubmitPayUParams;
  submitYunoParams?: SubmitYunoParams;
  manualPaymentParams?: ManualPaymentParams;
  submitPaymentReturn: SubmitPaymentReturn;
  cpfNumber?: string;
  isScreenLoading?: boolean;
  billingAgreements?: BillingAgreements;
  validateAddress: () => void;
  isAddressValid: boolean;
  alternativePaymentInfo?: AlternativePaymentInfo;
}

export const usePaymentSubmitProps = ({
  alternativePaymentInfo,
  paymentInfo,
  documentNumber,
  documentType,
  isUpsell,
  contractParams,
  sfdcToken,
  isSubmitEnabled,
  submitPayPalParams = {} as SubmitPayPalParams,
  submitPayUParams = {} as SubmitPayUParams,
  submitYunoParams = {} as SubmitYunoParams,
  billingAddress,
  manualPaymentParams,
  submitPaymentReturn,
  cpfNumber,
  isScreenLoading,
  billingAgreements,
  validateAddress,
  isAddressValid,
}: UseSubmitPayment) => {
  const { paymentMethod } = paymentInfo;
  const pendingPayment = useAtomValue(pendingPaymentAtom);
  const selectedPaymentMethod = useAtomValue(selectedPaymentMethodAtom);
  const selectedPaymentMethodOnFile = useAtomValue(paymentMethodOnFileAtom);
  const atomContractId = useAtomValue(contractIdAtom) as string;

  const {
    data: paymentResponse,
    submitPayment,
    isError: isSubmitError,
    apiError: submitApiError,
    submitPaymentReset,
  } = submitPaymentReturn;

  const isPayPal = selectedPaymentMethod === PaymentMethod.PAY_PAL;
  const isPayPalRedirectionFlow =
    isPayPal && selectedPaymentMethodOnFile !== PaymentMethodOnFile.EXISTING;

  const alternativePaymentGateway =
    alternativePaymentInfo?.paymentGateway || PaymentGateway.PAYU;

  const isPayU =
    paymentMethod === PaymentMethod.ALTERNATIVE &&
    alternativePaymentGateway !== PaymentGateway.YUNO;

  const isYuno =
    paymentMethod === PaymentMethod.ALTERNATIVE &&
    alternativePaymentGateway === PaymentGateway.YUNO;

  const {
    data: newContractId = '',
    acceptContract,
    isLoading: isAcceptLoading,
    isError: isAcceptError,
    acceptContractReset,
  } = useAcceptContract({
    contract: contractParams,
  });
  const contractId = isAcceptLoading ? '' : atomContractId || newContractId;
  const contractAccepted = !isAcceptLoading && !isAcceptError && !!contractId;

  const { submitPayPal, payPalApiError, isPayPalError, payPalReset } =
    useSubmitPayPal(submitPayPalParams, newContractId);

  const { submitPayU } = useSubmitPayU(submitPayUParams);

  const { submitYuno, yunoApiError, isYunoError, yunoReset } =
    useSubmitYuno(submitYunoParams);

  useHandlePaymentErrors({
    submitPaymentReset,
    acceptContractReset,
    payPalReset,
    isAcceptError,
    isSubmitError,
    isPayPalError,
    submitApiError,
    payPalApiError,
    yunoApiError,
    isYunoError,
    yunoReset,
  });

  const pendingPaymentMethod = getPendingPaymentMethod(
    isPayPalRedirectionFlow,
    isPayU,
    isYuno
  );

  const { onSubmitButtonPress } = useHandlePaymentSubmit({
    alternativePaymentGateway,
    submitPaymentReset,
    submitPayU,
    acceptContract: contractParams ? acceptContract : undefined,
    submitPayPal,
    isYuno,
    submitYuno,
    submitPayment,
    paymentResponse,
    pendingPaymentMethod,
    paymentInfo,
    billingAddress,
    manualPaymentParams,
    isPayU,
    isSubmitError,
    isPayPalRedirectionFlow,
    isUpsell,
    contractAccepted,
    validateAddress,
    isAddressValid,
    sfdcToken,
    contractId,
    documentNumber,
    documentType,
    cpfNumber,
    isScreenLoading,
    billingAgreements,
  });

  const paymentSubmitProps: PaymentSubmitProps = {
    submitPayment: onSubmitButtonPress,
    isLoading: pendingPayment !== null,
    isSubmitEnabled,
    isPayPal,
    isPayU,
    isYuno,
  };

  return { paymentSubmitProps };
};
