import {
  PPPageTemplate,
  PaymentInfo,
  PersonalInfo,
  ManualPaymentInfo,
  PaymentSubmit,
  InvoiceTable,
  PaymentStatusModal,
  ErrorModal,
  AdditionalPaymentInfo,
  AddressValidationModal,
} from '@openeducation/pp-rn-components';
import { useInvoiceScreen } from '@openeducation/pp-rn-providers';
import { getBaseUrl } from '@openeducation/pp-rn-services';

import CreditCardPreview from '../components/credit-card-preview.web';
import { footerProps } from '../helpers';
import { useParams } from '../router';

const InvoiceScreen = () => {
  const params = useParams();
  const baseUrl = getBaseUrl();
  const { invoiceId = '', accountId = '' } = params;

  const {
    paymentSubmitProps,
    pageProps,
    personalInfoProps,
    invoiceTableProps,
    paymentInfoProps,
    manualPaymentInfoProps,
    paymentStatusModalProps,
    errorModalProps,
    additionalPaymentInfoProps,
    addressValidationModalProps,
  } = useInvoiceScreen({
    invoiceId,
    accountId,
    baseUrl,
  });

  return (
    <>
      <PPPageTemplate {...pageProps} footerProps={footerProps}>
        <InvoiceTable {...invoiceTableProps} />
        <PersonalInfo {...personalInfoProps} />
        <PaymentInfo
          {...paymentInfoProps}
          renderCardPreview={(
            number: string,
            expiry: string,
            name: string,
            cvv: string,
            focused?: string
          ) => (
            <CreditCardPreview
              cardType={paymentInfoProps.formData.cardType}
              number={number}
              expiry={expiry}
              name={name}
              cvc={cvv}
              focused={focused}
            />
          )}
        />
        <AdditionalPaymentInfo {...additionalPaymentInfoProps} />
        <ManualPaymentInfo {...manualPaymentInfoProps} />
        <PaymentSubmit {...paymentSubmitProps} />
      </PPPageTemplate>
      <PaymentStatusModal {...paymentStatusModalProps} />
      <ErrorModal {...errorModalProps} />
      <AddressValidationModal {...addressValidationModalProps} />
    </>
  );
};

export default InvoiceScreen;
