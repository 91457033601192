import type {
  Environment,
  TinyPayloads,
  Buyer,
  Company,
  Subscription,
  PaymentParams,
  PaymentResponse,
  PaymentStatus,
  PayPalTokenParams,
  PayPalToken,
  WebCheckoutFormData,
  BuyerParams,
  CardNumberParams,
  AccountStatusInfo,
  WebCheckoutFormDataParams,
  AccountInfo,
  BillingAgreementsParams,
  BillingAgreements,
  YunoPaymentLinkParams,
  YunoPaymentLink,
  YunoPaymentStatusParams,
  ExternalGatewayPaymentStatus,
  InvoiceInfo,
} from '@openeducation/pp-rn-shared-types';
import axios from 'axios';

import { hostsConfig } from '../utils';

export default abstract class PPApiDao {
  static async getTinyPayloads(
    env: Environment,
    sfdcToken: string,
    expired: boolean
  ) {
    const url = hostsConfig[env].ppApiGateway;
    const { data } = await axios.get<TinyPayloads>(
      `${url}/tiny-payloads/${sfdcToken}`,
      { params: { expired } }
    );
    return data;
  }

  static async getBuyer(env: Environment, params: BuyerParams) {
    const url = hostsConfig[env].ppApiGateway;
    const { data } = await axios.get<Buyer>(`${url}/buyers`, {
      params,
    });
    return data;
  }
  static async getCardNumber(env: Environment, params: CardNumberParams) {
    const url = hostsConfig[env].ppApiGateway;
    const { creditCardToken } = params;
    const { data } = await axios.get<string>(
      `${url}/drafts/cards/${creditCardToken}/number`
    );
    return data;
  }

  static async getCompanyInfo(
    env: Environment,
    countryIsoCode: string,
    sfdcToken: string
  ) {
    const url = hostsConfig[env].ppApiGateway;
    const { data } = await axios.get<Company>(`${url}/company`, {
      params: {
        countryIsoCode,
        token: sfdcToken,
      },
    });
    return data;
  }

  static async getSubscription(
    env: Environment,
    subscriptionName: string,
    sfdcToken: string
  ) {
    const url = hostsConfig[env].ppApiGateway;
    const { data } = await axios.get<Subscription>(`${url}/accounts`, {
      params: {
        subscriptionName,
        token: sfdcToken,
      },
    });
    return data;
  }

  static async submitFirstPayment(env: Environment, params: PaymentParams) {
    const url = hostsConfig[env].ppApiGateway;
    const { data } = await axios.post<PaymentResponse>(
      `${url}/payments/first-payments`,
      params
    );
    return data;
  }

  static async getFirstPaymentStatus(env: Environment, paymentId: number) {
    const url = hostsConfig[env].ppApiGateway;
    const { data } = await axios.get<PaymentStatus>(
      `${url}/payments/first-payments/${paymentId}`
    );
    return data;
  }

  static async submitUpsellPayment(env: Environment, params: PaymentParams) {
    const url = hostsConfig[env].ppApiGateway;
    const { data } = await axios.post<PaymentResponse>(
      `${url}/payments/upsells`,
      params
    );
    return data;
  }

  static async getUpsellPaymentStatus(env: Environment, paymentId: number) {
    const url = hostsConfig[env].ppApiGateway;
    const { data } = await axios.get<PaymentStatus>(
      `${url}/payments/upsells/${paymentId}`
    );
    return data;
  }

  static async createPayPalToken(env: Environment, params: PayPalTokenParams) {
    const url = hostsConfig[env].ppApiGateway;
    const { data } = await axios.post<PayPalToken>(
      `${url}/paypal/tokens`,
      params
    );
    return data;
  }

  static async getWebCheckoutFormData(
    env: Environment,
    params: WebCheckoutFormDataParams
  ) {
    const url = hostsConfig[env].ppApiGateway;
    const { data } = await axios.get<WebCheckoutFormData>(
      `${url}/payu/webcheckout-form-data`,
      {
        params,
      }
    );
    return data;
  }

  static async getYunoPaymentLink(
    env: Environment,
    params: YunoPaymentLinkParams
  ) {
    const url = hostsConfig[env].ppApiGateway;
    const { data } = await axios.post<YunoPaymentLink>(
      `${url}/yuno/payment-links`,
      params
    );
    return data;
  }

  static async getYunoPaymentStatus(
    env: Environment,
    params: YunoPaymentStatusParams
  ) {
    const url = hostsConfig[env].ppApiGateway;
    const { data } = await axios.get<ExternalGatewayPaymentStatus>(
      `${url}/yuno/payments`,
      {
        params,
      }
    );
    return data;
  }

  static async getAccountStatus(env: Environment, accountId: string) {
    const url = hostsConfig[env].ppApiGateway;
    const { data } = await axios.get<AccountStatusInfo>(
      `${url}/accounts/${accountId}/status`
    );
    return data;
  }

  static async getAccountInfo(env: Environment, accountId: string) {
    const url = hostsConfig[env].ppApiGateway;
    const { data } = await axios.get<AccountInfo>(
      `${url}/accounts/${accountId}`
    );
    return data;
  }
  static async getNextInvoiceDate(env: Environment, subscriptionName: string) {
    const url = hostsConfig[env].ppApiGateway;
    const { data } = await axios.get<string>(
      `${url}/subscriptions/${subscriptionName}/invoices/next-invoice-date`
    );
    return data;
  }

  static async getBillingAgreements(
    env: Environment,
    payPalToken: string,
    params: BillingAgreementsParams
  ) {
    const url = hostsConfig[env].ppApiGateway;
    const { data } = await axios.get<BillingAgreements>(
      `${url}/paypal/billing_agreements/${payPalToken}`,
      { params }
    );
    return data;
  }

  static async updatePaymentMethod(
    env: Environment,
    accountId: string,
    params: PaymentParams
  ) {
    const url = hostsConfig[env].ppApiGateway;
    const { data } = await axios.put<number>(
      `${url}/accounts/${accountId}/payment-method`,
      params
    );
    return data;
  }
  static async getInvoiceData(
    env: Environment,
    accountId: string,
    invoiceId: string
  ) {
    const url = hostsConfig[env].ppApiGateway;
    const { data } = await axios.get<InvoiceInfo>(
      `${url}/b2b/accounts/${accountId}/invoices/${invoiceId}`
    );
    return data;
  }
  static getInvoicePdfLink(
    env: Environment,
    accountId: string,
    invoiceId: string
  ) {
    const url = hostsConfig[env].ppApiGateway;
    return `${url}/b2b/accounts/${accountId}/invoices/${invoiceId}/pdf`;
  }

  static async submitInvoicePayment(
    env: Environment,
    accountId: string,
    invoiceId: string,
    params: PaymentParams
  ) {
    const url = hostsConfig[env].ppApiGateway;
    const { data } = await axios.post<PaymentResponse>(
      `${url}/b2b/accounts/${accountId}/invoices/${invoiceId}/payments`,
      params
    );
    return data;
  }
}
