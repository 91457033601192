import { FontAwesome5 } from '@expo/vector-icons';
import {
  subMonths,
  addMonths,
  subDays,
  formatTz,
} from '@openeducation/pp-js-utils';
import type { Language } from '@openeducation/pp-rn-shared-types';
import { styled, useStyledTheme } from '@openeducation/pp-rn-themes';
import { rgba } from 'polished';
import React, { memo, useState } from 'react';
import { View } from 'react-native';

import { PPText } from '../../atoms';
import Calendar from '../calendar/calendar';

export interface DateInputProps {
  onValueChange: (value: string, name?: string) => void;
  language: Language;
  today: Date;
  disabled?: boolean;
  name?: string;
}

export const DateInput = ({
  disabled,
  name,
  onValueChange,
  language,
  today,
}: DateInputProps) => {
  const theme = useStyledTheme();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [currentMonth, setCurrentMonth] = useState<Date>(today);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const textColor =
    disabled || !selectedDate ? theme.colors.dark3 : theme.colors.text;
  const displayValue = selectedDate
    ? formatTz(selectedDate, 'yyyy-MM-dd')
    : `${today.getFullYear()}-mm-dd`;

  const toggleCalendar = () => setIsCalendarVisible((prev) => !prev);
  const onDateSelect = (date: Date) => {
    setSelectedDate(date);
    toggleCalendar();
    onValueChange(formatTz(date, 'yyyy-MM-dd'), name);
  };

  return (
    <View>
      <Container disabled={disabled} onPress={toggleCalendar}>
        <InputText color={textColor} fontSize={13}>
          {displayValue}
        </InputText>
        <FontAwesome5 name="calendar" size={16} color={textColor} />
      </Container>
      {isCalendarVisible && (
        <StyledCalendar
          selectedDates={selectedDate ? [selectedDate] : []}
          currentMonth={currentMonth}
          language={language}
          today={today}
          minDate={subDays(today, 30)}
          onPrevMonth={() => setCurrentMonth((prev) => subMonths(prev, 1))}
          onNextMonth={() => setCurrentMonth((prev) => addMonths(prev, 1))}
          onDayPress={onDateSelect}
        />
      )}
    </View>
  );
};

type ContainerType = {
  disabled?: boolean;
};

const Container = styled.TouchableOpacity<ContainerType>`
  padding-horizontal: ${({ theme }) => theme.spacing.primarySmall}px;
  padding-vertical: ${({ theme }) => theme.spacing.small}px;
  border-radius: ${({ theme }) => theme.border.radius.primary}px;
  border-width: ${({ theme }) => theme.border.width.primary}px;
  border-color: ${({ theme }) => theme.colors.border};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.dark0 : theme.colors.light};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  flex-direction: row;
  align-items: center;
`;

const InputText = styled(PPText)`
  flex: 1;
`;

const StyledCalendar = styled(Calendar)`
  left: 0;
  border-width: ${({ theme }) => theme.border.width.primary}px;
  border-color: ${({ theme }) => theme.colors.border};
  z-index: 2;
  width: 290px;
  top: 40px;
  position: absolute;
  box-shadow: ${({ theme }) =>
    `0 ${theme.spacing.extraSmall}px ${theme.spacing.primarySmall}px ${rgba(
      theme.colors.black,
      0.05
    )}`};
`;

export default memo(DateInput);
