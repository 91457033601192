import {
  PPPageTemplate,
  InfoMessage,
  Loading,
} from '@openeducation/pp-rn-components';
import {
  useDefaultContactPhone,
  useGetYunoPaymentStatus,
} from '@openeducation/pp-rn-providers';
import { getUrlParams } from '@openeducation/pp-rn-services';
import { ExternalGatewayPaymentStatus } from '@openeducation/pp-rn-shared-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { footerProps } from '../helpers';
import { useCheckoutRedirection } from '../hooks';

const YunoCallbackScreen = () => {
  const { t } = useTranslation();
  const { contactPhone } = useDefaultContactPhone();
  const { sfdcToken, accountId, invoiceId } = getUrlParams();
  const [isProcessing, setIsProcessing] = useState(true);

  const { openCheckoutAfterRedirection } = useCheckoutRedirection({
    accountId,
    invoiceId,
    sfdcToken,
  });
  const { data, isLoading, isError } = useGetYunoPaymentStatus({
    sfdcToken,
    accountId,
  });

  useEffect(() => {
    if (data) {
      setIsProcessing(
        data === ExternalGatewayPaymentStatus.SUCCESS ||
          data === ExternalGatewayPaymentStatus.PENDING
      );
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (!isProcessing) {
      openCheckoutAfterRedirection();
    }
  }, [isProcessing, openCheckoutAfterRedirection]);

  const renderMessage = () => {
    if (isError) {
      return (
        <InfoMessage iconName="times-circle">{t('error.unknown')}</InfoMessage>
      );
    }
    if (isProcessing) {
      return (
        <InfoMessage iconName="clock">
          {t('polling.text.inprogress')}
        </InfoMessage>
      );
    }
  };
  return (
    <PPPageTemplate headerProps={{ contactPhone }} footerProps={footerProps}>
      {isLoading ? <Loading isFullPage size={50} /> : renderMessage()}
    </PPPageTemplate>
  );
};

export default YunoCallbackScreen;
