import { styled } from '@openeducation/pp-rn-themes';
import React, { ReactNode } from 'react';
import type { StyleProp, ViewStyle } from 'react-native';

import { PPText } from '../../atoms';

export interface FormSectionProps {
  children: ReactNode;
  title: string;
  style?: StyleProp<ViewStyle>;
}

export const FormSection = ({ style, title, children }: FormSectionProps) => {
  return (
    <Container style={style}>
      <Title>{title}</Title>
      {children}
    </Container>
  );
};

const Container = styled.View`
  margin-top: ${({ theme }) => theme.spacing.medium}px;
`;

export const Title = styled(PPText).attrs({
  fontSize: 24,
  fontType: 'medium',
})`
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing.primarySmall}px;
`;

export default FormSection;
