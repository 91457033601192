import { useMemo } from 'react';
import { useWindowDimensions } from 'react-native';

export enum SizeBreakpoint {
  XS = 0,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
  XXL = 1400,
}

export function useDimension() {
  const { width } = useWindowDimensions();

  const isMobile = useMemo(() => width <= SizeBreakpoint.SM, [width]);

  const isLargeMobile = useMemo(
    () => width > SizeBreakpoint.SM && width < SizeBreakpoint.MD,
    [width]
  );

  const isTablet = useMemo(
    () => width > SizeBreakpoint.SM && width < SizeBreakpoint.LG,
    [width]
  );

  const isDesktop = useMemo(() => width >= SizeBreakpoint.LG, [width]);

  return {
    isLargeMobile,
    isMobile,
    isTablet,
    isDesktop,
    isMediumSize: isMobile || isLargeMobile,
    isLargeScreen: isDesktop || isTablet,
  };
}
