import type {
  Environment,
  PersonalPaymentDetails,
  CommonPaymentInfo,
  CreditCardPaymentInfo,
  FreeTrialsPaymentInfo,
  AdditionalPaymentInfo,
  Installment,
  BillingAddress,
  AddressValidation,
  ManualPaymentInfo,
  CommonPaymentInfoParams,
  AlternativePaymentInfo,
  ManualPaymentInfoParams,
} from '@openeducation/pp-rn-shared-types';

import PPPaymentInfoDao from '../dao/pp-payment-info.dao';
import type {
  GetAdditionalPaymentInfoParams,
  GetAlternativePaymentInfoParams,
  GetCreditCardPaymentInfoParams,
  GetInstallmentsParams,
  GetManualPaymentBanksParams,
} from '../types';

export abstract class PPPaymentInfoService {
  static async getPersonalDetails(
    env: Environment,
    countryISOCode: string
  ): Promise<PersonalPaymentDetails> {
    const data = await PPPaymentInfoDao.getPersonalDetails(env, countryISOCode);
    return data;
  }

  static async getCommonPaymentInfo(
    env: Environment,
    params: CommonPaymentInfoParams,
    isB2b: boolean = false
  ): Promise<CommonPaymentInfo> {
    const data = await PPPaymentInfoDao.getCommonPaymentInfo(
      env,
      params,
      isB2b
    );
    return data;
  }

  static async getCreditCardPaymentInfo(
    params: GetCreditCardPaymentInfoParams
  ): Promise<CreditCardPaymentInfo> {
    const data = await PPPaymentInfoDao.getCreditCardPaymentInfo(params);
    return data;
  }

  static async getAlternativePaymentInfo(
    params: GetAlternativePaymentInfoParams
  ): Promise<AlternativePaymentInfo> {
    const data = await PPPaymentInfoDao.getAlternativePaymentInfo(params);
    return data;
  }

  static async getFreeTrialsInfo(
    env: Environment,
    sfdcToken: string
  ): Promise<FreeTrialsPaymentInfo> {
    const data = await PPPaymentInfoDao.getFreeTrialsInfo(env, sfdcToken);
    return data;
  }

  static async getAdditionalPaymentInfo(
    params: GetAdditionalPaymentInfoParams
  ): Promise<AdditionalPaymentInfo> {
    const data = await PPPaymentInfoDao.getAdditionalPaymentInfo(params);
    return data;
  }
  static async getManualPaymentBanks(
    params: GetManualPaymentBanksParams
  ): Promise<string[]> {
    const data = await PPPaymentInfoDao.getManualPaymentBanks(params);
    return data;
  }

  static async getInstallments(
    params: GetInstallmentsParams
  ): Promise<Installment[]> {
    const data = await PPPaymentInfoDao.getInstallments(params);
    return data;
  }

  static async getManualPaymentInfo(
    env: Environment,
    paymentInfoToken: string,
    country: string
  ): Promise<ManualPaymentInfo> {
    const data = await PPPaymentInfoDao.getManualPaymentInfo(
      env,
      paymentInfoToken,
      country
    );
    return data;
  }
  static async getManualPaymentInfoB2B(
    env: Environment,
    params: ManualPaymentInfoParams
  ): Promise<ManualPaymentInfo> {
    const data = await PPPaymentInfoDao.getManualPaymentInfoB2B(env, params);
    return data;
  }

  static async get(
    env: Environment,
    paymentInfoToken: string,
    country: string
  ): Promise<ManualPaymentInfo> {
    const data = await PPPaymentInfoDao.getManualPaymentInfo(
      env,
      paymentInfoToken,
      country
    );
    return data;
  }

  static async getAddressValidation(
    env: Environment,
    params: BillingAddress
  ): Promise<AddressValidation> {
    const data = await PPPaymentInfoDao.getAddressValidation(env, params);
    return data;
  }
}
