import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectInputProps, SelectInput } from '../../../atoms';
import { InputRow } from '../../../molecules';

const PaymentInfoCountry = ({
  onFocus,
  onBlur,
  selectedValue,
  data,
  disabled,
  onValueChange,
}: SelectInputProps) => {
  const { t } = useTranslation();

  const translatedData = useMemo(
    () =>
      data.map(({ label, value }) => ({
        label: t(`country.${label}`),
        value,
      })),
    [data, t]
  );

  return (
    <InputRow label={t('input.country.label')}>
      <SelectInput
        name="country"
        selectedValue={selectedValue}
        data={translatedData}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        onValueChange={onValueChange}
      />
    </InputRow>
  );
};

export default memo(PaymentInfoCountry);
