export const capitalizeFirstLetter = ([first, ...rest]: string) =>
  [first.toUpperCase(), ...rest].join('');

export const stringToBoolean = (str: string) => {
  if (str === 'true') {
    return true;
  } else if (str === 'false') {
    return false;
  }

  return str;
};

export const stringToNumber = (str: string) => {
  if (isNaN(+str)) {
    return str;
  }
  return +str;
};
