export enum QueryKey {
  SupportedLanguageCodes = 'supportedLanguageCodes',
  GetCountryByCode = 'getCountryByCode',
  GetCountryStates = 'getCountryStates',
  GetCountries = 'getCountries',
  GetTinyPayloads = 'getTinyPayloads',
  GetBuyer = 'getBuyer',
  GetCompanyInfo = 'getCompanyInfo',
  GetPersonalDetails = 'getPersonalDetails',
  GetCommonPaymentInfo = 'getCommonPaymentInfo',
  GetCreditCardPaymentInfo = 'getCreditCardPaymentInfo',
  GetAlternativePaymentInfo = 'getAlternativePaymentInfo',
  GetFreeTrialsInfo = 'getFreeTrialsInfo',
  GetAdditionalPaymentInfo = 'getAdditionalPaymentInfo',
  GetInstallments = 'getInstallments',
  GetTermsOfPurchase = 'getTermsOfPurchase',
  GetSubscription = 'getSubscription',
  GetPaymentStatus = 'getPaymentStatus',
  GetWebCheckoutFormData = 'getWebCheckoutFormData',
  GetYunoPaymentStatus = 'getYunoPaymentStatus',
  GetAccountStatus = 'getAccountStatus',
  GetAccountInfo = 'getAccountInfo',
  GetCities = 'getCities',
  GetBillingAgreements = 'getBillingAgreements',
  GetCardNumber = 'getCardNumber',
  GetBanks = 'getBanks',
  GetNextInvoiceDate = 'getNextInvoiceDate',
}
