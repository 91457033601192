import { getDisplayPrice } from '@openeducation/pp-js-utils';
import type { Currency } from '@openeducation/pp-rn-shared-types';
import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { PPText } from '../../atoms';
interface InvoiceCardDiscountProps {
  discount: number;
  currency: Currency;
}

export const InvoiceCardDiscount = ({
  discount,
  currency,
}: InvoiceCardDiscountProps) => {
  const { t } = useTranslation();
  return (
    <DiscountRow>
      <DiscountLabel>{t('invoice.discount')}</DiscountLabel>
      <DiscountValue>- {getDisplayPrice(discount, currency)}</DiscountValue>
    </DiscountRow>
  );
};

export const DiscountRow = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const DiscountLabel = styled(PPText).attrs(({ theme }) => ({
  color: theme.colors.accent,
  fontSize: 12,
}))`
  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.colors.accent};
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.accent};
  margin-right: ${({ theme }) => theme.spacing.tiny}px;
  text-transform: uppercase;
`;

export const DiscountValue = styled(PPText).attrs(({ theme }) => ({
  color: theme.colors.accent,
  fontSize: 18,
  fontType: 'semiBold',
}))``;

export default memo(InvoiceCardDiscount);
