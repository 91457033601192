import { removeEmptyKeys } from '@openeducation/pp-js-utils';
import { PPPaymentInfoService } from '@openeducation/pp-rn-services';
import type {
  CommonPaymentInfo,
  CommonPaymentInfoParams,
} from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

export const useGetCommonPaymentInfo = (
  params: CommonPaymentInfoParams,
  enabled = true,
  b2b = false
) => {
  const { environment } = useEnvironmentContext();
  const { paymentInfoToken, country, accountId, currency, organization } =
    params;
  const hasCheckoutParams = !!paymentInfoToken && !!country;
  const hasPaymentInfoParams = !!accountId && !!currency && !!organization;

  const { data, isError, isLoading } = useQuery<CommonPaymentInfo>(
    [QueryKey.GetCommonPaymentInfo, ...Object.entries(params)],
    () =>
      PPPaymentInfoService.getCommonPaymentInfo(
        environment,
        removeEmptyKeys(params),
        b2b
      ),
    { enabled: enabled && (b2b || hasCheckoutParams || hasPaymentInfoParams) }
  );

  return {
    data,
    isError,
    isLoading,
  };
};
