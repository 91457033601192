import {
  pmCountryAtom,
  selectedPaymentMethodAtom,
} from '@openeducation/pp-rn-jotai-atoms';
import { PaymentMethod } from '@openeducation/pp-rn-shared-types';
import { useAtomValue } from 'jotai';

import { useInvoiceTableProps } from './invoice/use-invoice-table-props';
import {
  useGetAdditionalPaymentInfo,
  useGetAlternativePaymentInfo,
  useGetCommonPaymentInfo,
  useGetCountries,
  useGetCountryByCode,
  useGetCountryStates,
  useGetCreditCardPaymentInfo,
} from '../../hooks';
import { useGetInvoiceData } from '../../hooks/pp-api/use-get-invoice-info';
import { useGetManualPaymentInfoB2B } from '../../hooks/pp-payment-info/use-get-manual-payment-info-b2b';

interface useInvoiceDataParams {
  accountId: string;
  invoiceId: string;
}

export const useInvoiceData = ({
  accountId,
  invoiceId,
}: useInvoiceDataParams) => {
  const pmCountry = useAtomValue(pmCountryAtom);
  const selectedPaymentMethod = useAtomValue(selectedPaymentMethodAtom);

  const {
    data: invoiceInfo,
    isLoading: isInvoiceInfoLoading,
    pdfLink,
  } = useGetInvoiceData({
    invoiceId,
    accountId,
  });

  const { invoiceTableProps } = useInvoiceTableProps({ invoiceInfo, pdfLink });

  const countryIso = pmCountry || invoiceInfo?.personalInfo?.countryIso;
  const organization = invoiceInfo?.organization;
  const currencyIso = invoiceInfo?.invoice?.currencyIso;

  const {
    data: creditCardPaymentInfo,
    isLoading: isCreditCardPaymentInfoLoading,
  } = useGetCreditCardPaymentInfo(
    {
      countryISOCode: countryIso,
      currency: currencyIso,
      pmCountry: countryIso,
      isB2B: true,
    },
    selectedPaymentMethod === PaymentMethod.CREDIT_CARD
  );

  const {
    data: additionalPaymentInfo,
    isLoading: isAdditionalPaymentInfoLoading,
  } = useGetAdditionalPaymentInfo({
    countryISOCode: countryIso,
    currency: currencyIso,
    organization,
    paymentMethod: selectedPaymentMethod as PaymentMethod,
    pmCountry: countryIso,
    isB2B: true,
  });

  const { data: country, isLoading: isCountryLoading } = useGetCountryByCode({
    countryISOCode: countryIso || 'xx',
  });
  const { data: countryStates, isLoading: isCountryStatesLoading } =
    useGetCountryStates(
      { countryId: country?.countryId },
      country?.isoCode !== 'xx'
    );

  const { data: manualPaymentInfo, isLoading: isManualPaymentInfoLoading } =
    useGetManualPaymentInfoB2B(
      {
        country: countryIso,
        currency: currencyIso,
        organization,
      },
      selectedPaymentMethod === PaymentMethod.MANUAL
    );

  const { data: allCountries = [], isLoading: isAllCountriesLoading } =
    useGetCountries();

  const { data: commonPaymentInfo, isLoading: isCommonPaymentInfoLoading } =
    useGetCommonPaymentInfo(
      {
        country: countryIso,
        currency: currencyIso,
        organization,
      },
      !!countryIso && !!currencyIso && !!organization,
      true
    );

  const { data: alternativePaymentInfo } = useGetAlternativePaymentInfo(
    {
      currency: currencyIso,
      organization,
      accountId,
    },
    commonPaymentInfo?.paymentMethods.includes(PaymentMethod.ALTERNATIVE)
  );

  const isLoadingArray = [
    isAllCountriesLoading,
    isCountryLoading,
    isCreditCardPaymentInfoLoading,
    isAdditionalPaymentInfoLoading,
    isCountryStatesLoading,
    isManualPaymentInfoLoading,
    isCommonPaymentInfoLoading,
  ];

  return {
    alternativePaymentInfo,
    invoiceInfo: {
      ...invoiceInfo,
      pdfLink,
    },
    isInvoiceInfoLoading,
    invoiceTableProps,
    countryIso,
    organization,
    currencyIso,
    creditCardPaymentInfo,
    additionalPaymentInfo,
    countryStates,
    allCountries,
    manualPaymentInfo,
    commonPaymentInfo,
    isScreenLoading: isLoadingArray.includes(true),
  };
};
