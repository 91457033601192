import type {
  PaymentInfoProps,
  PaymentInfoFormData,
} from '@openeducation/pp-rn-shared-types';

export interface ValidatePaymentInfoParams {
  fields: PaymentInfoProps['fields'];
  validation: PaymentInfoProps['validation'];
  formData: PaymentInfoFormData;
}

export const validatePaymentInfo = ({
  fields,
  validation,
  formData,
}: ValidatePaymentInfoParams) => {
  const isCountryOk =
    fields.isCountryEnabled && !fields.isExistingPaymentMethod
      ? !!formData.country
      : true;
  const isPaymentMethodOk = !!formData.paymentMethod;
  const isCardTypeOk = fields.isCardTypeEnabled
    ? validation.isCardTypeValid
    : true;
  const isCardNumberOk = fields.isCardNumberEnabled
    ? validation.isCardNumberValid
    : true;
  const isCardExpirationOk = fields.isExpirationDateEnabled
    ? validation.isCardExpirationValid
    : true;
  const isCvvOk =
    fields.isCvvEnabled && !fields.isExistingPaymentMethod
      ? !!formData.cvv
      : true;
  const isInstallmentsOk = fields.isInstallmentsEnabled
    ? !!formData.installments
    : true;

  return (
    isCountryOk &&
    isPaymentMethodOk &&
    isCardTypeOk &&
    isCardNumberOk &&
    isCardExpirationOk &&
    isCvvOk &&
    isInstallmentsOk
  );
};
