import {
  mapSelectInputData,
  validateDocumentNumber,
} from '@openeducation/pp-js-utils';
import { paymentInfoAtom } from '@openeducation/pp-rn-jotai-atoms';
import type {
  AdditionalPaymentInfo,
  AdditionalPaymentInfoProps,
  CountryState,
  City,
  DocumentNumberType,
} from '@openeducation/pp-rn-shared-types';
import { useAtomValue } from 'jotai';
import { useMemo, useState, useEffect, useCallback } from 'react';

import { useGetCities } from '../../hooks';
import { getAdditionalPaymentInfo } from '../helpers/get-additional-payment-info/get-additional-payment-info';

interface UseAdditionalPaymentInfoProps {
  additionalPaymentInfo?: AdditionalPaymentInfo;
  isExistingPaymentMethod: boolean;
  countryStates?: CountryState[];
}

export const useAdditionalPaymentInfoProps = ({
  additionalPaymentInfo = {} as AdditionalPaymentInfo,
  isExistingPaymentMethod,
  countryStates = [],
}: UseAdditionalPaymentInfoProps) => {
  const {
    address,
    documentNumber,
    addressFieldsMandatory,
    city,
    number,
    cityDefaultValue,
    complement,
    state,
    zipCode,
    zipCodeMaxLength,
    zipCodeMinLength,
  } = additionalPaymentInfo;

  const [formData, setFormData] = useState({
    documentType: '',
    documentNumber: '',
    address: '',
    number: '',
    complement: '',
    state: '',
    city: '',
    zipCode: '',
  });

  const paymentInfo = useAtomValue(paymentInfoAtom);
  const documentNumberType = useMemo(
    () => additionalPaymentInfo?.documentNumberTypes || [],
    [additionalPaymentInfo]
  );
  const currentDocumentType = documentNumberType.find(
    (item: DocumentNumberType) =>
      item.documentNumberType === formData.documentType
  );
  const minDocumentNumberLength = currentDocumentType?.minLength || 0;
  const maxDocumentNumberLength = currentDocumentType?.maxLength || 100;
  const zipCodeLength = formData.zipCode.length;
  const minZipCodeLength = zipCodeMinLength || 0;
  const maxZipCodeLength = zipCodeMaxLength || 200;
  const docNumberLength = formData.documentNumber.length;
  const selectedStateId = countryStates.find(
    ({ stateName }) => formData.state === stateName
  )?.stateId;

  const handleFieldChange = useCallback((value: string, name?: string) => {
    if (name) {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  }, []);

  useEffect(() => {
    if (cityDefaultValue) {
      handleFieldChange(cityDefaultValue, 'city');
    }
  }, [cityDefaultValue, handleFieldChange]);

  useEffect(() => {
    if (paymentInfo?.billingAddress) {
      const billingAddress = getAdditionalPaymentInfo(
        paymentInfo?.billingAddress
      );
      Object.entries(billingAddress).map(([key, value]) => {
        handleFieldChange(String(value), key);
      });
    }
  }, [handleFieldChange, paymentInfo?.billingAddress]);

  const { data: cities } = useGetCities({ stateId: selectedStateId });

  const fields = {
    isDocumentTypeEnabled:
      !isExistingPaymentMethod &&
      !!documentNumber &&
      documentNumberType.length > 0,
    isDocumentNumberEnabled: !isExistingPaymentMethod && !!documentNumber,
    isAddressEnabled: !isExistingPaymentMethod && !!address,
    isNumberEnabled: !isExistingPaymentMethod && !!number,
    isComplementEnabled: !isExistingPaymentMethod && !!complement,
    isStateEnabled: !isExistingPaymentMethod && !!state,
    isCityEnabled: !isExistingPaymentMethod && !!city,
    isZipCodeEnabled: !isExistingPaymentMethod && !!zipCode,
  };

  const validation = {
    isDocumentNumberTooShort: docNumberLength < minDocumentNumberLength,
    isDocumentNumberTooLong: docNumberLength > maxDocumentNumberLength,
    isDocumentNumberValid: validateDocumentNumber(
      formData.documentNumber,
      currentDocumentType?.supportedCharacters
    ),
    minDocumentNumberLength,
    maxDocumentNumberLength,
    isAddressMandatory: !!addressFieldsMandatory,
    isZipCodeTooShort: zipCodeLength < minZipCodeLength,
    isZipCodeTooLong: zipCodeLength > maxZipCodeLength,
    minZipCodeLength,
    maxZipCodeLength,
  };

  const selectData = {
    documentType: mapSelectInputData<DocumentNumberType>(
      documentNumberType,
      'description',
      'documentNumberType'
    ),
    state: mapSelectInputData<CountryState>(
      countryStates,
      'stateName',
      'stateName'
    ),
    city: mapSelectInputData<City>(cities, 'cityName', 'cityName'),
  };

  const additionalPaymentInfoProps: AdditionalPaymentInfoProps = {
    formData,
    selectData,
    onFieldChange: handleFieldChange,
    fields,
    validation,
  };

  return { additionalPaymentInfoProps };
};
