import { getDisplayPrice } from '@openeducation/pp-js-utils';
import type { InvoiceCardInstallmentsProps } from '@openeducation/pp-rn-shared-types';
import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { PPText } from '../../atoms';

const InvoiceCardInstallments = ({
  currency,
  numberOfInstallments,
  installmentAmount,
  financialCostAmount,
}: InvoiceCardInstallmentsProps) => {
  const { t } = useTranslation();
  const hasInstallmentsAmount = installmentAmount && installmentAmount > 0;

  return (
    <>
      <InstallmentsText>
        {t('invoice.installments_number_message', { numberOfInstallments })}{' '}
        {hasInstallmentsAmount && t('invoice.installments_amount_message')}{' '}
        {hasInstallmentsAmount && getDisplayPrice(installmentAmount, currency)}
      </InstallmentsText>
      {financialCostAmount > 0 && (
        <FinancialCost>
          <FinancialCostLabel>{t('invoice.financial_cost')}</FinancialCostLabel>
          <FinancialCostValue>
            {getDisplayPrice(financialCostAmount, currency)}
          </FinancialCostValue>
        </FinancialCost>
      )}
    </>
  );
};

const InstallmentsText = styled(PPText).attrs(({ theme }) => ({
  color: theme.colors.text,
  fontSize: 14,
}))`
  margin-top: ${({ theme }) => theme.spacing.tiny}px;
  margin-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const FinancialCost = styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: ${({ theme }) => theme.spacing.small}px;
  padding-vertical: ${({ theme }) => theme.spacing.tiny}px;
  border-radius: ${({ theme }) => theme.border.radius.primary}px;
  background-color: ${({ theme }) => theme.colors.dark0};
`;

const FinancialCostLabel = styled(PPText).attrs(({ theme }) => ({
  color: theme.colors.text,
  fontSize: 14,
}))`
  margin-right: ${({ theme }) => theme.spacing.tiny}px;
`;

const FinancialCostValue = styled(PPText).attrs(({ theme }) => ({
  color: theme.colors.text,
  fontSize: 14,
  fontType: 'bold',
}))``;

export default memo(InvoiceCardInstallments);
