import { validateHolderName } from '@openeducation/pp-js-utils';
import {
  selectedPaymentMethodAtom,
  pmCountryAtom,
} from '@openeducation/pp-rn-jotai-atoms';
import type {
  ContractInfoProps,
  TinyPayloads,
  Company,
  CreditCard,
  Country,
  ContractType,
  BillingPeriod,
} from '@openeducation/pp-rn-shared-types';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';

import { useCreateContract, useGetTermsOfPurchase } from '../../../hooks';
import { getAdditionalTermsArray, getContractParams } from '../../helpers';
import { usePreferredLanguage } from '../use-preferred-language';

interface UseContractInfoProps {
  customerEmailAddress: string;
  creditCardNumber: string;
  creditCardType: CreditCard;
  tinyPayloads?: TinyPayloads;
  company?: Company;
  freeTrial: ContractInfoProps['freeTrial'];
  nextInvoice?: string;
}

export const useContractInfoProps = ({
  tinyPayloads,
  company,
  customerEmailAddress,
  creditCardType,
  creditCardNumber,
  freeTrial,
  nextInvoice,
}: UseContractInfoProps) => {
  const [isContractHolderFocused, setIsContractHolderFocused] = useState(false);
  const pmCountry = useAtomValue(pmCountryAtom);
  const [formData, setFormData] = useState({
    contractHolderName: '',
  });
  const paymentMethod = useAtomValue(selectedPaymentMethodAtom);
  const isContractHolderNameValid = validateHolderName(
    formData.contractHolderName
  );
  const { preferredLanguage } = usePreferredLanguage();
  const [checkedTerms, setCheckedTerms] = useState<string[]>([]);

  const { contractParams } = getContractParams({
    tinyPayloads,
    company,
    contractHolderName: isContractHolderNameValid
      ? formData.contractHolderName
      : '',
    customerEmailAddress,
    pmCountry,
    creditCardType,
    creditCardNumber,
    paymentMethod,
    preferredLanguage,
    nextInvoice,
  });

  const contractEnabled = !isContractHolderFocused && !!contractParams;

  const {
    createContract,
    isLoading: isContractLoading,
    data: contractData,
  } = useCreateContract({ contract: contractParams });

  const { data: termsData } = useGetTermsOfPurchase(
    {
      autoRenew: tinyPayloads?.autoRenew,
      initialTerm: tinyPayloads?.initialTerm as number,
      billingPeriod: tinyPayloads?.ratePlan?.billingPeriod as BillingPeriod,
      contractType: tinyPayloads?.contractType as ContractType,
      country: pmCountry as Country['isoCode'],
      language: preferredLanguage,
      paymentMethod,
      splitInvoiceCount: contractParams?.invoiceSplitItems?.length,
    },
    !!contractParams
  );

  useEffect(() => {
    if (contractEnabled) {
      createContract();
    }
    setCheckedTerms([]);
  }, [preferredLanguage, createContract, contractEnabled]);

  const toggleTerm = (termName?: string) => {
    if (!termName) return;

    setCheckedTerms((prev) =>
      prev.includes(termName)
        ? prev.filter((item) => item !== termName)
        : [...prev, termName]
    );
  };

  const additionalTerms = useMemo(
    () => getAdditionalTermsArray(termsData),
    [termsData]
  );

  const handleFieldChange = (value: string, name?: string) => {
    if (name) {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const validation = {
    isContractHolderNameValid,
  };

  const terms = {
    toggleTerm,
    checkedTerms,
    additionalTerms,
  };

  const contractInfoProps: ContractInfoProps = {
    onFieldChange: handleFieldChange,
    formData,
    contract: {
      isLoading: isContractLoading,
      data: contractData,
    },
    validation,
    setIsContractHolderFocused,
    terms,
    freeTrial,
  };

  return { contractInfoProps, contractParams };
};
