import type { AdditionalPaymentInfoProps } from '@openeducation/pp-rn-shared-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DocumentNumberInput from './document-number-input';
import { useAdditionalPaymentInfoMessages } from './use-additional-payment-info-error-messages';
import { SelectInput, TextInput } from '../../atoms';
import { InputRow } from '../../molecules';

export const AdditionalPaymentInfo = ({
  formData,
  onFieldChange,
  selectData,
  fields,
  validation,
}: AdditionalPaymentInfoProps) => {
  const { t } = useTranslation();
  const {
    isDocumentTypeEnabled,
    isDocumentNumberEnabled,
    isAddressEnabled,
    isNumberEnabled,
    isComplementEnabled,
    isStateEnabled,
    isCityEnabled,
    isZipCodeEnabled,
  } = fields;
  const {
    documentType,
    documentNumber,
    address,
    number,
    complement,
    state,
    city,
    zipCode,
  } = formData;
  const isCitySelectionEnabled = selectData.city.length > 0;
  const {
    documentTypeError,
    addressError,
    numberError,
    complementError,
    stateError,
    cityError,
    zipCodeError,
  } = useAdditionalPaymentInfoMessages({
    validation,
    formData,
    isCitySelectionEnabled,
  });

  const commonInputProps = {
    onValueChange: onFieldChange,
  };

  return (
    <>
      {isDocumentTypeEnabled && (
        <InputRow
          label={t('input.document_number_type.label')}
          error={documentTypeError}
        >
          <SelectInput
            name="documentType"
            selectedValue={documentType}
            data={selectData.documentType}
            placeholder={t('input.document_number_type.label')}
            {...commonInputProps}
          />
        </InputRow>
      )}
      {isDocumentNumberEnabled && (
        <DocumentNumberInput
          value={documentNumber}
          validation={validation}
          {...commonInputProps}
        />
      )}
      {isAddressEnabled && (
        <InputRow label={t('input.address.label')} error={addressError}>
          <TextInput
            name="address"
            value={address}
            placeholder={t('input.address.label') as string}
            {...commonInputProps}
          />
        </InputRow>
      )}
      {isNumberEnabled && (
        <InputRow label={t('input.number.label')} error={numberError}>
          <TextInput
            name="number"
            value={number}
            placeholder={t('input.number.label') as string}
            {...commonInputProps}
          />
        </InputRow>
      )}
      {isComplementEnabled && (
        <InputRow label={t('input.complement.label')} error={complementError}>
          <TextInput
            name="complement"
            value={complement}
            placeholder={t('input.complement.label') as string}
            {...commonInputProps}
          />
        </InputRow>
      )}
      {isStateEnabled && (
        <InputRow label={t('input.state.label')} error={stateError}>
          <SelectInput
            name="state"
            selectedValue={state}
            data={selectData.state}
            placeholder={t('input.state.label')}
            {...commonInputProps}
          />
        </InputRow>
      )}
      {isCityEnabled && (
        <InputRow label={t('input.city.label')} error={cityError}>
          {isCitySelectionEnabled ? (
            <SelectInput
              name="city"
              selectedValue={city}
              placeholder={t('input.city.label') as string}
              data={selectData.city}
              {...commonInputProps}
            />
          ) : (
            <TextInput
              name="city"
              value={city}
              placeholder={t('input.city.label') as string}
              {...commonInputProps}
            />
          )}
        </InputRow>
      )}
      {isZipCodeEnabled && (
        <InputRow label={t('input.zip_code.label')} error={zipCodeError}>
          <TextInput
            name="zipCode"
            value={zipCode}
            placeholder={t('input.zip_code.label') as string}
            {...commonInputProps}
          />
        </InputRow>
      )}
    </>
  );
};

export default AdditionalPaymentInfo;
