import type {
  InvoiceTableProps,
  Currency,
  InvoiceInfo,
} from '@openeducation/pp-rn-shared-types';

interface UseInvoiceTableProps {
  invoiceInfo?: InvoiceInfo;
  pdfLink?: string;
}

export const useInvoiceTableProps = ({
  invoiceInfo,
  pdfLink,
}: UseInvoiceTableProps) => {
  const currency = invoiceInfo?.invoice.currencyIso || ('' as Currency);

  const invoiceTableProps: InvoiceTableProps = {
    currency,
    invoiceId: invoiceInfo?.invoice?.number || '',
    pdfLink,
    amount: String(invoiceInfo?.invoice?.amount),
    dueDate: invoiceInfo?.invoice?.dueDate,
  };

  return { invoiceTableProps };
};
