import { PPApiService } from '@openeducation/pp-rn-services';
import type { AccountInfo } from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetAccountInfoInput {
  accountId: string;
}

export const useGetAccountInfo = ({ accountId }: UseGetAccountInfoInput) => {
  const { environment } = useEnvironmentContext();

  const { data, isError, isLoading } = useQuery<AccountInfo>(
    [QueryKey.GetAccountInfo, accountId],
    () => PPApiService.getAccountInfo(environment, accountId),
    { enabled: !!accountId, retry: false }
  );

  return {
    data,
    isError,
    isLoading,
  };
};
