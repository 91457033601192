import React, { ReactNode } from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';

interface ReactQueryInitProviderProps {
  queryClient: QueryClient;
  children: ReactNode;
}

export const ReactQueryInitProvider = ({
  children,
  queryClient,
}: ReactQueryInitProviderProps) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
