import type {
  SelectInputItem,
  Installment,
  Currency,
} from '@openeducation/pp-rn-shared-types';

import { addThousandSeparator, toPrecision } from '../../number-utils';

export const getInstallmentDisplayValue = (
  installment: Installment,
  currency: Currency,
  translatedTotalWord: string
) => {
  const { financialCostAmount, installments, installmentAmount, totalAmount } =
    installment;

  if (!financialCostAmount) {
    return `${installments}`;
  }

  return `${installments} x ${currency} ${addThousandSeparator(
    installmentAmount
  )} (${translatedTotalWord} ${currency} ${addThousandSeparator(
    toPrecision(totalAmount, 2)
  )})`;
};

export const mapInstallmentsSelectInputData = (
  arr: Installment[],
  currency: Currency,
  translatedTotalWord: string
) =>
  arr.map(
    (item: Installment) =>
      ({
        label: getInstallmentDisplayValue(item, currency, translatedTotalWord),
        value: `${item.installments}`,
      } as SelectInputItem)
  );

export const getSelectedInstallment = (
  installments: Installment[],
  installmentsFormData: string
) =>
  installments.find(
    (item: Installment) =>
      item.installments === parseInt(installmentsFormData, 10)
  );
