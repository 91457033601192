import React, { ReactNode } from 'react';
import { View } from 'react-native';
import { Provider as PaperProvider } from 'react-native-paper';

import type { ThemeType } from './ThemeType';
import { getFontsByPriority } from './fonts';
import {
  theme as styledDefaultTheme,
  ThemeProvider as StyledThemeProvider,
} from './styled';

interface ThemeProviderProps {
  theme: any;
  loadingComponent?: React.ReactNode;
  styledTheme?: ThemeType;
  children?: ReactNode;
  waitForFonts?: boolean;
}

const ThemeProviderWithoutPaper: React.FC<ThemeProviderProps> = ({
  children,
  styledTheme,
  loadingComponent,
  waitForFonts = true,
}) => {
  const [maxPriorityFontsLoaded, setMaxPriorityFontsLoaded] =
    React.useState(false);

  React.useEffect(() => {
    if (waitForFonts) {
      getFontsByPriority()[0]
        .then(() => setMaxPriorityFontsLoaded(true))
        .catch(() => setMaxPriorityFontsLoaded(true));
    }
  }, [waitForFonts]);

  if (waitForFonts && !maxPriorityFontsLoaded) {
    return <View>{loadingComponent}</View>;
  }

  return (
    <StyledThemeProvider theme={styledTheme ?? styledDefaultTheme}>
      {children}
    </StyledThemeProvider>
  );
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  theme,
  ...props
}) => {
  return (
    <PaperProvider theme={theme}>
      <ThemeProviderWithoutPaper theme={theme} {...props} />
    </PaperProvider>
  );
};
