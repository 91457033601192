import {
  ErrorModal,
  PPPageTemplate,
  PaymentInfo,
  PaymentStatusModal,
  PaymentSubmit,
  PersonalInfo,
  InfoMessage,
  PaymentMethodStatusInfo,
  AdditionalPaymentInfo,
  AddressValidationModal,
} from '@openeducation/pp-rn-components';
import { usePaymentMethodScreen } from '@openeducation/pp-rn-providers';
import { getBaseUrl } from '@openeducation/pp-rn-services';
import { PaymentMethodStatus } from '@openeducation/pp-rn-shared-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CreditCardPreviewWeb from '../components/credit-card-preview.web';
import { footerProps } from '../helpers';
import { useParams } from '../router';

const PaymentMethodScreen = () => {
  const { t } = useTranslation();
  const { accountId } = useParams();
  const baseUrl = getBaseUrl();

  const {
    pageTemplateProps,
    paymentMethodStatusInfoProps,
    errorModalProps,
    personalInfoProps,
    paymentInfoProps,
    additionalPaymentInfoProps,
    paymentSubmitProps,
    paymentStatusModalProps,
    isInvalidAccount,
    addressValidationModalProps,
  } = usePaymentMethodScreen(accountId as string, baseUrl);

  const isInProcess =
    paymentMethodStatusInfoProps?.status ===
    PaymentMethodStatus.PAYMENT_IN_PROCESS;

  const renderContent = () => {
    if (isInvalidAccount) {
      return (
        <InfoMessage iconName="times-circle">
          {t('account_status.invalid')}
        </InfoMessage>
      );
    }

    if (isInProcess) {
      return (
        <InfoMessage iconName="clock">
          {t('account_status.payment_in_process')}
        </InfoMessage>
      );
    }

    return (
      <>
        <PaymentMethodStatusInfo {...paymentMethodStatusInfoProps} />
        <PersonalInfo {...personalInfoProps} />
        <PaymentInfo
          {...paymentInfoProps}
          renderCardPreview={(
            number: string,
            expiry: string,
            name: string,
            cvv: string,
            focused?: string
          ) => (
            <CreditCardPreviewWeb
              number={number}
              expiry={expiry}
              name={name}
              cvc={cvv}
              focused={focused}
              cardType={paymentInfoProps.formData.cardType}
            />
          )}
        />
        <AdditionalPaymentInfo {...additionalPaymentInfoProps} />
        <PaymentSubmit {...paymentSubmitProps} />
      </>
    );
  };

  return (
    <>
      <PPPageTemplate {...pageTemplateProps} footerProps={footerProps}>
        {renderContent()}
      </PPPageTemplate>
      <PaymentStatusModal {...paymentStatusModalProps} isUPM />
      <ErrorModal {...errorModalProps} />
      <AddressValidationModal {...addressValidationModalProps} />
    </>
  );
};

export default PaymentMethodScreen;
