import { PaymentMethod } from '@openeducation/pp-rn-shared-types';

export const getPendingPaymentMethod = (
  isPayPalRedirectionFlow: boolean,
  isPayU: boolean,
  isYuno: boolean
) => {
  if (isPayPalRedirectionFlow) {
    return PaymentMethod.PAY_PAL;
  }
  if (isPayU || isYuno) {
    return PaymentMethod.ALTERNATIVE;
  }

  return PaymentMethod.CREDIT_CARD;
};
