import { FontAwesome5 } from '@expo/vector-icons';
import { Language } from '@openeducation/pp-rn-shared-types';
import { styled } from '@openeducation/pp-rn-themes';
import React, { memo, useMemo, useState } from 'react';

import LanguagePickerDropdown from './language-picker-dopdown';
import { PPButton, PPText } from '../../atoms';

export interface LanguagePickerProps {
  languages: Language[];
  selectedLanguage: Language;
  onLanguageSelect: (lang: Language) => void;
}

const languageNames = {
  [Language.EN]: 'English',
  [Language.ES]: 'Español',
  [Language.PT]: 'Português',
  [Language.TR]: 'Türkçe',
  [Language.VI]: 'Tiếng Việt',
};

export const LanguagePicker = ({
  languages,
  selectedLanguage,
  onLanguageSelect,
}: LanguagePickerProps) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleDropdown = () => setIsDropdownVisible((prev) => !prev);

  const handleLanguageSelect = (lang: Language) => {
    onLanguageSelect(lang);
    toggleDropdown();
  };

  const pickerItems = useMemo(
    () =>
      languages.map((lang: Language) => ({
        key: lang,
        value: languageNames[lang],
      })),
    [languages]
  );

  return (
    <Container>
      <PickerButton onPress={toggleDropdown} icon="translate">
        <PickerButtonContent>
          <ButtonText>
            {languageNames[selectedLanguage].toUpperCase()}
          </ButtonText>
          <DropdownIconWrapper>
            <DropdownIcon />
          </DropdownIconWrapper>
        </PickerButtonContent>
      </PickerButton>
      {isDropdownVisible && (
        <LanguagePickerDropdown
          items={pickerItems}
          onLanguageSelect={handleLanguageSelect}
        />
      )}
    </Container>
  );
};

const Container = styled.View``;

const PickerButton = styled(PPButton).attrs(({ theme }) => ({
  labelStyle: {
    marginHorizontal: theme.spacing.primary,
    marginVertical: theme.spacing.extraSmall,
  },
}))`
  align-self: flex-end;
`;

const PickerButtonContent = styled.View`
  flex-direction: row;
  align-items: center;
`;

const ButtonText = styled(PPText).attrs(({ theme }) => ({
  color: theme.colors.light,
  fontType: 'semiBold',
}))`
  padding-horizontal: ${({ theme }) => theme.spacing.tiny}px;
`;

const DropdownIconWrapper = styled.View`
  margin-top: ${({ theme }) => -theme.spacing.extraSmall}px;
`;

const DropdownIcon = styled(FontAwesome5).attrs(({ theme }) => ({
  color: theme.colors.light,
  name: 'sort-down',
  size: 16,
}))``;

export default memo(LanguagePicker);
