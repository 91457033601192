import type { ManualPaymentInfoProps } from '@openeducation/pp-rn-shared-types';
import { useTranslation } from 'react-i18next';

interface UseManualPaymentInfoErrorMessages {
  formData: ManualPaymentInfoProps['formData'];
  validation: ManualPaymentInfoProps['validation'];
}

export const useManualPaymentInfoErrorMessages = ({
  formData,
  validation,
}: UseManualPaymentInfoErrorMessages) => {
  const { t } = useTranslation();
  const {
    paymentGateway,
    transactionNumber,
    sendBy,
    paymentDate,
    proofOfPayment,
    bank,
  } = formData;
  const {
    isTransactionNumberTooLong,
    iseSendByTooLong,
    isProofOfPaymentTooBig,
    isBanksRequired,
  } = validation;
  const paymentGatewayError = paymentGateway
    ? ''
    : t('input.payment_method.error.required');

  const paymentGatewayBanksError =
    !bank && isBanksRequired ? t('input.payment_bank.error.required') : '';

  const transactionNumberError = (() => {
    if (!transactionNumber) {
      return t('input.transaction_number.error.required');
    }
    if (isTransactionNumberTooLong) {
      return t('input.transaction_number.error.maxlength');
    }
    return '';
  })();

  const sendByError = (() => {
    if (!sendBy) {
      return t('input.send_by.error.required');
    }
    if (iseSendByTooLong) {
      return t('input.send_by.error.maxlength');
    }
    return '';
  })();

  const paymentDateError = paymentDate
    ? ''
    : t('input.payment_date.error.required');

  const proofOfPaymentError = (() => {
    if (!proofOfPayment) {
      return t('input.proof_of_payment.error.required');
    }
    if (isProofOfPaymentTooBig) {
      return t('input.proof_of_payment.error.maxsize');
    }
    return '';
  })();

  return {
    paymentGatewayError,
    transactionNumberError,
    sendByError,
    paymentDateError,
    proofOfPaymentError,
    paymentGatewayBanksError,
  };
};
