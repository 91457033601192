import { redirectToPayPal } from '@openeducation/pp-rn-services';
import type { Country } from '@openeducation/pp-rn-shared-types';
import { useEffect } from 'react';

import { useEnvironmentContext } from '../../../environment/environment.provider';
import { useCreatePayPalToken } from '../../../hooks';

export interface SubmitPayPalParams {
  baseUrl: string | null;
  agreementDescription: string | null;
  pmCountry: Country['isoCode'] | null;
  sfdcToken: string | null;
  zuoraAccountId: string | null;
}

export const useSubmitPayPal = (
  {
    baseUrl,
    agreementDescription,
    pmCountry,
    sfdcToken,
    zuoraAccountId,
  }: SubmitPayPalParams,
  contractId: string = ''
) => {
  const { environment } = useEnvironmentContext();

  const returnUrl = `${baseUrl}/success?contractId=${contractId}&accountId=${
    zuoraAccountId || ''
  }&sfdcToken=${sfdcToken || ''}`;
  const cancelUrl = `${baseUrl}/cancel?accountId=${
    zuoraAccountId || ''
  }&sfdcToken=${sfdcToken || ''}`;

  const {
    reset,
    createPayPalToken,
    data: payPalToken,
    isError,
    apiError,
  } = useCreatePayPalToken({
    returnUrl,
    cancelUrl,
    agreementDescription,
    countryIsoCode: pmCountry || '',
    sfdcToken,
    zuoraAccountId,
  });

  useEffect(() => {
    if (payPalToken) {
      redirectToPayPal(environment, payPalToken);
    }
  }, [payPalToken, environment]);

  return {
    payPalReset: reset,
    submitPayPal: createPayPalToken,
    payPalApiError: apiError,
    isPayPalError: isError,
  };
};
