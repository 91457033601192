import {
  addThousandSeparator,
  getDisplayPrice,
} from '@openeducation/pp-js-utils';
import type {
  InvoiceCardProps,
  FreeTrialsPaymentInfo,
} from '@openeducation/pp-rn-shared-types';
import { useDimension } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import InvoiceCardDiscount from './invoice-card-discount';
import InvoiceCardFreeTrial from './invoice-card-free-trial';
import InvoiceCardInstallments from './invoice-card-installments';
import {
  Container,
  Content,
  ContentWrapper,
  Cell,
  Label,
  Value,
} from './invoice-card.styles';

export const InvoiceCard = ({
  name,
  quantity,
  price,
  discount,
  total,
  currency,
  isFirstPayment,
  installments,
  freeTrialsInfo = {} as FreeTrialsPaymentInfo,
}: InvoiceCardProps) => {
  const { t } = useTranslation();
  const { isMediumSize, isTablet } = useDimension();
  const hasFreeTrial = !!freeTrialsInfo.hasFreeTrial;

  const renderOriginalPrice = () => (
    <Cell isMediumSize={isMediumSize} hasBorderBottom alignEnd={!isMediumSize}>
      <Label>{t('invoice.base_price')}</Label>
      <Value>{getDisplayPrice(price, currency)}</Value>
      {!!discount && (
        <InvoiceCardDiscount discount={discount} currency={currency} />
      )}
    </Cell>
  );

  const renderQuantity = () => (
    <Cell
      isMediumSize={isMediumSize}
      hasBorderRight={!isMediumSize}
      hasBorderBottom={isMediumSize}
    >
      <Label>{t('invoice.quantity')}</Label>
      <Value>{addThousandSeparator(quantity)}</Value>
    </Cell>
  );

  return (
    <Container isMediumSize={isMediumSize} isTablet={isTablet}>
      {hasFreeTrial && <InvoiceCardFreeTrial {...freeTrialsInfo} />}
      <Content isMediumSize={isMediumSize} isTablet={isTablet}>
        <ContentWrapper isMediumSize={isMediumSize}>
          <Cell
            isMediumSize={isMediumSize}
            hasBorderRight={!isMediumSize}
            hasBorderBottom
          >
            <Label>{t('invoice.name')}</Label>
            <Value>{name}</Value>
          </Cell>
          {isMediumSize ? renderQuantity() : renderOriginalPrice()}
        </ContentWrapper>
        <ContentWrapper isMediumSize={isMediumSize}>
          {isMediumSize ? renderOriginalPrice() : renderQuantity()}
          <Cell isMediumSize={isMediumSize} alignEnd={!isMediumSize}>
            <Label isBig>
              {t(
                isFirstPayment
                  ? 'invoice.split_total_to_pay'
                  : 'invoice.total_to_pay'
              )}
            </Label>
            <Value isBig>{getDisplayPrice(total, currency)}</Value>
            {!!installments && (
              <InvoiceCardInstallments currency={currency} {...installments} />
            )}
          </Cell>
        </ContentWrapper>
      </Content>
    </Container>
  );
};

export default memo(InvoiceCard);
