import { pmCountryAtom } from '@openeducation/pp-rn-jotai-atoms';
import {
  PaymentFlowType,
  PaymentGateway,
  PaymentParams,
} from '@openeducation/pp-rn-shared-types';
import { useAtomValue } from 'jotai';

import { useInvoicePaymentSubmitProps } from './use-invoice-payment-submit-props';
import { getBillingAddress, getIsSubmitEnabledParams } from '../../helpers';
import { getPaymentParams } from '../../helpers/get-payment-params';
import { useManualPaymentInfoProps } from '../checkout/use-manual-payment-info-props';
import { useHandleAddressValidation } from '../checkout/use-payment-submit-props/use-handle-address-validation';
import { useAdditionalPaymentInfoProps } from '../use-additional-payment-info-props';
import { useErrorModalProps } from '../use-error-modal-props';
import { useInvoiceData } from '../use-invoice-data';
import { useIsSubmitEnabled } from '../use-is-submit-enabled';
import { usePaymentInfoProps } from '../use-payment-info-props';
import { usePaymentStatusModalProps } from '../use-payment-status-modal-props';
import { usePersonalInfoProps } from '../use-personal-info-props';
import { useSetFirstPaymentMethod } from '../use-set-first-payment-method';
import type { SubmitYunoParams } from '../use-submit-yuno';

interface UseInvoiceScreenProps {
  invoiceId: string;
  accountId: string;
  baseUrl: string;
}

export const useInvoiceScreen = ({
  invoiceId = '',
  accountId = '',
  baseUrl,
}: UseInvoiceScreenProps) => {
  const pmCountry = useAtomValue(pmCountryAtom);

  const {
    organization,
    countryIso,
    commonPaymentInfo,
    invoiceInfo,
    invoiceTableProps,
    creditCardPaymentInfo,
    additionalPaymentInfo,
    countryStates,
    manualPaymentInfo,
    allCountries,
    isScreenLoading,
    alternativePaymentInfo,
  } = useInvoiceData({
    invoiceId,
    accountId,
  });

  const pageProps = {
    isLoading: isScreenLoading,
    headerProps: {
      organization,
    },
  };

  const { additionalPaymentInfoProps } = useAdditionalPaymentInfoProps({
    additionalPaymentInfo,
    countryStates,
    isExistingPaymentMethod: false,
  });

  const { personalInfoProps } = usePersonalInfoProps({
    fullName: invoiceInfo?.personalInfo?.fullName,
    email: invoiceInfo?.personalInfo?.email,
    countryISOCode: countryIso?.toLowerCase(),
    personalDetails: {
      country: true,
      email: true,
      fullName: true,
      cpf: additionalPaymentInfo?.cpf || false,
    },
  });

  const { paymentInfoProps } = usePaymentInfoProps({
    countryISOCode: countryIso,
    commonPaymentInfo,
    allCountries,
    creditCardPaymentInfo,
  });

  const billingAddress = getBillingAddress({
    additionalPaymentInfo: additionalPaymentInfoProps.formData,
    pmCountry,
  });

  useSetFirstPaymentMethod({ commonPaymentInfo });

  const { manualPaymentInfoProps } = useManualPaymentInfoProps({
    manualPaymentInfo,
  });

  const { addressValidationModalProps } = useHandleAddressValidation({
    billingAddress,
  });

  const { errorModalProps } = useErrorModalProps({
    isB2B: false,
    showMessage: false,
  });

  const paymentFlowType = PaymentFlowType.MANUAL_SALE;

  const { paymentStatusModalProps } = usePaymentStatusModalProps({
    paymentFlowType,
    alternativePaymentGateway: manualPaymentInfoProps?.formData
      ?.paymentGateway as PaymentGateway,
  });

  const alternativePaymentGateway = alternativePaymentInfo?.paymentGateway;

  const paymentParams: PaymentParams = getPaymentParams({
    paymentInfo: paymentInfoProps?.formData,
    cpfNumber: personalInfoProps.formData.cpf,
    documentNumber: additionalPaymentInfoProps.formData.documentNumber,
    documentType: additionalPaymentInfoProps.formData.documentType,
    billingAddress,
    manualPayment: {
      gateway: manualPaymentInfoProps?.formData?.paymentGateway,
      transactionNumber: manualPaymentInfoProps?.formData?.transactionNumber,
      paymentDate: manualPaymentInfoProps?.formData?.paymentDate,
      proofOfPaymentFileContent:
        manualPaymentInfoProps?.formData?.proofOfPayment?.content,
    },
    alternativePaymentGateway,
  });

  const isSubmitEnabledParams = getIsSubmitEnabledParams({
    personalInfoProps,
    paymentInfoProps,
    manualPaymentInfoProps,
  });
  const { isSubmitEnabled } = useIsSubmitEnabled(isSubmitEnabledParams);

  const submitYunoParams: SubmitYunoParams = {
    baseUrl,
    pmCountry: countryIso?.toLocaleLowerCase() || null,
    accountId,
    invoiceId,
  };

  const { paymentSubmitProps } = useInvoicePaymentSubmitProps({
    submitYunoParams,
    invoiceId,
    accountId,
    baseUrl,
    pmCountry: invoiceInfo?.personalInfo?.countryIso,
    paymentParams,
    alternativePaymentGateway,
    isSubmitEnabled,
  });

  return {
    paymentInfoProps,
    manualPaymentInfoProps,
    personalInfoProps,
    paymentSubmitProps,
    pageProps,
    invoiceTableProps,
    paymentStatusModalProps,
    errorModalProps,
    billingAddress,
    additionalPaymentInfoProps,
    addressValidationModalProps,
    isLoading: paymentSubmitProps.isLoading,
  };
};
