import { preferredLanguageAtom } from '@openeducation/pp-rn-jotai-atoms';
import { Language } from '@openeducation/pp-rn-shared-types';
import { useAtom } from 'jotai';

import { getLanguageByLocalization } from '../helpers';

const supportedLanguages = [Language.EN, Language.ES, Language.PT, Language.TR];

const browserLanguage = getLanguageByLocalization(supportedLanguages);

// 1. use stored language
// 2. if nothing was stored - use tiny payloads langauge
// 3. if no tiny paylaods - use browser langauge
// 4. EN as a fallback

export const usePreferredLanguage = () => {
  const [atomLanguage, setPreferredLanguage] = useAtom(preferredLanguageAtom);

  const preferredLanguage = atomLanguage || browserLanguage || Language.EN;

  return {
    preferredLanguage,
    setPreferredLanguage,
  };
};
