import type { ManualPaymentInfoProps } from '@openeducation/pp-rn-shared-types';

export interface ValidateManualPaymentInfoParams {
  fields: ManualPaymentInfoProps['fields'];
  validation: ManualPaymentInfoProps['validation'];
  formData: ManualPaymentInfoProps['formData'];
}

export const validateManualPaymentInfo = ({
  fields,
  validation,
  formData,
}: ValidateManualPaymentInfoParams) => {
  const {
    isPaymentGatewayEnabled,
    isTransactionNumberEnabled,
    isSendByEnabled,
    isPaymentDateEnabled,
    isProofOfPaymentEnabled,
    isBanksEnabled,
  } = fields;
  const {
    paymentGateway,
    transactionNumber,
    sendBy,
    paymentDate,
    proofOfPayment,
    bank,
  } = formData;
  const {
    isTransactionNumberTooLong,
    iseSendByTooLong,
    isProofOfPaymentTooBig,
  } = validation;

  const isPaymentGatewayOk = isPaymentGatewayEnabled ? !!paymentGateway : true;
  const isPaymentGatewayBankOk = isBanksEnabled ? !!bank : true;
  const isTransactionNumberOk = isTransactionNumberEnabled
    ? !!transactionNumber && !isTransactionNumberTooLong
    : true;
  const isSendByOk = isSendByEnabled ? !!sendBy && !iseSendByTooLong : true;
  const isPaymentDateOk = isPaymentDateEnabled ? !!paymentDate : true;
  const isProofOfPaymentOk = isProofOfPaymentEnabled
    ? !!proofOfPayment.content && !isProofOfPaymentTooBig
    : true;

  return (
    isPaymentGatewayOk &&
    isTransactionNumberOk &&
    isSendByOk &&
    isPaymentDateOk &&
    isProofOfPaymentOk &&
    isPaymentGatewayBankOk
  );
};
