import { styled, useStyledTheme } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';
import type { TextInputProps as RNTextInputProps } from 'react-native';

export interface TextInputProps
  extends Omit<RNTextInputProps, 'onFocus' | 'onBlur'> {
  disabled?: boolean;
  onValueChange?: (value: string, name?: string) => void;
  onFocus?: (name?: string) => void;
  onBlur?: (name?: string) => void;
  name?: string;
}

export const TextInput = ({
  disabled,
  name,
  onValueChange,
  onFocus,
  onBlur,
  ...props
}: TextInputProps) => {
  const { colors } = useStyledTheme();
  return (
    <StyledInput
      disabled={disabled}
      onChangeText={(text) => onValueChange?.(text, name)}
      placeholderTextColor={colors.dark3}
      onFocus={() => onFocus?.(name)}
      onBlur={() => onBlur?.(name)}
      {...props}
    />
  );
};

type StyledInputType = {
  disabled?: boolean;
};

const StyledInput = styled.TextInput<StyledInputType>`
  padding-horizontal: ${({ theme }) => theme.spacing.primarySmall}px;
  padding-vertical: ${({ theme }) => theme.spacing.small}px;
  border-radius: ${({ theme }) => theme.border.radius.primary}px;
  border-width: ${({ theme }) => theme.border.width.primary}px;
  border-color: ${({ theme }) => theme.colors.border};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.dark0 : theme.colors.light};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.dark3 : theme.colors.text};
  font-family: ${({ theme }) => theme.font.regular.fontFamily};
  font-size: 13px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'text')};
`;

export default memo(TextInput);
