import { errorModalAtom } from '@openeducation/pp-rn-jotai-atoms';
import {
  Organization,
  AccountStatus,
  Currency,
  PaymentGateway,
} from '@openeducation/pp-rn-shared-types';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

import {
  useGetAccountStatusInfo,
  useGetAlternativePaymentInfo,
  useGetCountryByCode,
  useGetPersonalDetails,
  useGetWebCheckoutFormData,
} from '../../../hooks';
import { getOrganizationPhoneKey } from '../../helpers';

interface UseGetAccountScreenData {
  accountId: string;
}

export const useGetAccountScreenData = ({
  accountId,
}: UseGetAccountScreenData) => {
  const setErrorModal = useSetAtom(errorModalAtom);

  const {
    data: statusInfo,
    isLoading: isStatusInfoLoading,
    apiError: statusInfoError,
  } = useGetAccountStatusInfo({ accountId });

  const countryISOCode = (
    statusInfo?.personalInfo?.countryIso || ''
  ).toLowerCase();
  const currency = statusInfo?.invoice?.currencyIso || ('' as Currency);
  const organization = statusInfo?.organization;
  const isPendingPayment =
    statusInfo?.accountStatus === AccountStatus.PENDING_PAYMENT;
  const areDetailsRequestsEnabled = !!statusInfo && !isPendingPayment;

  useEffect(() => {
    if (statusInfoError) {
      setErrorModal({
        visible: true,
        errorKey: statusInfoError.message,
        retryEnabled: false,
      });
    }
  }, [statusInfoError, setErrorModal]);

  const { data: country, isLoading: isCountryLoading } = useGetCountryByCode({
    countryISOCode,
  });

  const { data: personalDetails, isLoading: isPersonalDetailsLoading } =
    useGetPersonalDetails(
      {
        countryISOCode,
      },
      areDetailsRequestsEnabled
    );

  const {
    data: alternativePaymentInfo,
    isLoading: isAlternativePaymentInfoLoading,
  } = useGetAlternativePaymentInfo({
    currency,
    organization,
    accountId,
  });

  const { data: webCheckoutFormData, isLoading: isWebCheckoutFormDataLoading } =
    useGetWebCheckoutFormData(
      { accountId },
      areDetailsRequestsEnabled &&
        alternativePaymentInfo?.paymentGateway === PaymentGateway.PAYU
    );

  const isLoadingArray = [
    !statusInfo, // NOTE: we should show the loader if BE is down
    isCountryLoading,
    isPersonalDetailsLoading,
    isStatusInfoLoading,
    isWebCheckoutFormDataLoading,
    isAlternativePaymentInfoLoading,
  ];

  const data = {
    statusInfo,
    country,
    personalDetails,
    webCheckoutFormData,
  };

  const contactPhone =
    (country &&
      country.phone[
        getOrganizationPhoneKey(organization || Organization.OPEN_ENGLISH)
      ]) ||
    '';

  return {
    isScreenLoading: isLoadingArray.includes(true),
    data,
    contactPhone,
    organization,
    countryISOCode,
    isPendingPayment,
    alternativePaymentInfo,
  };
};
