import * as ExpoFont from 'expo-font';

import {
  FontAsset,
  FontAssetFamily,
  mapFontAssetFamilies,
} from './font-asset-family';
import type { FontsType } from './fonts-type';

const primaryFontAssets = new FontAssetFamily({
  regular: new FontAsset('Inter-Regular', 20),
  italic: new FontAsset('Inter-Italic', 30),
  light: new FontAsset('Inter-Light', 30),
  bold: new FontAsset('Inter-Bold', 20),
  medium: new FontAsset('Inter-Medium', 50),
  semiBold: new FontAsset('Inter-SemiBold', 50),
  semiBoldItalic: new FontAsset('Inter-SemiBoldItalic', 60),
  thin: new FontAsset('Inter-Thin', 70),
});

export const getFontsByPriority = () => {
  const fontAssetFamilies = mapFontAssetFamilies({}, [primaryFontAssets]);
  return Object.values(fontAssetFamilies).map((fonts) =>
    Promise.all(fonts.map((font) => ExpoFont.loadAsync(font)))
  );
};

export const primaryFontFamily: FontsType = primaryFontAssets.getFontsType;
