import type { ManualPaymentInfoProps } from '@openeducation/pp-rn-shared-types';
import { styled } from '@openeducation/pp-rn-themes';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useManualPaymentInfoErrorMessages } from './use-manual-payment-info-error-messages';
import { SelectInput, TextInput } from '../../atoms';
import { DateInput, FileInput, InputRow } from '../../molecules';

export const ManualPaymentInfo = ({
  formData,
  onFieldChange,
  selectData,
  fields,
  validation,
  today,
  language,
}: ManualPaymentInfoProps) => {
  const { t } = useTranslation();
  const {
    paymentGatewayError,
    transactionNumberError,
    sendByError,
    paymentDateError,
    proofOfPaymentError,
    paymentGatewayBanksError,
  } = useManualPaymentInfoErrorMessages({
    validation,
    formData,
  });
  const {
    isPaymentGatewayEnabled,
    isTransactionNumberEnabled,
    isSendByEnabled,
    isPaymentDateEnabled,
    isProofOfPaymentEnabled,
    isBanksEnabled,
  } = fields;
  const { paymentGateway, bank, transactionNumber, sendBy } = formData;

  const commonInputProps = {
    onValueChange: onFieldChange,
  };

  return (
    <>
      {isPaymentGatewayEnabled && (
        <InputRow
          label={t('input.payment_method.label')}
          error={paymentGatewayError}
        >
          <SelectInput
            name="paymentGateway"
            selectedValue={paymentGateway}
            data={selectData.paymentGateway}
            placeholder={t('input.payment_method.label')}
            {...commonInputProps}
          />
        </InputRow>
      )}
      {isBanksEnabled && selectData?.paymentGatewayBanks && (
        <InputRow
          label={t('input.payment_bank.label')}
          error={paymentGatewayBanksError}
        >
          <SelectInput
            name="bank"
            selectedValue={bank || ''}
            data={selectData?.paymentGatewayBanks}
            placeholder={t('input.payment_bank.label')}
            {...commonInputProps}
          />
        </InputRow>
      )}
      {isTransactionNumberEnabled && (
        <InputRow
          label={t('input.transaction_number.label')}
          error={transactionNumberError}
        >
          <TextInput
            name="transactionNumber"
            value={transactionNumber}
            placeholder={t('input.transaction_number.label') as string}
            {...commonInputProps}
          />
        </InputRow>
      )}
      {isSendByEnabled && (
        <InputRow label={t('input.send_by.label')} error={sendByError}>
          <TextInput
            name="sendBy"
            value={sendBy}
            placeholder={t('input.send_by.placeholder') as string}
            {...commonInputProps}
          />
        </InputRow>
      )}
      {isPaymentDateEnabled && (
        <PaymentDateRow
          label={t('input.payment_date.label')}
          error={paymentDateError}
        >
          <DateInput
            name="paymentDate"
            today={today}
            language={language}
            {...commonInputProps}
          />
        </PaymentDateRow>
      )}
      {isProofOfPaymentEnabled && (
        <InputRow
          label={t('input.proof_of_payment.label')}
          error={proofOfPaymentError}
        >
          <FileInput
            name="proofOfPayment"
            placeholder={t('input.proof_of_payment.placeholder') as string}
            {...commonInputProps}
          />
        </InputRow>
      )}
    </>
  );
};

const PaymentDateRow = styled(InputRow)`
  z-index: 1;
`;

export default ManualPaymentInfo;
