import React from 'react';

import { Path } from './paths';
import { Navigate } from './router';
import AccountScreen from '../screens/account.screen';
import CallbackSuccessScreen from '../screens/callback-success.screen';
import CancelScreen from '../screens/cancel.screen';
import CheckoutScreen from '../screens/checkout.screen';
import ErrorScreen from '../screens/error.screen';
import HomeScreen from '../screens/home.screen';
import InvoiceScreen from '../screens/invoice.screen';
import PaymentMethodScreen from '../screens/payment-method.screen';
import SuccessScreen from '../screens/success.screen';
import YunoCallbackScreen from '../screens/yuno-callback.screen';

export const publicRoutes = [
  {
    path: Path.Root,
    private: false,
    component: <Navigate replace to={Path.Home} />,
  },
  {
    path: Path.NotFound,
    private: false,
    component: <Navigate replace to={Path.Home} />,
  },
  {
    path: Path.Home,
    private: false,
    component: <HomeScreen />,
  },
  {
    path: Path.Checkout,
    private: false,
    component: <CheckoutScreen />,
  },
  {
    path: Path.Success,
    private: false,
    component: <SuccessScreen />,
  },
  {
    path: Path.CallbackSuccess,
    private: false,
    component: <CallbackSuccessScreen />,
  },
  {
    path: Path.YunoCallback,
    private: false,
    component: <YunoCallbackScreen />,
  },
  {
    path: Path.Error,
    private: false,
    component: <ErrorScreen />,
  },
  {
    path: Path.Account,
    private: false,
    component: <AccountScreen />,
  },
  {
    path: Path.Invoice,
    private: false,
    component: <InvoiceScreen />,
  },
  {
    path: Path.PaymentMethod,
    private: false,
    component: <PaymentMethodScreen />,
  },
  {
    path: Path.Cancel,
    private: false,
    component: <CancelScreen />,
  },
];
