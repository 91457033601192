import { styled, ThemeType, useStyledTheme } from '@openeducation/pp-rn-themes';
import { rgba } from 'polished';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { PPButton, PPText } from '../../atoms';

interface PayUSubmitButtonProps {
  disabled: boolean;
  isLoading?: boolean;
  onPress: () => void;
}

const PayUSubmitButton = ({
  disabled,
  isLoading,
  onPress,
}: PayUSubmitButtonProps) => {
  const { t } = useTranslation();
  const { colors } = useStyledTheme();

  return (
    <SubmitButton disabled={disabled} loading={isLoading} onPress={onPress}>
      <Content>
        <PPText
          fontType="semiBold"
          color={disabled ? rgba(colors.text, 0.3) : colors.light}
        >
          {t('button.submit_payu')}
        </PPText>
        <ButtonImage
          source={require('../../assets/payu.png')}
          resizeMode="contain"
          disabled={disabled}
        />
      </Content>
    </SubmitButton>
  );
};

type SubmitButtonType = {
  loading?: boolean;
  theme: ThemeType;
};

const SubmitButton = styled(PPButton).attrs(
  ({ theme, loading }: SubmitButtonType) => ({
    contentStyle: {
      flexDirection: 'row-reverse',
    },
    labelStyle: {
      textTransform: 'uppercase',
      marginVertical: theme.spacing.tiny,
      marginLeft: theme.spacing.primary,
      marginRight: loading ? theme.spacing.medium : theme.spacing.small,
    },
  })
)<SubmitButtonType>`
  align-self: flex-end;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const Content = styled.View`
  flex-direction: row;
  align-items: center;
`;

type ButtonImageType = {
  disabled: boolean;
};

const ButtonImage = styled.Image<ButtonImageType>`
  width: 80px;
  height: 42px;
  margin-left: ${({ theme }) => theme.spacing.primary}px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export default memo(PayUSubmitButton);
