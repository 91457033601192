import { CreditCard } from '@openeducation/pp-rn-shared-types';
import React, { memo, useMemo } from 'react';
import Cards, { Focused, ReactCreditCardProps } from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import './credit-card.preview.css';

interface CreditCardPreviewProps extends Omit<ReactCreditCardProps, 'focused'> {
  focused?: string;
  cardType: CreditCard;
}

const CreditCardPreview = ({
  cvc,
  expiry,
  focused,
  name,
  number,
  cardType,
}: CreditCardPreviewProps) => {
  const issuer = useMemo(() => {
    switch (cardType) {
      case CreditCard.SHOPPING:
      case CreditCard.AURA:
        return 'unknown';
      default:
        return undefined;
    }
  }, [cardType]);

  return (
    <Cards
      cvc={cvc}
      expiry={expiry}
      focused={focused as Focused}
      name={name}
      number={number}
      preview
      issuer={issuer}
    />
  );
};

export default memo(CreditCardPreview);
