import type { AdditionalPaymentInfoProps } from '@openeducation/pp-rn-shared-types';
import { useTranslation } from 'react-i18next';

interface UseAdditionalPaymentInfoMessages {
  validation: AdditionalPaymentInfoProps['validation'];
  formData: AdditionalPaymentInfoProps['formData'];
  isCitySelectionEnabled?: boolean;
}

const MAX_INPUT_LENGTH = 200;

export const useAdditionalPaymentInfoMessages = ({
  validation,
  formData,
  isCitySelectionEnabled,
}: UseAdditionalPaymentInfoMessages) => {
  const { t } = useTranslation();
  const {
    isAddressMandatory,
    isZipCodeTooShort,
    isZipCodeTooLong,
    minZipCodeLength,
    maxZipCodeLength,
  } = validation;

  const { documentType, address, complement, number, state, city, zipCode } =
    formData;

  const documentTypeError = documentType
    ? ''
    : t('input.document_number_type.error.required');

  const addressError = (() => {
    if (isAddressMandatory && !address) {
      return t('input.address.error.required');
    }
    if (address.length > MAX_INPUT_LENGTH) {
      return t('input.address.error.maxlength');
    }
    return '';
  })();

  const numberError = (() => {
    if (isAddressMandatory && !number) {
      return t('input.number.error.required');
    }
    if (number.length > MAX_INPUT_LENGTH) {
      return t('input.number.error.maxlength');
    }
    return '';
  })();

  const complementError =
    complement.length > MAX_INPUT_LENGTH
      ? t('input.complement.error.maxlength')
      : '';

  const stateError = (() => {
    if (isAddressMandatory && !state) {
      return t('input.state.error.required');
    }
    return '';
  })();

  const cityError = (() => {
    if (isAddressMandatory && !city) {
      return t('input.city.error.required');
    }
    if (!isCitySelectionEnabled && city.length > 20) {
      return t('input.city.error.maxlength');
    }
    return '';
  })();

  const zipCodeError = (() => {
    if (isAddressMandatory && !zipCode) {
      return t('input.zip_code.error.required');
    }
    if (isZipCodeTooShort) {
      return t('input.zip_code.error.minlength', {
        zipCodeMinLength: minZipCodeLength,
      });
    }
    if (isZipCodeTooLong) {
      return t('input.zip_code.error.maxlength', {
        zipCodeMaxLength: maxZipCodeLength,
      });
    }
    return '';
  })();

  return {
    documentTypeError,
    addressError,
    numberError,
    complementError,
    stateError,
    cityError,
    zipCodeError,
  };
};
