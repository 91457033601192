import { AccountStatus } from '@openeducation/pp-rn-shared-types';
import { useTranslation } from 'react-i18next';

import { InfoBannerVariant, InfoBannerProps } from '../../molecules';

export const useAccountStatusInfoBanner = (
  status: AccountStatus,
  isSuspended?: boolean
): InfoBannerProps => {
  const { t } = useTranslation();

  switch (status) {
    case AccountStatus.OPEN_BALANCE:
      if (isSuspended) {
        return {
          children: t('alert.suspended_non_payment') as string,
          variant: InfoBannerVariant.ERROR,
        };
      } else {
        return {
          children: t('alert.please_retry_payment') as string,
          variant: InfoBannerVariant.WARNING,
        };
      }
    case AccountStatus.PENDING_PAYMENT:
      return {
        children: t('alert.payment_in_progress') as string,
        variant: InfoBannerVariant.INFO,
      };
    case AccountStatus.UP_TO_DATE:
      return {
        children: t('alert.all_good') as string,
        variant: InfoBannerVariant.SUCCESS,
      };
  }
};
