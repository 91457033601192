import { styled } from '@openeducation/pp-rn-themes';
import React, { memo, ReactNode } from 'react';
import type { StyleProp, ViewStyle } from 'react-native';

import { PPText } from '../../atoms';

export interface BulletListItemProps {
  children: ReactNode;
  style?: StyleProp<ViewStyle>;
}

export const BulletListItem = ({ children, style }: BulletListItemProps) => {
  return (
    <Container style={style}>
      <Bullet />
      <TextWrapper>
        <PPText>{children}</PPText>
      </TextWrapper>
    </Container>
  );
};

const BULLET_SIZE = 8;

const Bullet = styled.View`
  width: ${BULLET_SIZE}px;
  height: ${BULLET_SIZE}px;
  border-radius: ${BULLET_SIZE / 2}px;
  background-color: ${({ theme }) => theme.colors.primary};
  margin-top: ${({ theme }) => theme.spacing.small}px;
`;

const Container = styled.View`
  margin-bottom: ${({ theme }) => theme.spacing.small}px;
  flex-direction: row;
`;

const TextWrapper = styled.View`
  flex-wrap: wrap;
  flex: 1;
  margin-left: ${({ theme }) => theme.spacing.small}px;
`;

export default memo(BulletListItem);
