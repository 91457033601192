import { useStyledTheme, styled } from '@openeducation/pp-rn-themes';
import { darken, rgba } from 'polished';
import React, { memo, useMemo } from 'react';
import type { StyleProp, ViewStyle } from 'react-native';

import { PPText } from '../../atoms';

export enum InfoBannerVariant {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
}

export interface InfoBannerProps {
  children: string | string[];
  variant?: InfoBannerVariant;
  style?: StyleProp<ViewStyle>;
}

export const InfoBanner = ({
  children,
  variant = InfoBannerVariant.INFO,
  style,
}: InfoBannerProps) => {
  const { colors } = useStyledTheme();

  const variantColor = useMemo(() => {
    switch (variant) {
      case InfoBannerVariant.ERROR:
        return colors.error;
      case InfoBannerVariant.WARNING:
        return colors.warning;
      case InfoBannerVariant.SUCCESS:
        return colors.success;
      case InfoBannerVariant.INFO:
      default:
        return colors.info;
    }
  }, [variant, colors]);

  return (
    <Container color={variantColor} style={style}>
      <PPText color={darken(0.15, variantColor)}>{children}</PPText>
    </Container>
  );
};

type ContainerType = {
  color: string;
};

const Container = styled.View<ContainerType>`
  background-color: ${({ color }) => rgba(color, 0.05)};
  border-color: ${({ color }) => color};
  border-width: ${({ theme }) => theme.border.width.primary}px;
  border-radius: ${({ theme }) => theme.border.radius.primary}px;
  padding: ${({ theme }) => theme.spacing.primary}px;
`;

export default memo(InfoBanner);
