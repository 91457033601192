import { SizeBreakpoint, styled } from '@openeducation/pp-rn-themes';
import { rgba } from 'polished';
import React, { ReactNode, memo } from 'react';
import { Modal as DefaultModal, StyleProp, ViewStyle } from 'react-native';

import { PPText } from '../pp-text/pp-text';

export interface PPModalProps {
  visible: boolean;
  children: ReactNode | ReactNode[];
  title?: string;
  style?: StyleProp<ViewStyle>;
}

const PPModal: React.FC<PPModalProps> = ({
  visible,
  title,
  children,
  style,
}) => {
  return (
    <DefaultModal
      transparent
      visible={visible}
      presentationStyle="overFullScreen"
      animationType="fade"
    >
      <Background>
        <Container style={style}>
          {!!title && <Title>{title}</Title>}
          <Content>{children}</Content>
        </Container>
      </Background>
    </DefaultModal>
  );
};

const Background = styled.View`
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => rgba(theme.colors.dark1, 0.8)};
`;

interface ModalContentProps {}

const Container = styled.View<ModalContentProps>`
  border-radius: ${({ theme }) => theme.border.radius.medium}px;
  max-width: ${SizeBreakpoint.MD}px;
  margin: ${({ theme }) => theme.spacing.primary}px;
  box-shadow: ${({ theme }) =>
    `0 ${theme.spacing.extraSmall}px ${theme.spacing.primarySmall}px ${rgba(
      theme.colors.black,
      0.05
    )}`}
  background-color: ${({ theme }) => theme.colors.light};
`;

const Content = styled.View`
  padding: ${({ theme }) => theme.spacing.primary}px;
`;

export const Title = styled(PPText).attrs({
  fontSize: 24,
  lineHeight: 24,
  fontType: 'semiBold',
})`
  text-transform: uppercase;
  padding: ${({ theme }) => theme.spacing.primary}px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.border};
`;

export default memo(PPModal);
