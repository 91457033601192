import { Language } from '@openeducation/pp-rn-shared-types';
import { locale } from 'expo-localization';

export const getLanguageByLocalization = (
  supportedLanguages: Language[]
): Language => {
  const localLanguage = locale?.substring(0, 2) as Language;
  if (!supportedLanguages.includes(localLanguage)) {
    return Language.EN;
  }
  return localLanguage;
};
