import {
  pendingPaymentIdAtom,
  pendingPaymentAtom,
} from '@openeducation/pp-rn-jotai-atoms';
import {
  PaymentStatusModalProps,
  WorkflowStatus,
  PaymentFlowType,
  PaymentGateway,
  InvoiceCardProps,
} from '@openeducation/pp-rn-shared-types';
import { useAtom } from 'jotai';
import { useEffect, useRef, useState, useCallback } from 'react';

import { useGetPaymentStatus, useGoogleTagManager } from '../../hooks';

interface UsePaymentStatusModalProps {
  paymentFlowType?: PaymentFlowType;
  alternativePaymentGateway?: PaymentGateway;
  leadServiceId?: string;
  invoiceCardProps?: InvoiceCardProps;
}

// NOTE: MAX_RETRIES * RETRY_DELAY = 1 minute of total delay
const MAX_RETRIES = 6;
const RETRY_DELAY = 10000; // ms;

const stopPollingStatus = [
  WorkflowStatus.SUCCESS,
  WorkflowStatus.PAID,
  WorkflowStatus.ERROR,
];
const retryEnabledStatus = [WorkflowStatus.ERROR];

export const usePaymentStatusModalProps = ({
  paymentFlowType,
  alternativePaymentGateway,
  leadServiceId,
  invoiceCardProps,
}: UsePaymentStatusModalProps) => {
  const retryCounter = useRef(0);

  const [paymentId, setPendingPaymentId] = useAtom(pendingPaymentIdAtom);
  const [pendingPayment, setPendingPayment] = useAtom(pendingPaymentAtom);
  const [hasTriggeredPurchase, setHasTriggeredPurchase] = useState(false);

  const { pushEventPurchase } = useGoogleTagManager();

  const { data, refetch } = useGetPaymentStatus({
    paymentFlowType,
    paymentId,
  });

  const triggerPurchaseEvent = useCallback(() => {
    if (!paymentId || !invoiceCardProps) {
      return;
    }
    setHasTriggeredPurchase(true);
    pushEventPurchase(leadServiceId!, invoiceCardProps, paymentId.toString());
  }, [paymentId, invoiceCardProps, leadServiceId, pushEventPurchase]);

  const status = data?.status as WorkflowStatus;
  const retryEnabled = retryEnabledStatus.includes(status);
  const stopPolling = stopPollingStatus.includes(status);

  const handleClose = () => {
    setPendingPaymentId(null);
    setPendingPayment(null);
    retryCounter.current = 0;
  };

  useEffect(() => {
    if (status === WorkflowStatus.SUCCESS && !hasTriggeredPurchase) {
      triggerPurchaseEvent();
    }
  }, [status, hasTriggeredPurchase, triggerPurchaseEvent]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (status) {
      interval = setInterval(() => {
        if (!stopPolling && retryCounter.current < MAX_RETRIES) {
          refetch();
          retryCounter.current += 1;
        } else {
          setPendingPayment(null);
          if (interval) {
            clearInterval(interval);
          }
        }
      }, RETRY_DELAY);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [status, refetch, stopPolling, setPendingPayment]);

  useEffect(() => {
    if (stopPolling) {
      setPendingPayment(null);
    }
  }, [stopPolling, setPendingPayment]);

  const paymentStatusModalProps: PaymentStatusModalProps = {
    visible: !!paymentId || pendingPayment !== null,
    alternativePaymentGateway,
    status,
    message: status === WorkflowStatus.ERROR ? data?.message || '' : '',
    pendingPayment,
    onClose: retryEnabled ? handleClose : undefined,
    stoppedPolling: retryCounter.current >= MAX_RETRIES,
  };

  return { paymentStatusModalProps };
};
