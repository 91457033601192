import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectInputProps, SelectInput } from '../../../atoms';
import { InputRow } from '../../../molecules';

interface PaymentInfoCardTypeProps extends SelectInputProps {
  error?: string;
}

const PaymentInfoCardType = ({
  onFocus,
  onBlur,
  selectedValue,
  data,
  onValueChange,
  error,
  disabled,
}: PaymentInfoCardTypeProps) => {
  const { t } = useTranslation();

  return (
    <InputRow label={t('input.card_type.label')} error={error}>
      <SelectInput
        name="cardType"
        selectedValue={selectedValue}
        disabled={disabled}
        data={data}
        onFocus={onFocus}
        onBlur={onBlur}
        onValueChange={onValueChange}
        placeholder={t('input.card_type.label')}
      />
    </InputRow>
  );
};

export default memo(PaymentInfoCardType);
