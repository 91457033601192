import { PPApiService } from '@openeducation/pp-rn-services';
import type {
  YunoPaymentLinkParams,
  ApiError,
} from '@openeducation/pp-rn-shared-types';
import type { AxiosError } from 'axios';
import type { YunoPaymentLink } from 'libs/pp-rn-shared-types/lib/typescript/src';
import { useMutation } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';

export const useGetYunoPaymentLink = (params: YunoPaymentLinkParams) => {
  const { environment } = useEnvironmentContext();
  const { paymentInfoToken, zuoraAccountId, callbackUrl, countryIsoCode } =
    params;

  const { mutate, data, error, isError, isLoading, reset } = useMutation<
    YunoPaymentLink,
    AxiosError<any>
  >(() => {
    if (
      (!countryIsoCode || !callbackUrl) &&
      (paymentInfoToken || zuoraAccountId)
    ) {
      throw new Error('Missing Yuno payment link params');
    }
    return PPApiService.getYunoPaymentLink(environment, params);
  });

  const apiError: ApiError | undefined = error?.response?.data;

  return {
    reset,
    getYunoPaymentLink: mutate,
    data,
    apiError,
    isError,
    isLoading,
  };
};
