import { PPApiService } from '@openeducation/pp-rn-services';
import type {
  ApiError,
  SubmitPaymentReturn,
  PaymentParams,
  PaymentGateway,
} from '@openeducation/pp-rn-shared-types';
import type { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';

interface UseUpdatePaymentMethodInput {
  accountId: string;
  alternativePaymentGateway?: PaymentGateway | null;
}

export const useUpdatePaymentMethod = ({
  accountId,
}: UseUpdatePaymentMethodInput): SubmitPaymentReturn => {
  const { environment } = useEnvironmentContext();

  const { data, mutate, reset, isError, error, isSuccess, isLoading } =
    useMutation<number, AxiosError<ApiError>, PaymentParams>(
      (params: PaymentParams) => {
        if (!accountId || !params.paymentMethod) {
          throw new Error('[updatePaymentMethod] no params provided');
        }
        return PPApiService.updatePaymentMethod(environment, accountId, params);
      }
    );

  const apiError: ApiError | undefined = error?.response?.data;

  return {
    submitPayment: mutate,
    submitPaymentReset: reset,
    data,
    apiError,
    isError,
    isLoading,
    isSuccess,
  };
};
