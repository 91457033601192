import type { AddressValidationModalProps } from '@openeducation/pp-rn-shared-types';
import { VisibleModalAddressValidation } from '@openeducation/pp-rn-shared-types';
import React, { memo } from 'react';

import { PPModal } from '../../atoms';
import AddressValidationCorrected from '../address-validation-corrected/address-validation-corrected';
import { AddressValidationError } from '../address-validation-error/address-validation-error';

export const AddressValidationModal = ({
  modalVisible,
  accept,
  proceed,
  cancel,
  address,
  validatedAddress,
}: AddressValidationModalProps) => {
  const renderContent = () => {
    if (modalVisible === VisibleModalAddressValidation.CORRECTED) {
      return (
        <AddressValidationCorrected
          accept={accept}
          proceed={proceed}
          address={address}
          validatedAddress={validatedAddress}
        />
      );
    }
    if (modalVisible === VisibleModalAddressValidation.ERROR) {
      return (
        <AddressValidationError
          cancel={cancel}
          proceed={proceed}
          address={address}
        />
      );
    }
    return null;
  };

  return <PPModal visible={!!modalVisible}>{renderContent()}</PPModal>;
};

export default memo(AddressValidationModal);
