import { PPApiService } from '@openeducation/pp-rn-services';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetCardNumberInput {
  creditCardToken?: string | null;
}

export const useGetCardNumber = ({
  creditCardToken,
}: UseGetCardNumberInput) => {
  const { environment } = useEnvironmentContext();

  const {
    data = '',
    isError,
    isLoading,
  } = useQuery<string>(
    [QueryKey.GetCardNumber, creditCardToken],
    () => PPApiService.getCardNumber(environment, { creditCardToken }),
    { enabled: !!creditCardToken }
  );

  return {
    data,
    isError,
    isLoading,
  };
};
