import type { SelectInputItem } from '@openeducation/pp-rn-shared-types';
import { styled, useStyledTheme } from '@openeducation/pp-rn-themes';
import { Picker } from '@react-native-picker/picker';
import React, { memo, useState } from 'react';
import type { ViewStyle, StyleProp } from 'react-native';

export interface SelectInputProps {
  data: SelectInputItem[];
  selectedValue: SelectInputItem['value'];
  onValueChange: (item: SelectInputItem['value'], name?: string) => void;
  name?: string;
  placeholder?: string | null;
  disabled?: boolean;
  onFocus?: (name?: string) => void;
  onBlur?: (name?: string) => void;
  style?: StyleProp<ViewStyle>;
}

export const SelectInput = ({
  data = [],
  selectedValue,
  name,
  onValueChange,
  disabled,
  style,
  placeholder,
  onFocus,
  onBlur,
}: SelectInputProps) => {
  const theme = useStyledTheme();
  const [isFocused, setIsFocused] = useState(false);

  const itemsColor =
    selectedValue || isFocused ? theme.colors.text : theme.colors.dark3;

  const handleFocus = () => {
    setIsFocused(true);
    onFocus?.(name);
  };

  const handleBlur = () => {
    setIsFocused(false);
    onBlur?.(name);
  };

  const renderPickerItem = (
    { label, value }: SelectInputItem,
    enabled: boolean = true
  ) => (
    <Picker.Item
      key={value}
      label={label}
      value={value}
      fontFamily={theme.font.regular.fontFamily}
      enabled={enabled}
    />
  );

  return (
    <StyledPicker
      selectedValue={selectedValue}
      onValueChange={(value: any) => onValueChange(value, name)}
      enabled={!disabled}
      color={itemsColor}
      onFocus={handleFocus}
      onBlur={handleBlur}
      style={style}
    >
      {renderPickerItem({ label: placeholder || '', value: '' }, false)}
      {data.map((item) => renderPickerItem(item))}
    </StyledPicker>
  );
};

type StyledPickerType = {
  enabled?: boolean;
  color: string;
};

const StyledPicker = styled(Picker)<StyledPickerType>`
  padding-horizontal: ${({ theme }) => theme.spacing.primarySmall}px;
  padding-vertical: ${({ theme }) => theme.spacing.small}px;
  border-radius: ${({ theme }) => theme.border.radius.primary}px;
  border-color: ${({ theme }) => theme.colors.border};
  background-color: ${({ theme, enabled }) =>
    enabled ? theme.colors.light : theme.colors.dark0};
  color: ${({ color }) => color};
  cursor: ${({ enabled }) => (enabled ? 'pointer' : 'not-allowed')};
`;

export default memo(SelectInput);
