import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectInputProps, SelectInput } from '../../../atoms';
import { InputRow } from '../../../molecules';

const PaymentInfoPaymentType = ({
  onFocus,
  onBlur,
  selectedValue,
  data,
  onValueChange,
}: SelectInputProps) => {
  const { t } = useTranslation();

  return (
    <InputRow label={t('input.payment_type.label')}>
      <SelectInput
        name="paymentMethod"
        selectedValue={selectedValue}
        data={data}
        onFocus={onFocus}
        onBlur={onBlur}
        onValueChange={onValueChange}
      />
    </InputRow>
  );
};

export default memo(PaymentInfoPaymentType);
