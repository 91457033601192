import {
  PPPageTemplate,
  InvoiceCard,
  NextInvoice,
  PaymentInfo,
  PersonalInfo,
  AdditionalPaymentInfo,
  ManualPaymentInfo,
  ContractInfo,
  PaymentSubmit,
  ErrorModal,
  PaymentStatusModal,
  AddressValidationModal,
} from '@openeducation/pp-rn-components';
import { useCheckoutScreen } from '@openeducation/pp-rn-providers';
import { getUrlParams, getBaseUrl } from '@openeducation/pp-rn-services';
import React, { useEffect } from 'react';

import CreditCardPreview from '../components/credit-card-preview.web';
import { footerProps } from '../helpers';
import { useSuccessfulPaymentRedirection } from '../hooks';
import { useNavigate, Path } from '../router';

const CheckoutScreen = () => {
  const navigate = useNavigate();
  const { sfdcToken } = getUrlParams();
  const baseUrl = getBaseUrl();

  useEffect(() => {
    if (!sfdcToken) {
      navigate(Path.Home);
    }
  }, [sfdcToken, navigate]);

  const {
    pageTemplateProps,
    invoiceCardProps,
    personalInfoProps,
    paymentInfoProps,
    additionalPaymentInfoProps,
    manualPaymentInfoProps,
    contractInfoProps,
    paymentSubmitProps,
    paymentStatusModalProps,
    errorModalProps,
    addressValidationModalProps,
    yunoPaymentStatus,
    nextInvoiceProps,
  } = useCheckoutScreen(sfdcToken, baseUrl);

  const { openScreenAfterSuccessfulPayment } =
    useSuccessfulPaymentRedirection();

  useEffect(() => {
    openScreenAfterSuccessfulPayment(yunoPaymentStatus);
  }, [openScreenAfterSuccessfulPayment, yunoPaymentStatus]);

  return (
    <>
      <PPPageTemplate {...pageTemplateProps} footerProps={footerProps}>
        <InvoiceCard {...invoiceCardProps} />
        <NextInvoice {...nextInvoiceProps} />
        <PersonalInfo {...personalInfoProps} />
        <PaymentInfo
          {...paymentInfoProps}
          renderCardPreview={(
            number: string,
            expiry: string,
            name: string,
            cvv: string,
            focused?: string
          ) => (
            <CreditCardPreview
              cardType={paymentInfoProps.formData.cardType}
              number={number}
              expiry={expiry}
              name={name}
              cvc={cvv}
              focused={focused}
            />
          )}
        />
        <AdditionalPaymentInfo {...additionalPaymentInfoProps} />
        <ManualPaymentInfo {...manualPaymentInfoProps} />
        <ContractInfo {...contractInfoProps} />
        <PaymentSubmit {...paymentSubmitProps} />
      </PPPageTemplate>
      <PaymentStatusModal {...paymentStatusModalProps} />
      <ErrorModal {...errorModalProps} />
      <AddressValidationModal {...addressValidationModalProps} />
    </>
  );
};

export default CheckoutScreen;
