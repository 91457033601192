import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInputProps, TextInput } from '../../../atoms';
import { InputRow } from '../../../molecules';

const PaymentInfoCvv = ({
  onFocus,
  onBlur,
  value,
  onValueChange,
}: TextInputProps) => {
  const { t } = useTranslation();

  return (
    <InputRow
      label={t('input.card_code.label')}
      error={value ? '' : t('input.card_code.error.required')}
    >
      <TextInput
        name="cvv"
        value={value}
        maxLength={4}
        placeholder="CVV"
        onFocus={onFocus}
        onBlur={onBlur}
        onValueChange={onValueChange}
      />
    </InputRow>
  );
};

export default memo(PaymentInfoCvv);
