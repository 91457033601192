import { selectedPaymentMethodAtom } from '@openeducation/pp-rn-jotai-atoms';
import type { CommonPaymentInfo } from '@openeducation/pp-rn-shared-types';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

interface UseSetFirstPaymentMethodProps {
  commonPaymentInfo?: CommonPaymentInfo;
}
export const useSetFirstPaymentMethod = ({
  commonPaymentInfo,
}: UseSetFirstPaymentMethodProps) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useAtom(
    selectedPaymentMethodAtom
  );

  useEffect(() => {
    const paymentMethods = commonPaymentInfo?.paymentMethods || [];
    const firstPaymentMethod = paymentMethods[0];
    if (!selectedPaymentMethod && firstPaymentMethod) {
      setSelectedPaymentMethod(firstPaymentMethod);
    }
  }, [
    commonPaymentInfo?.paymentMethods,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
  ]);
};
