import {
  mapSelectInputData,
  formatCardNumber,
  getNYearsAhead,
  generateArray,
  validateCardExpirationDate,
  validateCardNumber,
  validateHolderName,
  detectCardType,
} from '@openeducation/pp-js-utils';
import {
  pmCountryAtom,
  cardHolderNameAtom,
  selectedPaymentMethodAtom,
} from '@openeducation/pp-rn-jotai-atoms';
import {
  Country,
  PaymentMethod,
  SelectInputItem,
  CommonPaymentInfo,
  PaymentInfoProps,
  CreditCard,
  CreditCardPaymentInfo,
  TinyPayloads,
  PaymentFlowType,
  PaymentInfoFormData,
  Subscription,
} from '@openeducation/pp-rn-shared-types';
import { useSetAtom, useAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useInstallmentsInput } from './checkout/use-installments-input';
import { usePaymentMethodOnFile } from './checkout/use-payment-method-on-file';

interface UsePaymentInfoProps {
  countryISOCode?: Country['isoCode'];
  allCountries: Country[];
  commonPaymentInfo?: CommonPaymentInfo;
  creditCardPaymentInfo?: CreditCardPaymentInfo;
  tinyPayloads?: TinyPayloads;
  subscription?: Subscription;
  paypalEmail?: string;
  creditCardNumber?: string;
  splitInvoice?: boolean;
}

const now = new Date();

export const usePaymentInfoProps = ({
  countryISOCode,
  commonPaymentInfo,
  allCountries,
  creditCardPaymentInfo,
  tinyPayloads,
  subscription,
  paypalEmail,
  creditCardNumber,
  splitInvoice = false,
}: UsePaymentInfoProps) => {
  const paymentMethods = commonPaymentInfo?.paymentMethods;
  const cardTypes = creditCardPaymentInfo?.types;
  const setPmCountry = useSetAtom(pmCountryAtom);
  const setCardHolderName = useSetAtom(cardHolderNameAtom);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useAtom(
    selectedPaymentMethodAtom
  );

  const [formData, setFormData] = useState<PaymentInfoFormData>({
    country: '',
    paymentMethod: '' as PaymentMethod,
    cardType: '' as CreditCard,
    cardNumber: '',
    expirationMonth: '',
    expirationYear: '',
    holderName: '',
    cvv: '',
    installments: '',
  });
  const isCreditCard = formData.paymentMethod === PaymentMethod.CREDIT_CARD;
  const isPayPal = formData.paymentMethod === PaymentMethod.PAY_PAL;

  const twentyYearsAhead = getNYearsAhead(now, 20, 'yy');
  const months = generateArray(12).map((index: number) => `${index + 1}`);

  const { paymentMethodOnFile, isExistingPaymentMethod } =
    usePaymentMethodOnFile({
      subscription,
      isUpsell: tinyPayloads?.paymentFlowType === PaymentFlowType.UPSELL,
      setFormData,
    });

  const detectedCardType = useMemo(() => {
    if (!formData.cardNumber) {
      return null;
    }
    return detectCardType(formData.cardNumber);
  }, [formData.cardNumber]);
  const isAmericanExpress = detectedCardType === CreditCard.AMERICAN_EXPRESS;

  useEffect(() => {
    setCardHolderName(formData.holderName);
  }, [setCardHolderName, formData.holderName]);

  useEffect(() => {
    setPmCountry(formData.country);
  }, [setPmCountry, formData.country]);

  const handleFieldChange = useCallback(
    (value: string, name?: string) => {
      if (name) {
        let newValue = value;
        if (name === 'paymentMethod') {
          setSelectedPaymentMethod(value as PaymentMethod);
        }

        if (name === 'cardNumber') {
          newValue = formatCardNumber(value, isAmericanExpress);
        }
        setFormData((prev) => ({ ...prev, [name]: newValue }));
      }
    },
    [isAmericanExpress, setSelectedPaymentMethod]
  );

  const { installmentsInputData } = useInstallmentsInput({
    installmentsFormData: formData.installments,
    handleFieldChange,
    cardType: formData.cardType,
    organization: tinyPayloads?.organization,
    currency: tinyPayloads?.currencyISOCode,
    billingFrequency: tinyPayloads?.billingFrequency,
    amount: tinyPayloads?.totalAmount,
    initialTerm: tinyPayloads?.initialTerm,
    cardBinNumber: formData.cardNumber.replaceAll(' ', '').slice(0, 6),
    splitInvoice,
  });

  useEffect(() => {
    if (!formData.cardType && detectedCardType) {
      handleFieldChange(detectedCardType, 'cardType');
    }
  }, [detectedCardType, formData.cardType, handleFieldChange]);

  useEffect(() => {
    if (countryISOCode) {
      handleFieldChange(countryISOCode, 'country');
    }
  }, [countryISOCode, handleFieldChange]);
  useEffect(() => {
    if (creditCardNumber) {
      handleFieldChange(creditCardNumber, 'cardNumber');
    }
  }, [creditCardNumber, handleFieldChange]);

  useEffect(() => {
    if (selectedPaymentMethod !== formData.paymentMethod) {
      handleFieldChange(selectedPaymentMethod as string, 'paymentMethod');
    }
  }, [selectedPaymentMethod, formData.paymentMethod, handleFieldChange]);

  const fields = useMemo(
    () => ({
      isCountryEnabled: !!commonPaymentInfo?.country,
      isCountryFixed: !!commonPaymentInfo?.isCountryFixed,
      isCardTypeEnabled: isCreditCard,
      isCardNumberEnabled: isCreditCard && !!creditCardPaymentInfo?.number,
      isExpirationDateEnabled:
        isCreditCard && !!creditCardPaymentInfo?.expirationDate,
      isHolderNameEnabled: isCreditCard && !!creditCardPaymentInfo?.holderName,
      isCvvEnabled: isCreditCard && !!creditCardPaymentInfo?.cvv,
      isInstallmentsEnabled: installmentsInputData.length > 0,
      isExistingPaymentMethod,
      isPayPalEmailEnabled: isPayPal && isExistingPaymentMethod,
      isDraftCreditCardNumber: !!creditCardNumber,
    }),
    [
      commonPaymentInfo,
      creditCardPaymentInfo,
      installmentsInputData,
      isExistingPaymentMethod,
      isCreditCard,
      isPayPal,
      creditCardNumber,
    ]
  );

  const validation = useMemo(
    () => ({
      isCardTypeValid: cardTypes
        ? cardTypes.includes(formData.cardType)
        : false,
      isCardNumberValid:
        !!creditCardNumber ||
        isExistingPaymentMethod ||
        validateCardNumber(formData.cardNumber),
      isCardExpirationValid: validateCardExpirationDate(
        now,
        formData.expirationMonth,
        formData.expirationYear
      ),
      isHolderNameValid: validateHolderName(formData.holderName),
    }),
    [cardTypes, formData, isExistingPaymentMethod, creditCardNumber]
  );

  const paymentInfoProps: Omit<PaymentInfoProps, 'renderCardPreview'> = {
    formData,
    selectData: {
      country: mapSelectInputData<Country>(
        allCountries,
        'isoCode',
        'isoCode'
      ) as SelectInputItem[],
      paymentMethod: mapSelectInputData<PaymentMethod>(paymentMethods || []),
      cardType: mapSelectInputData<CreditCard>(
        creditCardPaymentInfo?.types || []
      ),
      expirationMonth: mapSelectInputData<string>(months),
      expirationYear: mapSelectInputData<string>(twentyYearsAhead),
      installments: installmentsInputData,
    },
    onFieldChange: handleFieldChange,
    fields,
    validation,
    paymentMethodOnFile,
    paypalEmail: paypalEmail || subscription?.paypalEmail,
  };

  return { paymentInfoProps };
};
