export const validateCardExpirationDate = (
  now: Date,
  expirationMonth: string,
  expirationYear: string
) => {
  if (!expirationMonth || !expirationYear) {
    return false;
  }
  const month = parseInt(expirationMonth, 10);
  const year = 2000 + parseInt(expirationYear, 10);

  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();
  if (year > currentYear) {
    return true;
  } else if (year === currentYear && month > currentMonth) {
    return true;
  }

  return false;
};
