import type {
  Environment,
  ContractParams,
  TermsOfPurchase,
} from '@openeducation/pp-rn-shared-types';
import axios from 'axios';

import type { GetTermsOfPurchaseParams } from '../types';
import { hostsConfig } from '../utils';

export default abstract class PPProductCatalog {
  static async createContract(env: Environment, contract: ContractParams) {
    const url = hostsConfig[env].ppProductCatalog;
    const { data } = await axios.post<string>(
      `${url}/contracts/generator`,
      contract
    );
    return data;
  }

  static async acceptContract(env: Environment, contract: ContractParams) {
    const url = hostsConfig[env].ppProductCatalog;
    const { data } = await axios.post<string>(`${url}/contracts`, contract);
    return data;
  }

  static async getTermsOfPurchase(
    env: Environment,
    params: GetTermsOfPurchaseParams
  ) {
    const url = hostsConfig[env].ppProductCatalog;
    const { data } = await axios.get<TermsOfPurchase>(
      `${url}/contracts/terms-of-purchase`,
      { params }
    );
    return data;
  }
}
