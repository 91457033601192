import { styled } from '@openeducation/pp-rn-themes';
import React, { memo } from 'react';

import MarkdownView from '../../molecules/markdown-view/markdown-view';

export interface ContractPreviewProps {
  children: string;
}

export const ContractPreview = ({ children }: ContractPreviewProps) => {
  return (
    <Container>
      <MarkdownView>{children}</MarkdownView>
    </Container>
  );
};

const Container = styled.ScrollView`
  padding: ${({ theme }) => theme.spacing.extraSmall}px;
  height: 310px;
  margin-bottom: ${({ theme }) => theme.spacing.primary}px;
  border-width: ${({ theme }) => theme.border.width.primary}px;
  border-radius: ${({ theme }) => theme.border.radius.primary}px;
  border-color: ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.light};
`;

export default memo(ContractPreview);
