import { PPPaymentInfoService } from '@openeducation/pp-rn-services';
import type { Currency, Organization } from '@openeducation/pp-rn-shared-types';
import { useQuery } from 'react-query';

import { useEnvironmentContext } from '../../environment/environment.provider';
import { QueryKey } from '../../types';

interface UseGetAlternativePaymentInfoInput {
  organization?: Organization;
  currency?: Currency;
  sfdcToken?: string;
  accountId?: string;
}

export const useGetAlternativePaymentInfo = (
  {
    organization = '' as Organization,
    currency = '' as Currency,
    sfdcToken = '' as string,
    accountId = '' as string,
  }: UseGetAlternativePaymentInfoInput,
  enabled: boolean = true
) => {
  const { environment } = useEnvironmentContext();

  const { data, isError, isLoading } = useQuery(
    [QueryKey.GetPersonalDetails, organization, currency],
    () =>
      PPPaymentInfoService.getAlternativePaymentInfo({
        env: environment,
        organization,
        currency,
        paymentInfoToken: sfdcToken,
        accountId,
      }),
    {
      enabled:
        enabled &&
        (!!sfdcToken || (!!organization && !!accountId && !!currency)),
    }
  );

  return {
    data,
    isError,
    isLoading,
  };
};
