import type { Language } from '@openeducation/pp-rn-shared-types';
import { styled } from '@openeducation/pp-rn-themes';
import { rgba } from 'polished';
import React, { memo, useCallback } from 'react';

import LanguagePickerDropdownItem from './language-picker-dropdown-item';

type DropdownItem = {
  key: Language;
  value: string;
};

interface LanguagePickerDropdownProps {
  items: DropdownItem[];
  onLanguageSelect: (language: Language) => void;
}

const LanguagePickerDropdown = ({
  items,
  onLanguageSelect,
}: LanguagePickerDropdownProps) => {
  const renderItem = useCallback(
    ({ key, value }: DropdownItem) => (
      <LanguagePickerDropdownItem
        key={key}
        text={value}
        onPress={() => onLanguageSelect(key)}
      />
    ),
    [onLanguageSelect]
  );

  return <Container>{items.map(renderItem)}</Container>;
};

const Container = styled.View`
  border-width: ${({ theme }) => theme.border.width.primary}px;
  background-color: ${({ theme }) => theme.colors.light};
  padding-vertical: ${({ theme }) => theme.spacing.tiny}px;
  border-radius: ${({ theme }) => theme.border.radius.primary}px;
  border-color: ${({ theme }) => theme.colors.border};
  align-self: flex-end;
  box-shadow: ${({ theme }) =>
    `0 ${theme.spacing.extraSmall}px ${theme.spacing.primarySmall}px ${rgba(
      theme.colors.black,
      0.05
    )}`}
  top: 35px;
  position: absolute;
  z-index: 2;
`;

export default memo(LanguagePickerDropdown);
